import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendarAlt,
  faSchool,
  faTasks,
  faUserFriends,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from '@reach/router';
import classnames from 'classnames';
import React, { useContext, useEffect } from 'react';
import { PackageContext } from '../../context/PackageContext';
import { getManageServiceRoute } from '../../util';
import ManageRosterLink from './ManageRosterLink';
import { useQuery } from 'react-query';
import { getPackageRequestById } from './api';
import { PackageRequest } from './NewApiTypes.generated';

const ArrowStepBar = () => (
  <div className="flex items-center my-auto">
    <div className="bg-gray-200 h-1 w-20" />
    <div
      className="border-gray-200 w-0 h-0 border-t-8 border-b-8 border-l-8"
      style={{
        borderBottomColor: 'transparent',
        borderTopColor: 'transparent'
      }}
    />
  </div>
);

export enum ManageServicesEnum {
  ASSIGN_TICKETS = 'assign-tickets',
  STAFF_OPTIONS = 'staff-options',
  EVENT_SELECTION = 'event-selection',
  COURSE_SELECTION = 'course-selection',
  INSTITUTE_SELECTION = 'institute-selection',
  REVIEW = 'review'
}

export const MANAGE_SERVICES_STEPS = [
  ManageServicesEnum.ASSIGN_TICKETS,
  ManageServicesEnum.STAFF_OPTIONS,
  ManageServicesEnum.EVENT_SELECTION,
  ManageServicesEnum.COURSE_SELECTION,
  ManageServicesEnum.INSTITUTE_SELECTION,
  ManageServicesEnum.REVIEW
];

export const getAvailableManageServicesSteps = (
  packageRequest?: PackageRequest
) => {
  const result = [];
  if (packageRequest?.package?.pdSessionGroupings?.length) {
    result.push(ManageServicesEnum.STAFF_OPTIONS);
  }
  if (packageRequest?.package?.events?.length) {
    result.push(ManageServicesEnum.EVENT_SELECTION);
  }
  if (packageRequest?.package?.groups?.length) {
    result.push(ManageServicesEnum.COURSE_SELECTION);
  }
  if (packageRequest?.package?.institutes?.length) {
    result.push(ManageServicesEnum.INSTITUTE_SELECTION);
  }
  if (packageRequest?.status === 'ACCEPTED') {
    result.push(ManageServicesEnum.ASSIGN_TICKETS);
  }
  result.push(ManageServicesEnum.REVIEW);
  return result;
};

const hasManageServicesStep = (
  step: ManageServicesEnum,
  packageRequest?: PackageRequest
) => {
  const availableSteps = getAvailableManageServicesSteps(packageRequest);
  return availableSteps.includes(step);
};

export const getManageServicesNextStep = (
  currentStep: ManageServicesEnum,
  packageRequest?: PackageRequest
) => {
  const availableSteps = getAvailableManageServicesSteps(packageRequest);
  const currentIndex = availableSteps.indexOf(currentStep);
  return availableSteps[currentIndex + 1];
};

export const getManageServicesPreviousStep = (
  currentStep: ManageServicesEnum,
  packageRequest?: PackageRequest
) => {
  const availableSteps = getAvailableManageServicesSteps(packageRequest);
  const currentIndex = availableSteps.indexOf(currentStep);
  return availableSteps[currentIndex - 1];
};

interface StepLabelProps {
  label: string;
  route: string;
  packageRequestId: string;
  stepNumber: string;
  isSelected: boolean;
  icon: IconProp;
}
const StepLabel = (props: StepLabelProps) => {
  const packageContext = useContext(PackageContext);
  const linkClasses = classnames({
    'sans-serif uppercase text-gray-400 font-semibold flex flex-col': true,
    'text-primary': props.isSelected,
    'hover:text-primary hover:underline cursor-pointer':
      packageContext.isPackageEditable,
    'text-gray-300 cursor-not-allowed': !packageContext.isPackageEditable
  });
  const iconContainerClasses = classnames({
    'border-2 rounded-full px-3 py-2 mx-auto mb-1': true,
    'bg-primary': props.isSelected,
    'border-primary': packageContext.isPackageEditable,
    'border-gray-300': !packageContext.isPackageEditable
  });
  const iconClasses = classnames({
    'text-xl sans-serif text-center': true,
    'text-white': props.isSelected
  });
  return (
    <div className="mx-2">
      <button
        onClick={() => {
          if (packageContext.hasUnsavedChanges) {
            if (
              confirm(
                'You have unsaved changes that will be lost. Are you sure you want to navigate away?'
              )
            ) {
              navigate(
                getManageServiceRoute(props.packageRequestId, props.route)
              );
              packageContext.setHasUnsavedChanges(false);
            }
          } else {
            navigate(
              getManageServiceRoute(props.packageRequestId, props.route)
            );
          }
        }}
        className={linkClasses}
        disabled={!packageContext.isPackageEditable}
      >
        <div className={iconContainerClasses}>
          <FontAwesomeIcon icon={props.icon} className={iconClasses} />
        </div>
        <p className="sans-serif text-center mt-2">{props.label}</p>
      </button>
    </div>
  );
};

interface PackagesProps {
  children: React.ReactChildren;
  path: string;
  packageRequestId: string;
  location: any;
}

const Packages = (props: PackagesProps) => {
  const { packageRequestId } = props;
  const packageContext = useContext(PackageContext);
  const [packageRequest, setPackageRequest] = React.useState<PackageRequest>();

  const splitPath = props.location.pathname.split('/');
  const currentStep = splitPath[splitPath.length - 1];
  useQuery<{
    packageRequest: PackageRequest;
  }>('packageRequestQuery', () => getPackageRequestById(packageRequestId), {
    onSuccess: data => {
      const isEditable = ['STARTED', 'CHANGES_REGUESTED', 'REOPENED'].some(
        status => status === data.packageRequest.status
      );
      setPackageRequest(data.packageRequest);
      packageContext.setOrganizationId(data.packageRequest.organizationId);
      packageContext.setIsPackageEditable(isEditable);
      const availableSteps = getAvailableManageServicesSteps(
        data.packageRequest
      );
      if (!availableSteps.includes(currentStep)) {
        console.log('navigate: ', navigate);
        navigate(
          getManageServiceRoute(props.packageRequestId, availableSteps[0])
        );
      }
      if (
        !isEditable &&
        !(
          currentStep === ManageServicesEnum.REVIEW ||
          currentStep === ManageServicesEnum.ASSIGN_TICKETS
        )
      ) {
        navigate(getManageServiceRoute(props.packageRequestId, 'review'));
      }
    }
  });

  const assignTicketSteps = ['assign-tickets', 'assign-staff'];

  const isAssigningTickets = assignTicketSteps.includes(currentStep);

  return (
    <>
      <div className="shadow bg-white p-8 -mt-8 -mx-8">
        {isAssigningTickets ? (
          <>
            <h1 className="text-primary text-2xl font-bold sans-serif text-center mb-4 uppercase">
              Allocate Tickets and Assign Staff
            </h1>
            {packageContext.organizationId && (
              <div className="text-center">
                <ManageRosterLink
                  organizationId={String(packageContext.organizationId)}
                  withArrow
                />
              </div>
            )}
          </>
        ) : (
          <h1 className="text-primary text-2xl font-bold sans-serif text-center mb-4 uppercase">
            Manage Services
          </h1>
        )}
        {!isAssigningTickets && (
          <div className="flex justify-center">
            {hasManageServicesStep(
              ManageServicesEnum.STAFF_OPTIONS,
              packageRequest
            ) && (
              <>
                <StepLabel
                  label="Staff Options"
                  route={ManageServicesEnum.STAFF_OPTIONS}
                  packageRequestId={packageRequestId}
                  stepNumber="1"
                  isSelected={currentStep === ManageServicesEnum.STAFF_OPTIONS}
                  icon={faUserFriends}
                />
                <ArrowStepBar />
              </>
            )}
            {hasManageServicesStep(
              ManageServicesEnum.EVENT_SELECTION,
              packageRequest
            ) && (
              <>
                <StepLabel
                  label="Event Selection"
                  route={ManageServicesEnum.EVENT_SELECTION}
                  packageRequestId={packageRequestId}
                  stepNumber="2"
                  isSelected={
                    currentStep === ManageServicesEnum.EVENT_SELECTION
                  }
                  icon={faCalendarAlt}
                />
                <ArrowStepBar />
              </>
            )}
            {hasManageServicesStep(
              ManageServicesEnum.COURSE_SELECTION,
              packageRequest
            ) && (
              <>
                <StepLabel
                  label="Course Selection"
                  route={ManageServicesEnum.COURSE_SELECTION}
                  packageRequestId={packageRequestId}
                  stepNumber="3"
                  isSelected={
                    currentStep === ManageServicesEnum.COURSE_SELECTION
                  }
                  icon={faUsers}
                />
                <ArrowStepBar />
              </>
            )}
            {hasManageServicesStep(
              ManageServicesEnum.INSTITUTE_SELECTION,
              packageRequest
            ) && (
              <>
                <StepLabel
                  label="Institute Selection"
                  route={ManageServicesEnum.INSTITUTE_SELECTION}
                  packageRequestId={packageRequestId}
                  stepNumber="4"
                  isSelected={
                    currentStep === ManageServicesEnum.INSTITUTE_SELECTION
                  }
                  icon={faSchool}
                />
                <ArrowStepBar />
              </>
            )}
            <StepLabel
              label="Review"
              route={ManageServicesEnum.REVIEW}
              packageRequestId={packageRequestId}
              stepNumber="5"
              isSelected={currentStep === ManageServicesEnum.REVIEW}
              icon={faTasks}
            />
          </div>
        )}
      </div>
      <div className="mt-4">{props.children}</div>
    </>
  );
};

export default Packages;
