import React from 'react';
interface PackageApplicationsProps {
  path: string;
  children: React.ReactChildren;
}

const PackageApplications = (props: PackageApplicationsProps) => {
  return <>{props.children}</>;
};

export default PackageApplications;
