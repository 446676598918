import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Select from 'react-select';
import { AuthContext } from '../../context/AuthContext';
import Card from '../common/Card';
import LoadingDialog from '../common/LoadingDialog';
import SEO from '../Seo';
import { getOrganizationAdminAffiliations } from './api';
import SelectService from './SelectService';
interface OrganizationSelect {
  path: string;
  children: React.ReactChildren;
}
const OrganizationSelect = (props: OrganizationSelect) => {
  const location = useLocation();
  const auth = useContext(AuthContext);

  const splitPath = location.pathname.split('/');
  const currentRoute = splitPath[2];

  const [selectedOrganization, setSelectedOrganization] = useState();
  const { data, isLoading } = useQuery(
    'organizations',
    getOrganizationAdminAffiliations
  );
  const [adminOrOwnerOrgs, setAdminOrOwnerOrgs] = useState();

  useEffect(() => {
    setAdminOrOwnerOrgs(data?.organizations);
  }, [data, setAdminOrOwnerOrgs, auth]);

  return (
    <>
      <SEO title="Organization Selection" />
      <Card>
        <h2 className="text-primary font-bold text-2xl">
          Select an Organization
        </h2>
        {isLoading ? <LoadingDialog /> : null}
        {data && data.organizations ? (
          <div className="mt-4">
            {currentRoute === 'manage-services' ? (
              <p className="sans-serif text-lg mb-2">
                Select the organization you would like to manage services for
              </p>
            ) : currentRoute === 'organization' ? (
              <p className="sans-serif text-lg mb-2">
                Select the organization you would like to manage
              </p>
            ) : null}
            <Select
              className="w-1/2"
              options={adminOrOwnerOrgs?.map(org => ({
                value: org.id,
                label: org.name
              }))}
              onChange={({ value }) => {
                setSelectedOrganization(value);
              }}
            />
          </div>
        ) : null}
      </Card>
      {selectedOrganization && currentRoute === 'manage-services' && (
        <div className="mt-4">
          <SelectService organizationId={String(selectedOrganization)} />
        </div>
      )}
      {selectedOrganization &&
        currentRoute === 'organization' &&
        navigate(`/members/organization/${String(selectedOrganization)}`)}
    </>
  );
};

export default OrganizationSelect;
