import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { countries } from '../../data/countries';
import { FormField, FormWrapper } from '../common/Form';
import moment from 'moment';

const NewStaffMemberSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Email is required'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  zipPostal: Yup.string()
    .max(10)
    .nullable(),
  country: Yup.string().required('Country is required'),
  grades: Yup.array().required('Grade selection is required'),
  position: Yup.string().required('Position is required'),
  toolkitAccess: Yup.boolean(),
  dateOfBirth: Yup.string()
    .test('validate-date', 'Date is invalid', (value: any) => {
      if (!value) return true;
      return moment(value, 'MM/DD/YYYY').isValid();
    })
    .nullable(),
  ssnLastFour: Yup.number()
    .min(1000)
    .max(9999)
    .nullable()
});

interface NewStaffMemberProps {
  positions: any[];
  grades: any[];
  organizationId: string;
  onCancel: () => void;
  onSubmit: (userData: any) => void;
  isSubmitting: boolean;
}

export type NewStaffMemberForm = {
  email: string;
  firstName: string;
  lastName: string;
  zipPostal?: string;
  country: string;
  position: string;
  grades: string;
  toolkitAccess?: boolean;
  dateOfBirth?: string;
  ssnLastFour?: string;
};

type IForm = NewStaffMemberForm;

const NewStaffMember = (props: NewStaffMemberProps) => {
  const formik = useFormik<IForm>({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      zipPostal: '',
      country: 'United States',
      position: '',
      grades: '',
      toolkitAccess: false,
      dateOfBirth: '',
      ssnLastFour: ''
    },
    validationSchema: NewStaffMemberSchema,
    onSubmit: (values: any) => {
      props.onSubmit(values);
    }
  });

  return (
    <section className="p-4 border-gray-300 border rounded-lg">
      <p className="text-primary sans-serif font-bold text-lg mb-4">
        Add a staff member
      </p>

      <FormWrapper
        formik={formik}
        isLoading={props.isSubmitting}
        onCancel={() => {
          formik.resetForm();
          props.onCancel();
        }}
      >
        <FormField<IForm>
          name="email"
          type="email"
          autoFocus
          className="mb-2 w-96 pr-4"
        />
        <div className="flex border-b border-gray-200 pb-8 w-1/2 gap-x-2">
          <FormField<IForm> name="firstName" type="text" />
          <FormField<IForm> name="lastName" type="text" />
        </div>
        <div className="flex border-b border-gray-200 pb-8 w-1/2 gap-x-2 mt-6">
          <FormField<IForm>
            name="zipPostal"
            label="Zip / Postal Code"
            type="text"
          />
          <FormField<IForm>
            name="country"
            type="select"
            className="w-44"
            options={countries.map(c => ({
              label: c.name,
              value: c.name
            }))}
          />
        </div>

        <div className="mt-6 border-b border-gray-200 pb-8 w-1/2">
          <div className="flex mb-2 gap-x-2">
            <FormField<IForm>
              name="position"
              type="select"
              className="w-44"
              options={props.positions.map(p => ({
                label: p.label,
                value: p.name
              }))}
            />
            <FormField<IForm>
              name="grades"
              type="select"
              className="w-44"
              isMulti
              options={props.grades.map(g => ({
                label: g.name,
                value: g.id
              }))}
            />
          </div>
          <FormField<IForm>
            name="toolkitAccess"
            label="Allow Toolkit Access"
            type="checkbox"
            className="mt-4"
          />
        </div>
        <div className="mt-6 mb-4 w-1/2">
          <p className="text-sm text-gray-700 sans-serif">
            If you are a teacher in New York state and wish to receive CTLE
            (Continuing Teacher and Leadership Education) credit for any events
            please provide your DOB and the last four numbers of your SSN.
          </p>
          <div className="mt-4 flex mb-4 gap-x-2">
            <FormField<IForm>
              name="dateOfBirth"
              type="mask"
              mask="dateOfBirth"
              placeholder="MM/DD/YYYY"
            />

            <FormField<IForm>
              name="ssnLastFour"
              label="Last 4 of SSN"
              type="number"
            />
          </div>
        </div>
      </FormWrapper>
    </section>
  );
};

export default NewStaffMember;
