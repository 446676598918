import React, { useState } from 'react';
import { QueryReturnState } from '../Enum';
import { IGrade, IOrganization, IPosition } from '../NewApiTypes';
import { debounce } from 'lodash';

import {
  getGradesTCAL,
  getPositionsTCAL,
  getOrganizationsForSelfAffiliationTCAL
} from '../../../tcal-api';

import { useQuery } from 'react-query';
import { useFormik } from 'formik';
import { FormField, FormWrapper } from '../../common/Form';
import * as Yup from 'yup';
import Button from '../../common/Button';
import CreateOrganizationModal, {
  CreateOrganizationType
} from '../CreateOrganizationModal';
import { SEARCH_DEBOUNCE_TIME } from '../../../constants/input';
import { OrganizationMembership } from '../NewApiTypes.generated';
import {
  CustomDropdownOption,
  CustomDropdownOptionType
} from '../../common/CustomDropdownOption';

export type AddOwnAffiliationForm = {
  organization: any;
  position: string;
  grades: string[];
};

type IForm = AddOwnAffiliationForm;

const AddOwnAffiliationSchema: Yup.SchemaOf<AddOwnAffiliationForm> = Yup.object().shape(
  {
    organization: Yup.object().required('Organization is required'),
    position: Yup.string().required('Position is required'),
    grades: Yup.array()
      .of(Yup.string().required())
      .min(1, 'Grade selection is required')
  }
);

const AddAffiliationForm = ({
  onCancel,
  onSubmit,
  onDelete,
  deleteLoading,
  loading,
  organizationMembership,
  onCreateOrganizationSubmit,
  loadingCreateOrganization
}: {
  onCancel: () => void;
  onDelete?: () => Promise<void>;
  onSubmit: (values: AddOwnAffiliationForm) => Promise<void>;
  loading?: boolean;
  deleteLoading?: boolean;
  organizationMembership?: OrganizationMembership;
  onCreateOrganizationSubmit: (values: CreateOrganizationType) => Promise<void>;
  loadingCreateOrganization?: boolean;
}) => {
  const [
    isVisibleAddOrganizationModal,
    setIsVisibleAddOrganizationModal
  ] = useState(false);

  const [organizationLoading, setOrganizationLoading] = useState(false);

  const { data: gradesData, isFetching: isLoadingGrades } = useQuery<{
    state: QueryReturnState;
    grades?: IGrade[];
  }>('grades', getGradesTCAL);

  const { data: positionsData, isLoading: isLoadingPositions } = useQuery<{
    state: QueryReturnState;
    positions?: IPosition[];
  }>('positions', getPositionsTCAL);

  const loadOrganizationOptions = async (
    inputValue: string,
    callback: (options: Array<CustomDropdownOptionType>) => void
  ) => {
    setOrganizationLoading(true);
    const result = await getOrganizationsForSelfAffiliationTCAL(inputValue);
    const organizations = result.organizations as IOrganization[];
    setOrganizationLoading(false);
    callback(
      organizations.map(o => ({
        value: o.id,
        label: o.name,
        description: o.district as any
      }))
    );
  };

  const formik = useFormik<AddOwnAffiliationForm>({
    validationSchema: AddOwnAffiliationSchema,
    initialValues: {
      grades: organizationMembership?.grades?.map(g => g?.id || '') || [],
      organization: organizationMembership?.organization?.id
        ? {
            label: organizationMembership?.organization?.name,
            value: organizationMembership?.organization?.id
          }
        : '',
      position: organizationMembership?.position?.name || ''
    },
    onSubmit
  });

  const gradeOptions =
    gradesData?.grades?.map(g => ({
      label: g.name,
      value: g.id
    })) || [];
  const positionOptions =
    positionsData?.positions?.map(p => ({
      label: p.label,
      value: p.name
    })) || [];

  return (
    <>
      {isVisibleAddOrganizationModal && (
        <CreateOrganizationModal
          isLoading={!!loadingCreateOrganization}
          onCancel={() => setIsVisibleAddOrganizationModal(false)}
          onSubmit={onCreateOrganizationSubmit}
          positionOptions={positionOptions}
          isLoadingPositions={isLoadingPositions}
          gradeOptions={gradeOptions}
          isLoadingGrades={isLoadingGrades}
        />
      )}
      <FormWrapper
        onCancel={onCancel}
        formik={formik}
        isLoading={loading}
        className="px-3 py-4 border border-gray-300 bg-gray-100 rounded-xl"
        customBtns={
          <>
            {organizationMembership && onDelete && (
              <Button
                isDanger
                text="Remove affiliation"
                size="sm"
                type="button"
                onClick={() => onDelete()}
                isLoading={deleteLoading}
              />
            )}
          </>
        }
      >
        <span className="mb-1.5 font-semibold sans-serif">
          {organizationMembership
            ? 'Edit affiliation'
            : 'Add a new affiliation'}
        </span>
        <div className="flex gap-x-2 w-full ">
          {organizationMembership ? (
            <div className="flex flex-col mt-0.5 gap-y-1 w-1/2">
              <div className="sans-serif text-gray-500 uppercase mb-1 text-sm">
                Organization
              </div>
              <div className="text-xl font-semibold">
                {organizationMembership?.organization?.name}
              </div>
            </div>
          ) : (
            <FormField<IForm>
              name="organization"
              type="async-select"
              isLoading={organizationLoading}
              onChange={data =>
                formik.setFieldValue('organization', data, true)
              }
              loadOptions={debounce(
                loadOrganizationOptions,
                SEARCH_DEBOUNCE_TIME
              )}
              className="w-1/2"
              components={{ Option: CustomDropdownOption as any }}
            />
          )}
          <FormField<IForm>
            name="position"
            type="select"
            className="w-1/4"
            options={positionOptions}
            isLoading={isLoadingPositions}
          />
          <FormField<IForm>
            name="grades"
            type="select"
            className="w-1/4"
            isMulti
            options={gradeOptions}
            isLoading={isLoadingGrades}
          />
        </div>
        <div>
          Organization not listed?{' '}
          <button
            className="text-primary bg-transparent border-none"
            onClick={() => setIsVisibleAddOrganizationModal(true)}
            type="button"
          >
            Create a new organization
          </button>
        </div>
      </FormWrapper>
    </>
  );
};

export default AddAffiliationForm;
