import { RadioGroup } from '@headlessui/react';
import React from 'react';
import { EventPathApplicationSelectionLevel } from './NewApiTypes.generated';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

interface EventPathGroupingsRadioProps {
  entries: any[];
  applicationSelectionLevel: EventPathApplicationSelectionLevel;
  selectedEntries: string;
  setSelected: (value: string) => void;
}

const renderOption = (entry: any, idx: number, entriesLength: number) => {
  return (
    <RadioGroup.Option
      key={entry.id}
      value={entry.id}
      className={({ checked }) =>
        classNames(
          idx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
          idx === entriesLength - 1 ? 'rounded-bl-md rounded-br-md' : '',
          checked ? 'z-10 border-primary bg-blue-50' : 'border-gray-200',
          'relative flex items-center cursor-pointer border p-4 '
        )
      }
    >
      {({ active, checked }) => (
        <>
          <span
            className={classNames(
              checked
                ? 'bg-blue-800 border-transparent'
                : 'bg-white border-gray-300',
              'h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center outline-none'
            )}
            aria-hidden="true"
          >
            <span className="rounded-full bg-white w-1.5 h-1.5" />
          </span>
          <span className="ml-3 flex flex-col w-full">
            <RadioGroup.Label
              as="h2"
              className={classNames(
                checked ? 'text-primary' : 'text-gray-600',
                'my-1 block text-sm font-bold'
              )}
            >
              {entry.displayTitle || entry.title}
            </RadioGroup.Label>
          </span>
        </>
      )}
    </RadioGroup.Option>
  );
};

const EventPathGroupingsRadio = ({
  entries,
  selectedEntries,
  applicationSelectionLevel,
  setSelected
}: EventPathGroupingsRadioProps) => {
  return (
    <RadioGroup defaultValue={selectedEntries} onChange={setSelected}>
      <div className="bg-white">
        {applicationSelectionLevel === 'GROUPINGS'
          ? entries.map((entry, idx) =>
              renderOption(entry, idx, entries.length)
            )
          : entries.map(entry => (
              <div className="mb-4" key={entry.id}>
                <div className="text-gray-500 font-bold mb-1">
                  {entry.title}
                </div>
                {entry.sections.map((section: any, idx: number) =>
                  renderOption(section, idx, entry.sections.length)
                )}
              </div>
            ))}
      </div>
    </RadioGroup>
  );
};

export default EventPathGroupingsRadio;
