import React, { useContext, useEffect, useState } from 'react';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import classnames from 'classnames';
import { useDebouncedCallback } from 'use-debounce';
import { flatPolyfill, formatDateWithoutTimezone } from '../../util';
import ManageRosterLink from './ManageRosterLink';
import './members.css';
import NoSelectionsWarning from './NoSelectionsWarning';
import { ProductPanelTitle } from './ProductPanel';
import SelectionInstructions from './SelectionInstructions';
import UserSelect from './UserSelect';
import {
  ConferenceDayTicketAssignment,
  TicketAssignments,
  UserOptionType
} from './AssignTickets';
import { PackageContext } from '../../context/PackageContext';
import SearchInput from '../SearchInput';
import { Event, EventSection, User } from './NewApiTypes.generated';
import {
  getTicketAllocationNotAvailableMessage,
  isNotAvailableForPurchase
} from '../../helpers/eventSections.helper';
import UnableToEditNotice from './UnableToEditNotice';
import { getUserInfo } from '../../util/auth';

interface PackageProductPanelProps {
  title: string;
  open: boolean;
  conferenceDaysParticipantsMissalocatedAmount: number;
  conferenceDaysParticipantsTotalAmount: number;
  complete: boolean;
  description: string;
  dates: string[];
  location: string;
  grades: string;
  usersOptions: UserOptionType[];
  isFree: boolean;
  // ticketOptions: any;
  isClosed: boolean;
  blockApplicantAllocationMessage?: string;
  maxAmount: number;
  assignedUsers: UserOptionType[];
  // onTicketSelect: any;
  onTicketAssignSelect: any;
}

const PackageProductPanel = (props: PackageProductPanelProps) => {
  const buttonClasses = classnames({
    'flex justify-between w-full px-4 py-2 font-medium text-left text-blue-100 rounded-t-lg focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75 sans-serif': true,
    'rounded-b-lg': !props.open,
    'bg-primary hover:bg-primary-dark': !props.isFree,
    'bg-green-700 hover:bg-green-800': props.isFree
  });
  const chevronClasses = classnames({
    'w-5 h-5 text-blue-100': true,
    'transform rotate-180': props.open
  });

  return (
    <>
      <Disclosure.Button className={buttonClasses}>
        <span className="sans-serif text-lg">
          <FontAwesomeIcon
            icon={props.assignedUsers?.length > 0 ? faCheckCircle : faCircle}
            className="text-blue-100 mr-2"
          />
          {props.isFree && (
            <span className="text-white py-1 px-2 my-auto mx-2 text-sm sans-serif border-white border rounded-full">
              Free
            </span>
          )}
          {props.title}
        </span>
        <ChevronUpIcon className={chevronClasses} />
      </Disclosure.Button>
      <Disclosure.Panel className="p-6 text-gray-700 sans-serif border-gray-300 border-b border-l border-r rounded-b-lg">
        {props.isClosed ||
          (props.blockApplicantAllocationMessage && (
            <div className="w-1/2 m-auto mt-2 mb-6">
              <UnableToEditNotice
                text={props.blockApplicantAllocationMessage}
              />
            </div>
          ))}
        <div className="mb-4 flex justify-between">
          <section className="flex">
            <div className="mr-12">
              <ProductPanelTitle title="Date(s)" />
              {!props.dates.length && <p>TBD</p>}
              {!!props.dates.length &&
                props.dates.map((d, i: number) => (
                  <p key={i}>{formatDateWithoutTimezone(new Date(d), true)}</p>
                ))}
            </div>
            <div className="mr-12">
              <ProductPanelTitle title="Location" />
              <p>{props.location}</p>
            </div>
            <div className="mr-12">
              <ProductPanelTitle title="Grade(s)" />
              <p>{props.grades}</p>
            </div>
          </section>
          <section className="flex">
            {/* <div className="mr-12">
              <ProductPanelTitle title="Number of Tickets" />
              <Select
                isDisabled={props.isClosed || props.blockApplicantAllocationMessage}
                options={props.ticketOptions.map((n: any) => ({
                  value: n,
                  label: n
                }))}
                defaultValue={{ value: props.amount, label: props.amount }}
                onChange={value => props.onTicketSelect(value)}
              />
            </div> */}
            <div className="w-96">
              <ProductPanelTitle title="Assign Tickets" />
              <UserSelect
                useCheckedSelect
                isDisabled={
                  props.isClosed || !!props.blockApplicantAllocationMessage
                }
                options={props.usersOptions}
                defaultValue={props.assignedUsers}
                onChange={(value: any) => props.onTicketAssignSelect(value)}
              />
            </div>
          </section>
        </div>
        {props.assignedUsers && (
          <div className="flex flex-col w-full">
            <div className="self-end">
              <p
                className={`sans-serif ${
                  props.assignedUsers?.length > props.maxAmount
                    ? 'text-red-600'
                    : 'text-gray-600'
                }`}
              >
                Maximum Tickets Per Session: {props.maxAmount}
              </p>
            </div>
            <div className="self-end">
              {props.conferenceDaysParticipantsTotalAmount > 0 &&
              props.conferenceDaysParticipantsMissalocatedAmount === 0 ? (
                <p className="text-green-700 sans-serif">
                  All tickets allocated
                </p>
              ) : props.conferenceDaysParticipantsMissalocatedAmount > 0 ? (
                <p className="text-gray-600 sans-serif font-bold">
                  {props.conferenceDaysParticipantsTotalAmount -
                    props.conferenceDaysParticipantsMissalocatedAmount}{' '}
                  of {props.conferenceDaysParticipantsTotalAmount} total package
                  tickets used
                </p>
              ) : (
                <p className="text-red-600 sans-serif">
                  Tickets are overallocated. Please correct before saving.
                </p>
              )}
            </div>
          </div>
        )}
        <div className="flex mt-6">
          <div>
            <ProductPanelTitle title="More Info" />
            <div
              className="conference-day-tickets-body"
              dangerouslySetInnerHTML={{ __html: props.description }}
            />
          </div>
        </div>
      </Disclosure.Panel>
    </>
  );
};

const getTicketOptions = (minSelectable: number, maxSelectable: number) => {
  let n = minSelectable;

  const arr = [n];

  if (minSelectable <= maxSelectable) {
    while (n < maxSelectable) {
      arr.push(++n);
    }
  }

  return arr;
};

const getFlatSectionsFromEvent = (event?: Event) => {
  if (!event) return [];
  return flatPolyfill(
    event.paths.map(p => p?.groupings.map(g => g?.sections || []) || []),
    2
  );
};

interface AllocateTicketsProps {
  ticketAssignments: TicketAssignments;
  packageRequestOrganizationUsers: (User & { isPending?: boolean })[];
  setHasUnsavedChanges: any;
  tickets: ConferenceDayTicketAssignment[];
  onUpdateOrganizationPackage: (a: ConferenceDayTicketAssignment) => void;
  conferenceDaysParticipantsMissalocatedAmount: number;
  conferenceDaysParticipantsTotalAmount: number;
}
const AllocateTickets = (props: AllocateTicketsProps) => {
  const {
    ticketAssignments,
    tickets,
    conferenceDaysParticipantsMissalocatedAmount,
    conferenceDaysParticipantsTotalAmount
  } = props;
  const packageContext = useContext(PackageContext);
  const [searchTerm, setSearchTerm] = useState(null);

  const [visibleEventsAndSections, setVisibleEventsAndSections] = useState<
    Event[]
  >(ticketAssignments.calendarOfConferenceDays || []);

  const debounced = useDebouncedCallback(
    events => setVisibleEventsAndSections(events),
    300
  );
  const [user, setUser] = useState();
  useEffect(() => {
    const fetchUser = async () => {
      setUser(await getUserInfo());
    };
    fetchUser();
  }, []);

  return (
    <>
      <h3 className="text-2xl text-gray-600 font-bold mt-4">
        Allocate Conference Day Tickets
      </h3>
      <div className="my-2">
        <ManageRosterLink
          organizationId={String(packageContext.organizationId)}
          withArrow
        />
      </div>
      {!ticketAssignments?.calendarOfConferenceDays.length && (
        <div className="w-1/2 m-auto my-6">
          <NoSelectionsWarning />
        </div>
      )}

      {
        <div className="mt-4">
          <div className="w-1/3">
            <SearchInput
              onChange={(e: any) => {
                if (!e.target.value) {
                  setSearchTerm(null);
                  return debounced.callback(
                    ticketAssignments?.calendarOfConferenceDays
                  );
                }
                setSearchTerm(e.target.value);
                const term = new RegExp(e.target.value, 'gi');
                debounced.callback(
                  ticketAssignments?.calendarOfConferenceDays
                    .map(ev => ({
                      ...ev,
                      paths:
                        ev.paths.map(p => ({
                          ...p,
                          groupings:
                            p?.groupings.map(g => ({
                              ...g,
                              sections:
                                g?.sections.filter(s => s?.title.match(term)) ||
                                []
                            })) || []
                        })) || []
                    }))
                    .filter(e => {
                      return e.paths.some(p =>
                        p?.groupings.some(g => g?.sections.length)
                      );
                    })
                );
              }}
              placeholder="Search Calendar Days"
            />
          </div>

          <div className="w-full mt-4 flex flex-col divide-y gap-3">
            {!!visibleEventsAndSections &&
              visibleEventsAndSections.map((ev, i: number) => (
                <div key={i} className="pt-2">
                  <p className="sans-serif text-2xl text-gray-800">{ev.name}</p>
                  <p className="sans-serif text-lg text-gray-700  ">
                    Tickets Available to be Allocated for Conference Day
                    Sections
                  </p>
                  <div className="flex mx-auto justify-center w-1/5 border-primary p-4 rounded-lg border">
                    {`${props.conferenceDaysParticipantsTotalAmount -
                      props.conferenceDaysParticipantsMissalocatedAmount} of ${
                      props.conferenceDaysParticipantsTotalAmount
                    } Tickets Assigned`}
                  </div>

                  <div className="w-1/2 m-auto my-6">
                    <SelectionInstructions />
                  </div>
                  <div className="w-1/3">
                    <div className="my-4">
                      {searchTerm && (
                        <>
                          <p className="italic text-gray-500">
                            Showing results for filter "{searchTerm}"
                          </p>
                          <p className="italic text-red-600">
                            {getFlatSectionsFromEvent(ev).length} of{' '}
                            {
                              getFlatSectionsFromEvent(
                                ticketAssignments.calendarOfConferenceDays.find(
                                  e => e.id === ev.id
                                )
                              ).length
                            }{' '}
                            Calendar Days shown
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  {ev.paths.map(p =>
                    p?.groupings.map(g =>
                      g?.sections
                        .sort(
                          (a, b) => new Date(a.dates[0]) - new Date(b.dates[0])
                        )
                        .map((s, i) => (
                          <div className="mb-2" key={i}>
                            <Disclosure>
                              {({ open }) => {
                                const sectionTickets: ConferenceDayTicketAssignment = tickets?.find(
                                  el =>
                                    el.eventId === ev.id &&
                                    el.sectionId === s?.id
                                ) || {
                                  eventId: ev.id,
                                  sectionId: s?.id || '',
                                  assignedUsers: []
                                };
                                return (
                                  <PackageProductPanel
                                    title={`${s?.dates
                                      .map(d =>
                                        formatDateWithoutTimezone(
                                          new Date(d as string),
                                          true
                                        )
                                      )
                                      .join(', ')} - ${
                                      s?.title
                                    } - ${s?.audienceType?.join(', ')}`}
                                    open={open}
                                    conferenceDaysParticipantsMissalocatedAmount={
                                      conferenceDaysParticipantsMissalocatedAmount
                                    }
                                    conferenceDaysParticipantsTotalAmount={
                                      conferenceDaysParticipantsTotalAmount
                                    }
                                    complete={false}
                                    description={s?.description || ''}
                                    location={s?.location?.name || ''}
                                    grades={s?.audienceType?.join(', ') || ''}
                                    dates={(s?.dates || []) as string[]}
                                    usersOptions={
                                      props.packageRequestOrganizationUsers?.map(
                                        sd => ({
                                          value: sd?.id || '',
                                          label:
                                            [
                                              sd?.firstName,
                                              sd?.lastName,
                                              sd?.isPending && '(Pending)'
                                            ]
                                              .filter(Boolean)
                                              .join(' ') || '',
                                          email: sd?.email || ''
                                        })
                                      ) || []
                                    }
                                    isFree={!!s?.isFree}
                                    // ticketOptions={getTicketOptions(
                                    //   s?.minTickets || 0,
                                    //   s?.maxTickets || 0
                                    // )}
                                    isClosed={
                                      // s.ClosedFlag
                                      false
                                    }
                                    blockApplicantAllocationMessage={
                                      isNotAvailableForPurchase({
                                        eventSection: s as EventSection,
                                        event: ev,
                                        type: 'CONFERENCE_DAY',
                                        isImpersonated: (user as any)
                                          ?.impersonator
                                      })
                                        ? getTicketAllocationNotAvailableMessage(
                                            ev.daysBeforePurchaseClose
                                          )
                                        : undefined
                                    }
                                    maxAmount={s?.maxTickets || 0}
                                    assignedUsers={
                                      sectionTickets.assignedUsers || []
                                    }
                                    onTicketAssignSelect={(
                                      assignedUsers: any
                                    ) => {
                                      const newSectionAssignedUsers = {
                                        assignedUsers: assignedUsers || [],
                                        sectionId: s?.id || '',
                                        eventId: ev?.id || ''
                                      };

                                      props.onUpdateOrganizationPackage(
                                        newSectionAssignedUsers
                                      );
                                      props.setHasUnsavedChanges(true);
                                    }}
                                  />
                                );
                              }}
                            </Disclosure>
                          </div>
                        ))
                    )
                  )}
                </div>
              ))}
          </div>
        </div>
      }
    </>
  );
};

export default AllocateTickets;
