import React, { useContext, useState } from 'react';
import classnames from 'classnames';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { AuthContext } from '../../context/AuthContext';
import Button from '../common/Button';
import Card from '../common/Card';
import LoadingDialog from '../common/LoadingDialog';
import SEO from '../Seo';
import AllocateTickets from './AllocateTickets';
import AssignApplicants from './AssignApplicants';
import AssignStaff from './AssignStaff';
import CtleNotice from './CtleNotice';
import './members.css';
import { OrganizationPackage } from './OrganizationPackageTypes';
import {
  getTotalOrganizationPackageForAssignments,
  savePackage
} from './Queries';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { formatDateWithoutTimezone, formatAsCurrency } from '../../util';
import { getOrgDevSectionByDevSection, shouldShowEvent } from './Util';
import { queryClient } from '../../pages/members';
import TabButton from '../common/TabButton';
import {
  getPackageRequestById,
  getPackageRequestOrganizationUsers,
  getTicketAssignments,
  submitAssignTickets
} from './api';
import { PackageContext } from '../../context/PackageContext';
import { Event, Group, PackageRequest, User } from './NewApiTypes.generated';
import { useFetch } from '../../hooks/useFetch';

const getConferenceDaysParticipantsAmount = (
  packageRequest: PackageRequest
) => {
  return packageRequest?.requestedEvents?.reduce((accum, requestedEvent) => {
    if (
      requestedEvent?.packageEvent?.event?.eventType?.name === 'Conference Day'
    ) {
      const maxParticipants =
        requestedEvent?.overrideAmount ||
        requestedEvent?.requestedParticipants ||
        0;
      return accum + maxParticipants;
    } else {
      return accum;
    }
  }, 0);
};

const getAssignEventsParticipantsAmount = (packageRequest: PackageRequest) => {
  const eventsTickets = packageRequest?.requestedEvents?.reduce(
    (accum, requestedEvent) => {
      if (
        requestedEvent?.packageEvent?.event?.eventType?.name ===
        'Conference Day'
      ) {
        return accum;
      } else {
        const maxParticipants =
          requestedEvent?.overrideAmount ||
          requestedEvent?.requestedParticipants ||
          0;
        return accum + maxParticipants;
      }
    },
    0
  );

  return eventsTickets;
};

const getAssignGroupsParticipantsAmount = (packageRequest: PackageRequest) => {
  const groupTickets = packageRequest?.requestedGroups?.reduce(
    (accum, requestedGroup) => {
      const maxParticipants =
        (requestedGroup?.overrideDate
          ? requestedGroup?.overrideRequestedSections
          : requestedGroup?.requestedSections
        )?.reduce((sectionAccum, reqSection) => {
          return sectionAccum + (reqSection?.requestedParticipants || 0);
        }, 0) || 0;
      return accum + maxParticipants;
    },
    0
  );

  return groupTickets;
};

const getAssignInstututesParticipantsAmount = (
  packageRequest: PackageRequest
) => {
  const institutesTickets = packageRequest?.requestedInstitutes?.reduce(
    (accum, requestedInstitute) => {
      const maxParticipants =
        requestedInstitute?.overrideAmount ||
        requestedInstitute?.requestedParticipants ||
        0;
      return accum + maxParticipants;
    },
    0
  );

  return institutesTickets;
};

const getEventsMissallocationsAmount = (
  packageRequest: PackageRequest,
  ticketsAssignments: TicketAssignments,
  assignedTickets: AssignedTickets
) => {
  const missallocatedAmount = packageRequest?.requestedEvents?.reduce(
    (accum, requestedEvent) => {
      if (
        requestedEvent?.packageEvent?.event?.eventType?.name ===
        'Conference Day'
      ) {
        return accum;
      } else {
        const maxParticipants =
          requestedEvent?.overrideAmount ||
          requestedEvent?.requestedParticipants ||
          0;

        const subtotal = assignedTickets.events
          .filter(ev => ev.eventId === requestedEvent?.packageEvent.eventId)
          .reduce(
            (accum, ev) => (accum += ev.assignedUsers?.filter(Boolean)?.length),
            0
          );

        return accum + maxParticipants - subtotal;
      }
    },
    0
  );

  return missallocatedAmount;
};

const getGroupsMissallocationsAmount = (
  packageRequest: PackageRequest,
  ticketsAssignments: TicketAssignments,
  assignedTickets: AssignedTickets
) => {
  const missallocatedAmount = packageRequest?.requestedGroups?.reduce(
    (accum, requestedGroup) => {
      const maxParticipants =
        (requestedGroup?.overrideDate
          ? requestedGroup?.overrideRequestedSections
          : requestedGroup?.requestedSections
        )?.reduce((sectionAccum, reqSection) => {
          return sectionAccum + (reqSection?.requestedParticipants || 0);
        }, 0) || 0;

      const subtotal = assignedTickets.groups
        .filter(g => g.groupId === requestedGroup?.packageGroup?.group?.id)
        .reduce(
          (accum, g) => (accum += g.assignedUsers?.filter(Boolean)?.length),
          0
        );

      return accum + maxParticipants - subtotal;
    },
    0
  );

  return missallocatedAmount;
};

const getInstitutesMissallocationsAmount = (
  packageRequest: PackageRequest,
  ticketsAssignments: TicketAssignments,
  assignedTickets: AssignedTickets
) => {
  const missallocatedAmount = packageRequest?.requestedInstitutes?.reduce(
    (accum, requestedInstitute) => {
      const maxParticipants =
        requestedInstitute?.overrideAmount ||
        requestedInstitute?.requestedParticipants ||
        0;

      const subtotal = assignedTickets.institutes
        .filter(
          i => i.instituteId === requestedInstitute?.packageInstitute?.eventId
        )
        .reduce(
          (accum, i) => (accum += i.assignedUsers?.filter(Boolean)?.length),
          0
        );

      return accum + maxParticipants - subtotal;
    },
    0
  );

  return missallocatedAmount;
};

const getMissallocationsAmount = (
  packageRequest: PackageRequest,
  ticketsAssignments: TicketAssignments,
  assignedTickets: AssignedTickets
) => {
  // for now check only coference days
  const missallocatedAmount = packageRequest?.requestedEvents?.reduce(
    (accum, requestedEvent) => {
      if (
        requestedEvent?.packageEvent?.event?.eventType?.name ===
        'Conference Day'
      ) {
        const maxParticipants =
          requestedEvent?.overrideAmount ||
          requestedEvent?.requestedParticipants ||
          0;
        const subtotal = assignedTickets.conferenceDays
          .filter(cd => {
            const isFree = ticketsAssignments?.calendarOfConferenceDays
              .find(ev => ev.id === cd.eventId)
              ?.paths.some(p =>
                p?.groupings.some(g =>
                  g?.sections?.some(s => s?.id === cd.sectionId && s?.isFree)
                )
              );
            return (
              cd.eventId === requestedEvent?.packageEvent.eventId && !isFree
            );
          })
          .reduce((accum, cd) => (accum += cd.assignedUsers.length), 0);
        return accum + maxParticipants - subtotal;
      } else {
        return accum;
      }
    },
    0
  );

  return missallocatedAmount;
};

const sectionMaxParticipantsExceeded = (
  ticketsAssignments: TicketAssignments,
  assignedTickets: AssignedTickets
) => {
  if (
    // check coference days
    assignedTickets.conferenceDays.some(cd =>
      ticketsAssignments?.calendarOfConferenceDays
        .find(ev => ev.id === cd.eventId)
        ?.paths.some(p =>
          p?.groupings.some(g =>
            g?.sections?.some(
              s =>
                s?.id === cd.sectionId &&
                s?.maxTickets < cd.assignedUsers.length
            )
          )
        )
    )
  ) {
    return true;
  }
  if (
    // check groups sections
    assignedTickets.groups.some(cd =>
      ticketsAssignments?.groups
        .find(g => g.id === cd.groupId)
        ?.groupSections?.find(
          gs =>
            gs?.id === cd.sectionId && gs?.maxTickets < cd.assignedUsers.length
        )
    )
  ) {
    return true;
  }
  return false;
};

const makeDeveloperSectionDates = (
  days: number,
  developerSectionDates: any[]
) => {
  const datesWord = days === 1 ? 'Date' : 'Dates';
  const pendingDates = `${days -
    developerSectionDates.length} ${datesWord} TBD`;

  const formattedDates = developerSectionDates.map(d =>
    formatDateWithoutTimezone(new Date(d.Date), true)
  );

  if (days - developerSectionDates.length === 0) {
    return formattedDates;
  }
  return [...formattedDates, pendingDates];
};

enum TABS {
  ALLOCATE_TICKETS = 1,
  ASSIGN_STAFF = 2,
  ASSIGN_APPLICANTS = 3
}
// TODO: rename variables and types
export type TicketAssignments = {
  events: Event[];
  institutes: Event[];
  calendarOfConferenceDays: Event[];
  groups: Group[];
};
interface AssignTicketsProps {
  path: string;
  packageRequestId: string;
}

export type AssignedTickets = {
  conferenceDays: ConferenceDayTicketAssignment[];
  events: EventTicketAssignment[];
  groups: GroupTicketAssignment[];
  institutes: InstituteTicketAssignment[];
};

export type UserOptionType = {
  value: string;
  label: string;
  email: string;
};

export type ConferenceDayTicketAssignment = {
  assignedUsers: UserOptionType[];
  eventId: string;
  sectionId: string;
};

export type GroupTicketAssignment = {
  assignedUsers: UserOptionType[];
  groupId: string;
  sectionId: string;
};

export type EventTicketAssignment = {
  assignedUsers: UserOptionType[];
  eventId: string;
};

export type InstituteTicketAssignment = {
  assignedUsers: (UserOptionType & { status?: string })[];
  instituteId: string;
};

const AssignTickets = (props: AssignTicketsProps) => {
  const auth = useContext(AuthContext);
  const [selectedTab, setSelectedTab] = useState(TABS.ALLOCATE_TICKETS);
  const [downloadSummaryLoading, setDownloadSummaryLoading] = useState(false);

  const unsavedChangesAlertMessage =
    'The page has unsaved changes. Please save before continuing.';

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const { isLoading: isLoadingTicketAssignments, data, refetch } = useQuery<
    TicketAssignments & {
      calendarOfConferenceDaysTickets: ConferenceDayTicketAssignment[];
      eventsTickets: EventTicketAssignment[];
      groupsTickets: GroupTicketAssignment[];
      institutesTickets: InstituteTicketAssignment[];
    }
  >('ticketAssignments', () => getTicketAssignments(props.packageRequestId), {
    onSuccess: dt => {
      setAssignedTickets({
        conferenceDays: dt.calendarOfConferenceDaysTickets || [],
        events: dt.eventsTickets || [],
        groups: dt.groupsTickets || [],
        institutes: dt.institutesTickets || []
      });
    }
  });

  const {
    isLoading: isLoadingPROrganizationUsers,
    data: PROrganizationUsers
  } = useQuery<{ users: User[]; pendingUsers: User[] }>(
    'packageRequestOrganizationUsers',
    () => getPackageRequestOrganizationUsers(props.packageRequestId)
  );

  const [packageRequest, setPackageRequest] = useState<PackageRequest | null>(
    null
  );

  const packageContext = useContext(PackageContext);
  useQuery<{
    packageRequest: PackageRequest;
  }>(
    'packageRequestQuery',
    () => getPackageRequestById(props.packageRequestId),
    {
      onSuccess: data => {
        setPackageRequest(data.packageRequest);
      }
    }
  );
  const [assignedTickets, setAssignedTickets] = useState<AssignedTickets>({
    conferenceDays: [],
    events: [],
    groups: [],
    institutes: []
  });

  const { apply: applyUpdatePackageRequest } = useFetch(submitAssignTickets);

  const [loadingSave, setLoadingSave] = useState(false);

  const submitSave = async ({
    segment: _segment,
    data
  }: {
    segment: TABS;
    data: any;
  }) => {
    setLoadingSave(true);

    const segment =
      _segment === TABS.ALLOCATE_TICKETS
        ? 'allocate-tickets'
        : _segment === TABS.ASSIGN_STAFF
        ? 'assign-staff'
        : _segment === TABS.ASSIGN_APPLICANTS
        ? 'assign-applicants'
        : null;

    const res = await applyUpdatePackageRequest(
      { segment, data },
      props.packageRequestId
    );

    if (res.state === 'SUCCESS') {
      toast.success('Ticket allocations saved');
      if (_segment === TABS.ASSIGN_APPLICANTS) {
        refetch();
      }
      packageContext.setHasUnsavedChanges(false);
      setLoadingSave(false);
      return;
    }

    setLoadingSave(false);
    toast.error(res.error || res.data?.message || 'Something went wrong');
  };

  const isLoading = isLoadingPROrganizationUsers || isLoadingTicketAssignments;

  return (
    <>
      <SEO title="Assign Tickets" />
      {isLoading && <LoadingDialog />}
      {!isLoading && data && (
        <>
          <div className="mb-4">
            <CtleNotice organizationId={packageContext.organizationId} />
          </div>
          <Card>
            <h2 className="sans-serif text-primary font-bold text-2xl border-b border-gray-300">
              {packageContext.packageRequest?.package.name}
            </h2>
            <div className="my-8 flex m-auto justify-center">
              <div className="">
                <TabButton
                  isActive={selectedTab === TABS.ALLOCATE_TICKETS}
                  text="Allocate Conference Day Tickets"
                  onClick={() => {
                    setSelectedTab(TABS.ALLOCATE_TICKETS);
                  }}
                />
              </div>
              <div className="">
                <TabButton
                  isActive={selectedTab === TABS.ASSIGN_STAFF}
                  text="Assign Staff to Events and Groups"
                  onClick={() => {
                    setSelectedTab(TABS.ASSIGN_STAFF);
                  }}
                />
              </div>
              <div className="">
                <TabButton
                  isActive={selectedTab === TABS.ASSIGN_APPLICANTS}
                  text="Assign Institute Applicants"
                  onClick={() => {
                    setSelectedTab(TABS.ASSIGN_APPLICANTS);
                  }}
                />
              </div>
            </div>
            {selectedTab === TABS.ALLOCATE_TICKETS ? (
              <AllocateTickets
                conferenceDaysParticipantsTotalAmount={getConferenceDaysParticipantsAmount(
                  packageRequest as PackageRequest
                )}
                conferenceDaysParticipantsMissalocatedAmount={getMissallocationsAmount(
                  packageRequest as PackageRequest,
                  data,
                  assignedTickets
                )}
                ticketAssignments={data}
                packageRequestOrganizationUsers={[
                  ...(PROrganizationUsers?.users || []),
                  ...(PROrganizationUsers?.pendingUsers || []).map(user => ({
                    ...user,
                    isPending: true
                  }))
                ]}
                tickets={assignedTickets.conferenceDays}
                onUpdateOrganizationPackage={a => {
                  const conferenceDaysAssignedTickets = assignedTickets.conferenceDays.filter(
                    ev =>
                      !(
                        ev.eventId === a.eventId && ev.sectionId === a.sectionId
                      )
                  );

                  setAssignedTickets({
                    ...assignedTickets,
                    conferenceDays: [...conferenceDaysAssignedTickets, a]
                  });
                }}
                setHasUnsavedChanges={setHasUnsavedChanges}
              />
            ) : selectedTab === TABS.ASSIGN_STAFF ? (
              <AssignStaff
                ticketAssignments={data}
                packageRequestOrganizationUsers={[
                  ...(PROrganizationUsers?.users || []),
                  ...(PROrganizationUsers?.pendingUsers || []).map(user => ({
                    ...user,
                    isPending: true
                  }))
                ]}
                amounts={{
                  events: {
                    total: getAssignEventsParticipantsAmount(
                      packageRequest as PackageRequest
                    ),
                    allocated:
                      getAssignEventsParticipantsAmount(
                        packageRequest as PackageRequest
                      ) -
                      getEventsMissallocationsAmount(
                        packageRequest as PackageRequest,
                        data,
                        assignedTickets
                      )
                  },
                  groups: {
                    total: getAssignGroupsParticipantsAmount(
                      packageRequest as PackageRequest
                    ),
                    allocated:
                      getAssignGroupsParticipantsAmount(
                        packageRequest as PackageRequest
                      ) -
                      getGroupsMissallocationsAmount(
                        packageRequest as PackageRequest,
                        data,
                        assignedTickets
                      )
                  }
                }}
                eventTickets={assignedTickets.events}
                onUpdateOrganizationPackageEvents={a => {
                  const eventsAssignedTickets = assignedTickets.events.filter(
                    ev => !(ev.eventId === a.eventId)
                  );

                  setAssignedTickets({
                    ...assignedTickets,
                    events: [...eventsAssignedTickets, a]
                  });
                }}
                groupTickets={assignedTickets.groups}
                onUpdateOrganizationPackageGroups={a => {
                  const groupsAssignedTickets = assignedTickets.groups.filter(
                    ev =>
                      !(
                        ev.groupId === a.groupId && ev.sectionId === a.sectionId
                      )
                  );

                  setAssignedTickets({
                    ...assignedTickets,
                    groups: [...groupsAssignedTickets, a]
                  });
                }}
                setHasUnsavedChanges={setHasUnsavedChanges}
              />
            ) : selectedTab === TABS.ASSIGN_APPLICANTS ? (
              <AssignApplicants
                ticketAssignments={data}
                packageRequestOrganizationUsers={[
                  ...(PROrganizationUsers?.users || []),
                  ...(PROrganizationUsers?.pendingUsers || []).map(user => ({
                    ...user,
                    isPending: true
                  }))
                ]}
                amounts={{
                  total: getAssignInstututesParticipantsAmount(
                    packageRequest as PackageRequest
                  ),
                  allocated:
                    getAssignInstututesParticipantsAmount(
                      packageRequest as PackageRequest
                    ) -
                    getInstitutesMissallocationsAmount(
                      packageRequest as PackageRequest,
                      data,
                      assignedTickets
                    )
                }}
                tickets={assignedTickets.institutes}
                onUpdateOrganizationPackage={a => {
                  const instituteAssignedTickets = assignedTickets.institutes.filter(
                    ev => !(ev.instituteId === a.instituteId)
                  );

                  setAssignedTickets({
                    ...assignedTickets,
                    institutes: [...instituteAssignedTickets, a]
                  });
                }}
                setHasUnsavedChanges={setHasUnsavedChanges}
              />
            ) : null}
            <div className="flex justify-end mt-6">
              {/* {hasUnsavedChanges &&
                !getMissallocationsAmount(packageRequest as PackageRequest, data assignedTickets) && (
                  <p className="sans-serif text-red-600 my-auto mr-4">
                    Changes must be saved before summary can be downloaded.
                  </p>
                )} */}
              {/* {getMissallocationsAmount(
                packageRequest as PackageRequest,
                data
                assignedTickets
              ) > 0 && (
                <p className="sans-serif text-red-600 my-auto mr-4">
                  Some ticket selections are not properly allocated. Please
                  correct before saving.
                </p>
              )} */}
            </div>
            <div className="mt-2 flex justify-end">
              <div className="mr-2">
                {/* <Button
                  text="Download Summary"
                  icon={faFilePdf}
                  isLoading={downloadSummaryLoading}
                  disabled={true}
                  // disabled={hasUnsavedChanges}
                  onClick={async () => {
                    // console.log(
                    //   'the assignments',
                    //   organizationPackage.OrganizationPackageTickets.filter(
                    //     t =>
                    //       t.ActualAmount > 0 && !t.ApplicationBasedEventTickets
                    //   ).map(
                    //     orgTicket =>
                    //       orgTicket.OrganizationPackageTicketAssignments
                    //   )
                    // );
                    // const summary = {
                    //   packageName: organizationPackage.Package.Name,
                    //   userFullName: [
                    //     auth.authState?.firstName,
                    //     auth.authState?.lastName
                    //   ]
                    //     .filter(Boolean)
                    //     .join(' '),
                    //   organizationName: organizationPackage.Organization.Name,
                    //   date: formatDateWithoutTimezone(new Date(), true),
                    //   calendarDays: organizationPackage.FilteredSections.filter(
                    //     s => s.Amount > 0
                    //   ).map(s => ({
                    //     title: s.DisplayTitle,
                    //     amount: s.Amount,
                    //     dates: s.EventSectionDates.map((d: any) =>
                    //       formatDateWithoutTimezone(new Date(d.Date), true)
                    //     ),
                    //     grades: s.AudienceType,
                    //     ticketAssignments:
                    //       s.OrganizationPackageTicketAssignments.map(
                    //         (a: any) => ({
                    //           name: a.User.FullName,
                    //           email: a.User.Email
                    //         })
                    //       )
                    //   })),
                    //   nonApplicationBasedEvents:
                    //     organizationPackage.OrganizationPackageTickets.filter(
                    //       t =>
                    //         t.ActualAmount > 0 &&
                    //         !t.ApplicationBasedEventTickets
                    //     )
                    //       .map(orgTicket => {
                    //         return orgTicket.PackageTicket.Ticket.EventTickets.filter(
                    //           t => !!t.Active
                    //         )
                    //           .filter(t =>
                    //             shouldShowEvent(
                    //               t.Event.EventTypeID,
                    //               orgTicket.PackageTicket
                    //             )
                    //           )
                    //           .map(t => {
                    //             return {
                    //               title: t.Event.Name,
                    //               price: formatAsCurrency(
                    //                 orgTicket.PackageTicket.Ticket.TicketPrice
                    //               ),
                    //               numberOfTickets: orgTicket.ActualAmount,
                    //               assignments:
                    //                 orgTicket.OrganizationPackageTicketAssignments.filter(
                    //                   a => !a.TempID && !!a.UserID
                    //                 ).map(a =>
                    //                   organizationPackage.Users.map(u => ({
                    //                     userId: u.UserID,
                    //                     name: u.FullName,
                    //                     email: u.Email
                    //                   })).find(u => u.userId == a.UserID)
                    //                 )
                    //             };
                    //           });
                    //       })
                    //       .filter(t => !!t.length)
                    //       .flat(),
                    //   developerGroups:
                    //     organizationPackage.Package.DeveloperGroupsPackages?.map(
                    //       g => {
                    //         return g.DeveloperGroup.DeveloperSections.filter(
                    //           s =>
                    //             getOrgDevSectionByDevSection(
                    //               organizationPackage,
                    //               s.DeveloperSectionID
                    //             ).Quantity > 0
                    //         ).map(s => {
                    //           return {
                    //             title: s.DisplayOnApplication
                    //               ? s.DisplayTitle
                    //               : s.AdminTitle,
                    //             audience: s.AudienceType.Description,
                    //             dates: makeDeveloperSectionDates(
                    //               s.Days,
                    //               s.DeveloperSectionDates
                    //             ),
                    //             assignments: getOrgDevSectionByDevSection(
                    //               organizationPackage,
                    //               s.DeveloperSectionID
                    //             )
                    //               .OrganizationPackageDeveloperGroupSectionAssignments?.filter(
                    //                 a => !!a.Active && !!a.UserID
                    //               )
                    //               .map(a =>
                    //                 organizationPackage.Users.map(u => ({
                    //                   userId: u.UserID,
                    //                   name: u.FullName,
                    //                   email: u.Email
                    //                 })).find(u => u.userId == a.UserID)
                    //               )
                    //           };
                    //         });
                    //       }
                    //     ).flat(),
                    //   institutes:
                    //     organizationPackage.OrganizationPackageTickets.filter(
                    //       t =>
                    //         t.ActualAmount > 0 && t.ApplicationBasedEventTickets
                    //     )
                    //       .map(orgTicket => {
                    //         return orgTicket.PackageTicket.Ticket.EventTickets.filter(
                    //           t => t.Event.ApplicationFlag
                    //         ).map(t => {
                    //           return {
                    //             title: t.Event.Name,
                    //             numberOfTickets:
                    //               orgTicket.OverrideAmount || orgTicket.Amount,
                    //             dates: `${formatDateWithoutTimezone(
                    //               new Date(t.Event.StartDate),
                    //               true
                    //             )} - ${formatDateWithoutTimezone(
                    //               new Date(t.Event.EndDate),
                    //               true
                    //             )}`,
                    //             assignments:
                    //               orgTicket.OrganizationPackageTicketAssignments.filter(
                    //                 a => !a.TempID && !!a.UserID
                    //               ).map(a =>
                    //                 organizationPackage.Users.map(u => ({
                    //                   dates: `${formatDateWithoutTimezone(
                    //                     new Date(t.Event.StartDate),
                    //                     true
                    //                   )} - ${formatDateWithoutTimezone(
                    //                     new Date(t.Event.EndDate),
                    //                     true
                    //                   )}`,
                    //                   userId: u.UserID,
                    //                   name: u.FullName,
                    //                   email: u.Email
                    //                 })).find(u => u.userId == a.UserID)
                    //               )
                    //           };
                    //         });
                    //       })
                    //       .flat()
                    // };

                    const summary = {
                      data: {}
                    };

                    try {
                      setDownloadSummaryLoading(true);
                      const req = await fetch(
                        `${process.env.GATSBY_REPORT_GEN_URL}/ticket-assignment-summary`,
                        {
                          method: 'POST',
                          body: JSON.stringify(summary),
                          headers: { 'Content-Type': 'application/json' }
                        }
                      );

                      const buffer = await req.arrayBuffer();
                      var blob = new Blob([buffer], {
                        type: 'application/pdf'
                      });

                      setDownloadSummaryLoading(false);
                      saveAs(blob, 'ticket-assignment-summary.pdf');
                    } catch (err) {
                      console.log(err);
                      setDownloadSummaryLoading(false);
                      toast.error('Something went wrong');
                    }
                  }}
                /> */}
              </div>
              <Button
                text="Save"
                isLoading={loadingSave}
                disabled={
                  getMissallocationsAmount(
                    packageRequest as PackageRequest,
                    data,
                    assignedTickets
                  ) < 0 || sectionMaxParticipantsExceeded(data, assignedTickets)
                }
                onClick={() => {
                  const sectionWithMoreThan10 = assignedTickets.conferenceDays.some(
                    cd => cd.assignedUsers.length > 10
                  );

                  if (sectionWithMoreThan10) {
                    toast.error(
                      'Some sections have more than 10 tickets allocated. Please correct before saving.'
                    );
                    return;
                  }

                  submitSave({
                    segment: selectedTab,
                    data:
                      selectedTab === TABS.ALLOCATE_TICKETS
                        ? assignedTickets.conferenceDays
                        : selectedTab === TABS.ASSIGN_STAFF
                        ? {
                            groups: assignedTickets.groups.map(g => ({
                              ...g,
                              assignedUsers: g.assignedUsers.filter(Boolean)
                            })),
                            events: assignedTickets.events.map(e => ({
                              ...e,
                              assignedUsers: e.assignedUsers.filter(Boolean)
                            }))
                          }
                        : selectedTab === TABS.ASSIGN_APPLICANTS
                        ? assignedTickets.institutes
                        : null
                  });
                }}
              />
            </div>
          </Card>
        </>
      )}
    </>
  );
};

export default AssignTickets;
