import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import classnames from 'classnames';
import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import Select from 'react-select';
import { PackageContext } from '../../context/PackageContext';
import Card from '../common/Card';
import LoadingDialog from '../common/LoadingDialog';
import SEO from '../Seo';
import {
  calculatePdSessionCostRange,
  fillDaySelection,
  formatAsCurrency
} from './../../util';
import { getPackageRequestById, getPackageRequestTotals } from './api';
import PackageCostTotals, { PackageRequestTotals } from './PackageCostTotals';
import PackageSaveControls from './PackageSaveControls';
import SelectionInstructions from './SelectionInstructions';
import {
  Package,
  PackageRequest,
  PackageRequestSchoolBasedPDSession,
  PackageSchoolBasedPDSession
} from './NewApiTypes.generated';
import pluralize from 'pluralize';
import Label from '../common/Label';
import { ManageServicesEnum, getManageServicesNextStep } from './Packages';
interface PackageProductPanelProps {
  title: string;
  body: string;
  open: boolean;
  complete: boolean;
  costOptions: { value: string; label: string }[];
  costRange: [number, number];
  onCostOptionSelect: any;
  onCostClear: any;
  existingPdSessionSelection?: Partial<PackageRequestSchoolBasedPDSession>;
  travelCost: number;
  isSubmitted?: boolean;
}

export const getPDSessionTotalByGrouping = (packageRequest: PackageRequest) => {
  if (!packageRequest) return [];

  const groupings = packageRequest.package.pdSessionGroupings
    .map(g => ({
      groupingId: g?.id,
      minAmount: g?.minDays || 0,
      pdSessionIds: g?.pdSessions.map(s => s?.id),
      title: g?.title,
      minAmountLabel: `${g?.minDays || 0} ${pluralize(
        'Days',
        g?.minDays || 0
      )}`,
      amount: 0
    }))
    .map(g => {
      let amount = 0;
      packageRequest.requestedPDSessions.map(s => {
        amount += g.pdSessionIds?.includes(s?.packageSchoolBasedPDSession.id)
          ? (s?.overrideDate ? s.overrideAmount : s?.requestedDays) || 0
          : 0;
      });

      return {
        ...g,
        amount
      };
    });

  return groupings;
};

const PackageProductPanel = (props: PackageProductPanelProps) => {
  const buttonClasses = classnames({
    'flex justify-between w-full px-4 py-2 font-medium text-left text-blue-100 bg-primary rounded-t-lg hover:bg-primary-dark focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75 sans-serif': true,
    'rounded-b-lg': !props.open
  });
  const chevronClasses = classnames({
    'w-5 h-5 text-blue-100': true,
    'transform rotate-180': props.open
  });
  const isOverriden = !!props.existingPdSessionSelection?.overrideDate;
  const isCreatedByAdmin =
    props.existingPdSessionSelection &&
    !props.existingPdSessionSelection.createdByInitiator;

  const selectedRequestedDaysMatch = props.existingPdSessionSelection
    ? props.costOptions.find(
        option =>
          String(option.value) ===
          String(props.existingPdSessionSelection?.requestedDays)
      )
    : null;

  const overridenRequestedDaysMatch = props.existingPdSessionSelection
    ? props.costOptions.find(
        option =>
          String(option.value) ===
          String(props.existingPdSessionSelection?.overrideAmount)
      )
    : null;

  const daysMatch = isOverriden
    ? overridenRequestedDaysMatch
    : selectedRequestedDaysMatch;

  return (
    <>
      <Disclosure.Button className={buttonClasses}>
        <span className="sans-serif text-lg">
          <FontAwesomeIcon
            icon={
              !!daysMatch && daysMatch.label !== '0 Days'
                ? faCheckCircle
                : faCircle
            }
            className="text-blue-100 mr-2"
          />
          {props.title}
        </span>
        <ChevronUpIcon className={chevronClasses} />
      </Disclosure.Button>
      <Disclosure.Panel className="p-6 text-gray-700 sans-serif border-gray-300 border-b border-l border-r rounded-b-lg flex justify-between">
        <div
          className="sans-serif text-lg"
          dangerouslySetInnerHTML={{ __html: props.body }}
        ></div>
        {isOverriden && (
          <div className="ml-auto mr-1 text-yellow-600">{`Requested Days were overriden by admin, orriginal request - ${selectedRequestedDaysMatch?.label}`}</div>
        )}
        {isCreatedByAdmin && (
          <div className="ml-auto mr-1 text-yellow-600">{`Created by admin`}</div>
        )}
        <div className="ml-4 flex flex-col">
          <div className="w-36 self-end">
            <>
              {props.isSubmitted ? (
                <span>{daysMatch?.label || '0 Days'}</span>
              ) : (
                <>
                  <Select
                    options={props.costOptions}
                    onChange={props.onCostOptionSelect}
                    value={daysMatch || null}
                    isDisabled={isOverriden || isCreatedByAdmin}
                  />
                  {!!daysMatch && !(isOverriden || isCreatedByAdmin) && (
                    <button
                      type="button"
                      className="mt-1 ml-1 flex items-center gap-2"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        props.onCostClear();
                      }}
                    >
                      <span className="text-red-400"> Clear</span>
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="text-red-400"
                      />
                    </button>
                  )}
                </>
              )}
            </>
          </div>
          <div className="mt-4 text-right">
            <p className="sans-serif font-bold">
              <span className="mr-1 sans-serif text-gray-700 font-normal">
                Staff Developer
              </span>{' '}
              <span className="sans-serif">
                {daysMatch && daysMatch.label === '0 Days' ? (
                  <span className="sans-serif">{formatAsCurrency(0)}</span>
                ) : props.costRange[0] === props.costRange[1] ? (
                  <span className="sans-serif">
                    {formatAsCurrency(props.costRange[0])}
                  </span>
                ) : (
                  <span className="sans-serif">
                    {formatAsCurrency(props.costRange[0])} -{' '}
                    {formatAsCurrency(props.costRange[1])}
                  </span>
                )}
              </span>
            </p>
          </div>
          {props.travelCost && props.existingPdSessionSelection ? (
            <div className="mt-1 text-right">
              <p className="sans-serif font-bold">
                <span className="mr-1 sans-serif text-gray-700 font-normal">
                  Travel Cost
                </span>{' '}
                <span className="sans-serif">
                  {formatAsCurrency(
                    (props.travelCost * Number(daysMatch?.value)) / 100
                  )}
                </span>
              </p>
            </div>
          ) : null}
        </div>
      </Disclosure.Panel>
    </>
  );
};

const getTravelCost = (packageInfo: Package) => {
  if (packageInfo.travelCost === 'ACTUAL_COST') {
    return packageInfo.actualCostAmount || 0;
  } else if (packageInfo.travelCost === 'FIXED_COST') {
    return packageInfo.fixedCostAmount || 0;
  } else {
    return 0;
  }
};

interface StaffOptionsProps {
  path: string;
  packageRequestId: string;
}
const StaffOptions = (props: StaffOptionsProps) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [pdSessionSelection, setPdSessionSelection] = useState<
    Partial<PackageRequestSchoolBasedPDSession>[]
  >([]);

  const [
    pdSessionSelectionForDelete,
    setPdSessionSelectionForDelete
  ] = useState<Partial<PackageRequestSchoolBasedPDSession>[]>([]);

  const packageContext = useContext(PackageContext);

  const { data, isLoading } = useQuery<{
    packageRequest: PackageRequest;
  }>(
    'packageRequestQuery',
    () => getPackageRequestById(props.packageRequestId),
    {
      onSuccess: data => {
        const preparedPdSessions =
          data.packageRequest?.requestedPDSessions || [];
        setPdSessionSelection(
          preparedPdSessions as PackageRequestSchoolBasedPDSession[]
        );
        setIsSubmitted(data.packageRequest.status === 'SUBMITTED');
      }
    }
  );

  const { data: totalsData, isLoading: isTotalsLoading } = useQuery<{
    pdfData: any;
    totals: PackageRequestTotals;
  }>(['packageRequestTotalsQuery', pdSessionSelection], () =>
    getPackageRequestTotals(props.packageRequestId, {
      requestedPDSessions: pdSessionSelection
    })
  );

  return (
    <>
      <SEO title="Staff Options" />
      <>
        {isLoading ? <LoadingDialog /> : null}
        {data && data.packageRequest.package && (
          <>
            <Card>
              <h2 className="sans-serif text-primary font-bold text-2xl border-b border-gray-300">
                {data.packageRequest.organization.name} -{' '}
                {data.packageRequest.package.name}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    data.packageRequest.package
                      .schoolBasedPDSessionsDescription || ''
                }}
              />
            </Card>

            {data.packageRequest.package.pdSessionGroupings.map(g => (
              <Card className="mt-4" key={g?.id}>
                <p className="sans-serif text-primary font-bold text-2xl border-b border-gray-300">
                  {g?.title}
                </p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: g?.description || ''
                  }}
                  className="mt-0.5"
                />
                <div className="w-1/2 m-auto my-6">
                  <SelectionInstructions isSubmitted={isSubmitted} />
                </div>
                <div className="mb-6">
                  <div className="mr-10">
                    <Label text="Min Days" />
                    <p className="sans-serif text-lg">{g?.minDays || 0}</p>
                  </div>
                </div>
                {(g?.pdSessions || []).map(
                  // @ts-ignore
                  (s: PackageSchoolBasedPDSession) => (
                    <div className="mb-2" key={s.id}>
                      <Disclosure>
                        {({ open }) => {
                          const isRemote = s.pdSession.setting === 'REMOTE';
                          return (
                            <PackageProductPanel
                              title={s.pdSession.name}
                              isSubmitted={isSubmitted}
                              body={s.pdSession.description}
                              costOptions={
                                s.useCustomSelection
                                  ? (s.customSelections || []).map(v => ({
                                      value: v.toString(),
                                      label: `${v} ${pluralize('Days', v)}`
                                    }))
                                  : fillDaySelection(
                                      s.minDays || 0,
                                      s.maxDays || 0,
                                      s.selectionInterval || 0,
                                      !!s.allowZeroSelection
                                    )
                              }
                              costRange={calculatePdSessionCostRange(
                                data.packageRequest,
                                pdSessionSelection,
                                s
                              )}
                              travelCost={
                                isRemote
                                  ? 0
                                  : getTravelCost(data.packageRequest.package)
                              }
                              existingPdSessionSelection={pdSessionSelection.find(
                                selection =>
                                  selection.packageSchoolBasedPDSessionId ===
                                  s.id
                              )}
                              onCostClear={() => {
                                const pdSession:
                                  | Partial<PackageRequestSchoolBasedPDSession>
                                  | undefined = pdSessionSelection.find(
                                  session =>
                                    session.packageSchoolBasedPDSessionId ===
                                    s.id
                                );

                                if (pdSession) {
                                  packageContext.setHasUnsavedChanges(true);

                                  const existingSelections = pdSessionSelection.filter(
                                    session =>
                                      session.packageSchoolBasedPDSessionId !==
                                      s.id
                                  );

                                  const existingSelectionsForDelete = pdSessionSelectionForDelete.filter(
                                    session =>
                                      session.packageSchoolBasedPDSessionId !==
                                      s.id
                                  );

                                  setPdSessionSelectionForDelete([
                                    ...existingSelectionsForDelete,
                                    pdSession
                                  ]);
                                  setPdSessionSelection([
                                    ...existingSelections
                                  ]);
                                }
                              }}
                              onCostOptionSelect={({ value }: any) => {
                                packageContext.setHasUnsavedChanges(true);

                                const pdSession: Partial<PackageRequestSchoolBasedPDSession> = pdSessionSelection.find(
                                  session =>
                                    session.packageSchoolBasedPDSessionId ===
                                    s.id
                                ) || {
                                  createdByInitiator: true,
                                  packageSchoolBasedPDSession: s,
                                  packageSchoolBasedPDSessionId: s.id
                                };

                                if (pdSession.overrideDate) {
                                  pdSession.overrideAmount = parseFloat(value);
                                } else {
                                  pdSession.requestedDays = parseFloat(value);
                                }

                                const existingSelections = pdSessionSelection.filter(
                                  session =>
                                    session.packageSchoolBasedPDSessionId !==
                                    s.id
                                );

                                const existingSelectionsForDelete = pdSessionSelectionForDelete.filter(
                                  session =>
                                    session.packageSchoolBasedPDSessionId !==
                                    s.id
                                );

                                setPdSessionSelection([
                                  ...existingSelections,
                                  pdSession
                                ]);

                                setPdSessionSelectionForDelete([
                                  ...existingSelectionsForDelete
                                ]);
                              }}
                              open={open}
                              complete={false}
                            />
                          );
                        }}
                      </Disclosure>
                    </div>
                  )
                )}
              </Card>
            ))}

            <Card className="mt-4">
              <div className="flex justify-between">
                <PackageCostTotals
                  isLoading={isTotalsLoading}
                  package={data.packageRequest.package}
                  totals={totalsData?.totals}
                />
                <div>
                  {getPDSessionTotalByGrouping({
                    ...data.packageRequest,
                    requestedPDSessions: pdSessionSelection as PackageRequestSchoolBasedPDSession[]
                  }).some(g => g.amount < g.minAmount) && (
                    <div className="text-yellow-600 mx-4 mb-4">
                      The minimum amount of days for{' '}
                      {getPDSessionTotalByGrouping({
                        ...data.packageRequest,
                        requestedPDSessions: pdSessionSelection as PackageRequestSchoolBasedPDSession[]
                      })
                        .filter(g => g.amount < g.minAmount)
                        .map(g => g.title)
                        .join(', ')}{' '}
                      has not been reached. Please adjust your selections to
                      include the minimum required days
                    </div>
                  )}
                  <PackageSaveControls
                    packageRequest={data.packageRequest}
                    segment="PD_SESSIONS"
                    segmentData={pdSessionSelection}
                    deleteData={pdSessionSelectionForDelete}
                    nextRoute={getManageServicesNextStep(
                      ManageServicesEnum.STAFF_OPTIONS,
                      data.packageRequest
                    )}
                    isDisabled={getPDSessionTotalByGrouping({
                      ...data.packageRequest,
                      requestedPDSessions: pdSessionSelection as PackageRequestSchoolBasedPDSession[]
                    }).some(g => g.amount < g.minAmount)}
                  />
                </div>
              </div>
            </Card>
          </>
        )}
      </>
    </>
  );
};

export default StaffOptions;
