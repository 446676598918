import React, { useContext, useEffect, useState } from 'react';
import Card from '../common/Card';
import SEO from '../Seo';
import { useQuery } from 'react-query';
import {
  checkApplicantForPuchaseOrder,
  getAffiliationsForUser,
  getApplicantsForPuchaseOrder,
  getCurrentYearInstitutes,
  getEventApplications,
  submitPurchaseOrderForInstitute
} from './api';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { navigate } from 'gatsby';
import * as Yup from 'yup';
import { FETCH_STATES, useFetch } from '../../hooks/useFetch';
import { FormField, FormWrapper } from '../common/Form';
import { Event, Organization, User } from './NewApiTypes.generated';
import { AuthContext } from '../../context/AuthContext';
import Button from '../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';

const InstitutePurchaseOrderSchema = Yup.object().shape({
  institute: Yup.string().required('Institute required'),
  file: Yup.string().required('File required'),
  filename: Yup.string().required('Filename required'),
  contactEmail: Yup.string()
    .email('Invalid email')
    .required('Email required'),
  contactName: Yup.string().required('Name required'),
  contactPhone: Yup.string()
    .test('validate-phone', 'Phone is invalid', (value: any) => {
      if (!value) return false;
      return value.indexOf('_') === -1;
    })
    .required('Phone is required'),
  number: Yup.string().required('Number required'),
  total: Yup.number().required('Purchase order total is required'),
  organization: Yup.string().required('Organization required'),
  applicants: Yup.array()
    .min(1, 'At least 1 applicant required')
    .required('Applicants required')
});

interface PurchaseOrdersInstituteProps {
  path: string;
}

type PurchaseOrdersInstituteForm = {
  institute: string;
  file: string;
  filename: string;
  contactEmail: string;
  contactName: string;
  contactPhone: string;
  number: string;
  total: number;
  organization: string;
  applicants: string[];
};

type IForm = PurchaseOrdersInstituteForm;

const PurchaseOrdersInstitute = (props: PurchaseOrdersInstituteProps) => {
  const auth = useContext(AuthContext);

  const [organizationOptions, setOrganizationOptions] = useState<any[]>([]);
  const [applicantEmail, setAppicantEmail] = useState<string>('');
  const [isLoadingApplicantsCheck, setIsLoadingApplicantsCheck] = useState<
    boolean
  >(false);
  const [applicantsCounts, setApplicantsCounts] = useState<number>(0);

  const { isFetching, error, data } = useQuery<{
    state: 'SUCCESS' | 'ERROR';
    institutes?: Event[];
  }>('instituteListForPO', () => getCurrentYearInstitutes());

  const {
    data: organizationsData,
    isLoading: isLoadingOrganization
  } = useQuery<{
    organizations?: Organization[];
  }>('affiliations', () => getAffiliationsForUser());

  useEffect(() => {
    if (organizationsData?.organizations) {
      setOrganizationOptions(
        organizationsData.organizations.map(o => ({
          label: o.name,
          value: o.id
        }))
      );
    }
  }, [organizationsData]);

  const { apply, error: submitError, state } = useFetch(
    submitPurchaseOrderForInstitute
  );

  const options =
    data?.institutes?.map(i => ({
      label: i.name,
      value: i.id
    })) || [];

  const handleSubmit = async (values: any) => {
    const res = await apply({
      ...values,
      total: (values?.total || 0) * 100,
      type: 'EVENT'
    });
    if (res.state === 'SUCCESS') {
      toast.success('Purchase order submitted successfully');
      navigate('/members/purchase-orders');
    } else {
      toast.error(submitError || res.data.message);
    }
  };

  const formik = useFormik<IForm>({
    validationSchema: InstitutePurchaseOrderSchema,
    initialValues: {
      institute: '',
      file: '',
      filename: '',
      contactEmail: auth.authState?.email || '',
      total: 0,
      contactName:
        auth.authState?.firstName && auth.authState?.lastName
          ? `${auth.authState?.firstName} ${auth.authState?.lastName}`
          : '',
      contactPhone: auth.authState?.phone || '',
      number: '',
      organization: '',
      applicants: []
    },
    onSubmit: handleSubmit
  });

  useEffect(() => {
    formik.setFieldValue('applicants', []);
  }, [formik.values.institute]);

  const { isLoading: isLoadingApplicants } = useQuery<{
    usersCount?: number;
    state?: 'ERROR' | 'SUCCESS';
  }>(
    ['applicantsForPO', formik.values.institute],
    () =>
      getApplicantsForPuchaseOrder({
        instituteId: formik.values.institute
      }),
    {
      enabled: !!formik.values.institute,
      onSuccess: data => {
        if (data.state === 'ERROR') {
          setApplicantsCounts(0);
        } else {
          setApplicantsCounts(data.usersCount || 0);
        }
      }
    }
  );

  const handleAddApplicantClick = async () => {
    try {
      setIsLoadingApplicantsCheck(true);
      if (applicantEmail) {
        if (
          formik.values.applicants.some(
            e => e.toLowerCase() === applicantEmail.toLowerCase()
          )
        ) {
          toast.error('Applicant already added');
          return;
        }

        const applicantCheckResult = await checkApplicantForPuchaseOrder({
          instituteId: formik.values.institute,
          email: applicantEmail
        });

        if (applicantCheckResult?.userAvailable) {
          formik.setFieldValue('applicants', [
            ...formik.values.applicants,
            applicantEmail.toLowerCase()
          ]);
          setAppicantEmail('');
        } else {
          toast.error('Applicant not found');
        }
      }
    } catch (e) {
      toast.error('Error adding applicant');
    } finally {
      setIsLoadingApplicantsCheck(false);
    }
  };

  const handleRemoveApplicant = (_email: string, idx: number) => {
    const applicants = [...formik.values.applicants];
    applicants.splice(idx, 1);
    formik.setFieldValue('applicants', applicants);
  };

  return (
    <>
      <SEO title="Purchase Orders Submit" />
      <Card className="mt-4">
        <FormWrapper
          formik={formik}
          isLoading={state === FETCH_STATES.PROCESSING}
          onCancel={() => navigate('/members/purchase-orders')}
        >
          <FormField<IForm>
            name="institute"
            type="select"
            options={options}
            isLoading={isFetching}
          />

          {formik.values.institute && (
            <>
              <hr className="my-3" />
              <div>
                Please note that{' '}
                <span className="font-bold">
                  all purchases are non-refundable and non-transferable to other
                  seminars.
                </span>{' '}
                Please expect a confirmation email after payment has been
                processed.{' '}
                <span className="font-bold">Another separate email </span>
                will be sent in the following weeks with your ticket stub.
              </div>
              <div className="flex">
                <div className="w-1/2 mr-8">
                  <div className="flex mb-2">
                    <FormField<IForm>
                      name="contactName"
                      type="text"
                      className="mr-2 w-1/2"
                    />
                    <FormField<IForm>
                      name="contactEmail"
                      type="text"
                      className="w-1/2"
                    />
                  </div>
                  <div className="flex  mb-2">
                    <FormField<IForm>
                      name="contactPhone"
                      type="mask"
                      mask="phoneNumber"
                      className="w-1/2 mr-2"
                    />
                    <FormField<IForm>
                      name="number"
                      label="Purchase order number"
                      placeholder="Purchase order number"
                      type="text"
                      className="w-1/2"
                    />
                  </div>
                  <div className="flex  mb-2">
                    <FormField<IForm>
                      name="organization"
                      type="select"
                      className="w-1/2 mr-2"
                      options={organizationOptions}
                      isLoading={isLoadingOrganization}
                    />
                    <FormField<IForm>
                      name="total"
                      label="Purchase order total"
                      placeholder="Purchase order total"
                      type="number"
                      className="w-1/2"
                    />
                  </div>
                  <div className="mt-1 sans-serif">
                    <span
                      onClick={() => navigate('/members/profile')}
                      className="text-primary cursor-pointer sans-serif"
                    >
                      Click here{' '}
                    </span>{' '}
                    to add an organization affiliation
                  </div>
                </div>
              </div>
              <div>
                <div className="flex items-end gap-x-2">
                  <FormField<any>
                    type="email"
                    name="applicants-email"
                    label="Applicants"
                    placeholder="Email"
                    value={applicantEmail}
                    onChange={e => setAppicantEmail(e.target.value)}
                    disabled={isLoadingApplicants || !applicantsCounts}
                  />
                  <Button
                    type="button"
                    text="Add"
                    size="sm"
                    disabled={
                      isLoadingApplicants ||
                      !applicantsCounts ||
                      isLoadingApplicantsCheck
                    }
                    isLoading={isLoadingApplicants || isLoadingApplicantsCheck}
                    icon={faPlus}
                    onClick={handleAddApplicantClick}
                  />
                </div>
                {!isLoadingApplicants && !applicantsCounts && (
                  <div className="text-sm text-gray-500 mt-1">
                    No available applicants
                  </div>
                )}
                {formik.errors.applicants && (
                  <div className="text-sm text-red-500">
                    {formik.errors.applicants}
                  </div>
                )}
                {formik.values.applicants.length > 0 && (
                  <div className="mt-2">
                    <div className="text-sm text-gray-500 mb-1">
                      Selected applicants
                    </div>
                    <div className="flex flex-wrap gap-x-2">
                      {formik.values.applicants.map((a: any, idx) => (
                        <div
                          key={a}
                          className="bg-gray-200 px-3 py-1 rounded-full text-sm relative"
                        >
                          {a}
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="ml-2 cursor-pointer text-gray-600 hover:text-gray-500"
                            onClick={() => handleRemoveApplicant(a, idx)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <FormField<IForm>
                name="file"
                type="file"
                subheading="Add a .pdf file"
                acceptedFileTypes={{
                  'application/*': ['.pdf']
                }}
                filenamePath="filename"
              />
            </>
          )}
        </FormWrapper>
      </Card>
    </>
  );
};

export default PurchaseOrdersInstitute;
