import React, { useContext, useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import { useQuery } from 'react-query';
import { AuthContext } from '../../context/AuthContext';
import { UserInfo } from '../../context/User';
import Card from '../common/Card';
import LoadingDialog from '../common/LoadingDialog';
import Selection from '../common/Selection';
import SearchInput from '../SearchInput';
import SEO from '../Seo';
import MembersPageTitle from './MembersPageTitle';
import type { Resource } from './MySavedResources';
import { getToolkitResources } from './Queries';
import ResourceItem from './ResourceItem';
import { useDebouncedCallback } from 'use-debounce';
import { User } from './NewApiTypes.generated';

type ContentSidebarProps = {
  categories: string[];
  selectedCategories: string[];
  onSetSelectedCategory: any;
};
const ContentSidebar = (props: ContentSidebarProps) => {
  return (
    <div className="p-4 shadow-lg rounded-lg bg-white">
      <div className="border-b border-gray-200 pb-4">
        <h2 className="sans-serif text-primary text-xl font-semibold">
          Categories
        </h2>
      </div>
      <div className="mt-4">
        {props.categories.map((category, i: number) => (
          <Selection
            item={{
              label: category,
              selected: props.selectedCategories.includes(category)
            }}
            setSelected={() => props.onSetSelectedCategory(category)}
            key={i}
          />
        ))}
      </div>
    </div>
  );
};

interface CollectionCountProps {
  count: number;
}

const CollectionCount = (props: CollectionCountProps) => {
  return (
    <span className="px-3 py-1 sans-serif bg-primary rounded-full text-blue-100 text-sm">
      {props.count} Items
    </span>
  );
};

type ICollection = {
  id: string;
  name: string;
  items: Resource[];
  slug: string;
  description: string;
  title: string;
  type: string;
  downloadLink: string;
  category: string;
};

interface CollectionProps {
  title: string;
  description: string;
  slug: string;
  items: Resource[];
  onFavorite?: any;
  user: UserInfo | User;
}

const Collection = (props: CollectionProps) => {
  return (
    <Card>
      <SEO title="My Toolkit" />
      <div className="flex justify-between">
        <div className="">
          <h2 className="sans-serif text-primary text-2xl font-semibold">
            {props.title}
          </h2>
        </div>
        <div className="">
          <div className="ml-8 my-auto flex flex-row text-center">
            <CollectionCount count={props.items.length} />
          </div>
        </div>
      </div>
      <div>
        <p className="sans-serif text-gray-500 leading-snug mt-4">
          {props.description}
        </p>
      </div>
      <div className="mt-4">
        <ul>
          {props.items.map((item, i) => {
            return <ResourceItem item={item} collectionSlug={props.slug} key={i} onFavorite={props.onFavorite} user={props.user}/>;
          })}
        </ul>
      </div>
    </Card>
  );
};

interface MyToolkitProps {
  path: string;
}
const MyToolkit = (props: MyToolkitProps) => {
  const auth = useContext(AuthContext);
  const { id: _userId, portalMetadata } = auth.authState as User & {
    portalMetadata: any;
  };

  const userId = portalMetadata?.userId || _userId;

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (!auth.canAccessToolkit(auth.authState as User)) {
      navigate('/members/profile');
    }
  }, [])

  const { isFetching, error, data } = useQuery('toolkitContent', () =>
    getToolkitResources(userId, auth.isSchoolLeader(auth.authState as User))
  );

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const debounced = useDebouncedCallback(
    searchTerm => setSearchTerm(searchTerm),
    300
  );

  useEffect(() => {
    if (data) {
      const categories = [
        ...new Set(data.map((collection: ICollection) => collection.category))
      ];
      setSelectedCategories(categories as string[]);
    }
  }, [data]);

  return (
    <div>
      <div className="mb-4">
        <MembersPageTitle title="My Toolkit" />
      </div>
      {isFetching && <LoadingDialog />}
      {!isFetching && data && (
        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-1/4">
            <div className="sticky top-4 mb-4">
              <ContentSidebar
                selectedCategories={selectedCategories}
                onSetSelectedCategory={(category: string) => {
                  if (selectedCategories.includes(category)) {
                    setSelectedCategories(
                      selectedCategories.filter((c: string) => c !== category)
                    );
                  } else {
                    setSelectedCategories([...selectedCategories, category]);
                  }
                }}
                categories={
                  data
                    ? [...new Set(data.map((collection: ICollection) => collection.category))] as string[]
                    : []
                }
              />
            </div>
          </div>
          <div className="lg:ml-8 w-full lg:w-3/4">
            <div className="mb-4 w-1/3">
              <SearchInput placeholder="Search Collections" onChange={(e:any) => debounced.callback(e.target.value.toLowerCase())} />
            </div>
            <div className="my-4">
              {searchTerm && (
                <p className="italic text-gray-500">
                  Showing results for filter "{searchTerm}"
                </p>
              )}
            </div>
            <div className="mb-8">
              {data &&
                data
                  .filter((collection: ICollection) =>
                    selectedCategories.includes(collection.category)
                  )
                  .filter((collection:ICollection) => {
                    return collection.name.toLowerCase().includes(searchTerm) || collection.items.some(item => item.title.toLowerCase().includes(searchTerm))
                  })
                  .map((collection: ICollection, i: number) => {
                    return (
                      <div className="mb-4" key={i}>
                        <Collection
                          title={collection.name}
                          slug={collection.slug}
                          description={collection.description || ''}
                          items={collection.items}
                          user={auth.authState as User}
                        />
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyToolkit;
