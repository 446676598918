import React, { ReactNode, useEffect } from 'react';
import { formatAsCurrency } from '../../util';
import { useLocalStorage } from 'usehooks-ts';
import { EventSectionsCartModel } from '../event-sections/EventSectionsForm';
import { EVENT_SECTION_CART_DEFAULT } from '../event-sections/EventSectionsCart';

const ConferenceDaysSummary = () => {
  const [cart] = useLocalStorage<EventSectionsCartModel>(
    'eventSectionsCart',
    EVENT_SECTION_CART_DEFAULT
  );
  if (cart.items?.length === 0) return <></>;
  return (
    <>
      <h3 className="text-lg mb-2">Event Cart</h3>
      {cart.items.map(item => (
        <div key={item.id} className="mb-2 pb-2 border-b">
          <div className="text-primary mb-2">{item.title}</div>
          <div className="my-1 text-xs italic text-gray-500">
            Number of Tickets: {item.numberOfTickets}
          </div>
          <div className="my-1 text-xs italic text-gray-500">
            Subtotal:{' '}
            {formatAsCurrency(
              (item.ticketType.price * item.numberOfTickets) / 100
            )}
          </div>
        </div>
      ))}
      <div className="mb-6">
        <div className="mb-1 italic text-gray-500">
          Total:{' '}
          {formatAsCurrency(
            cart.items.reduce(
              (accum, item) =>
                (accum += item.ticketType.price * item.numberOfTickets),
              0
            ) / 100
          )}
        </div>
      </div>
    </>
  );
};

export default ConferenceDaysSummary;
