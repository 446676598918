import { calculateOrganizationPackageTotals } from './CostUtil';
import { OrganizationPackage } from './OrganizationPackageTypes';
import { EVENT_TYPE } from './Enum';
import moment from 'moment';
import { request, gql } from 'graphql-request';

async function makeApiRequest(
  route: string,
  method?: string,
  data?: any,
  contentType?: string
) {
  try {
    let baseOptions = {
      method: method ?? 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': contentType ?? 'text/json; charset=UTF-8',
        'request-source': 'pubweb'
      }
    };

    let options = {};

    if (data) {
      options = {
        ...baseOptions,
        body: JSON.stringify(data)
      };
    } else {
      options = baseOptions;
    }

    const req = await fetch(`${process.env.GATSBY_API_URL}/${route}`, options);

    if (!req.ok) {
      const err = req.statusText;

      throw new Error(err);
    }

    if (req.statusText !== 'No Content') {
      return await req.json();
    }
  } catch (err) {
    console.log(err);
  }
}

export async function getToolkitResources(
  userId: string,
  isSchoolLeader: boolean
) {
  const req = await fetch(
    `${process.env.GATSBY_FUNCTIONS_PATH}/toolkit?userId=${userId}&isSchoolLeader=${isSchoolLeader}`
  );
  return await req.json();
}

export async function getFavoritedDocs(userId: string) {
  const req = await fetch(
    `${process.env.GATSBY_FUNCTIONS_PATH}/favorites?userId=${userId}`
  );

  return await req.json();
}

export async function getVideoCollectionContent(collectionSlug: string) {
  const req = await fetch(
    `${process.env.GATSBY_FUNCTIONS_PATH}/videoCollection?slug=${collectionSlug}`
  );
  return await req.json();
}

export async function getCurrentUser() {
  return await makeApiRequest('User/GetCurrentUser');
}

export async function logoutUser() {
  return await makeApiRequest('UserAuthentication/Logout', 'POST');
}

export async function getOrganizationUsers(userId: string) {
  return await makeApiRequest(
    `OrganizationUser/Get?ActiveOnly=true&IncludeGrades=true&IncludeOrganization=true&IncludePosition=true&IncludeUser=false&UserID=${userId}`
  );
}

export async function getGrades() {
  return await makeApiRequest(`Lookup/GetGrades`);
}

export async function getPositions() {
  return await makeApiRequest(`Lookup/GetPositions?visible=true`);
}

export async function getCurrentOrganizationUsers() {
  return await makeApiRequest('OrganizationUser/GetCurrentOrganizationUsers');
}

export async function getApplicantList(userId: string) {
  return await makeApiRequest(
    `EventApplication/GetApplicantList?userId=${userId}`
  );
}

export async function sendCtleNotifications(data: any) {
  return await makeApiRequest(`User/SendCtleNotifications`, 'POST', data);
}

export async function addStaffMember(data: any) {
  return await new Promise(res => setTimeout(res, 1000));
  // return await makeApiRequest(`User/SendCtleNotifications`, 'POST', data);
}

export async function getOrganizationList(searchTerm: string) {
  return await makeApiRequest(
    `Organization/GetList?Name=${searchTerm}&SortField=Name&SortDescending=false&ItemsPerPage=100&CurrentPage=0`
  );
}

export async function saveAffiliation(data: any) {
  return await makeApiRequest(`OrganizationUser/Save`, 'POST', data);
}

export async function deleteAffiliation(organizationUserId: string) {
  return await makeApiRequest(
    `OrganizationUser/Delete?id=${organizationUserId}`,
    'POST'
  );
}

export async function createNewOrganization(data: any) {
  return await makeApiRequest(
    `OrganizationUser/CreateNewAffiliation`,
    'POST',
    data
  );
}

export async function getPaymentTypes() {
  return await makeApiRequest('Lookup/PaymentTypes');
}

export async function getActiveSchoolYears() {
  return await makeApiRequest('SchoolYear/GetActive?PublishedOnly=true');
}

export async function changePaymentType(
  eventApplicationId: string,
  paymentTypeId: string
) {
  return await makeApiRequest(
    `EventApplication/UpdatePaymentType?eventApplicationId=${eventApplicationId}&paymentType=${paymentTypeId}`,
    'POST'
  );
}

export async function getAvailablePackages(
  organizationId: string,
  schoolYearId: string
) {
  return await makeApiRequest(
    `Package/GetAvailablePackages?OrganizationID=${organizationId}&SchoolYearIds=${schoolYearId}`
  );
}

export async function getOrganization(organizationId: string) {
  return await makeApiRequest(`Organization/Get?id=${organizationId}`);
}

export async function getOrganizationPackages(
  organizationId: string,
  schoolYearId: string
) {
  return await makeApiRequest(
    `OrganizationPackages/GetOrganizationPackagesByOrganizationId?OrganizationID=${organizationId}&SchoolYearId=${schoolYearId}&Active=true`
  );
}

export async function getOrganizationPackagesByOrganizationId(
  organizationId: string
) {
  return await makeApiRequest(
    `OrganizationPackages/GetOrganizationPackagesByOrganizationId?OrganizationID=${organizationId}&Active=true`
  );
}

export async function getMissingOrganizationPackageTypes(
  organizationId: string,
  packageId: string
) {
  return await makeApiRequest(
    `OrganizationPackages/GetMissingOrganizationPackageTypes?OrganizationId=${organizationId}&PackageID=${packageId}`
  );
}

export async function getOrganizationPackageDetails(
  organizationId: string,
  packageId: string
) {
  return await makeApiRequest(
    `Package/GetPackage?PackageID=${packageId}&IncludeTravelTypes=true&IncludeSchoolGroups=true&IncludeDeveloperGroups=true&IncludeEmails=false&IncludeInstitutes=true&OrganizationID=${organizationId}`
  );
}

export async function getOrganizationPackage(
  organizationId: string,
  packageId: string,
  userId: string
) {
  return await makeApiRequest(
    `OrganizationPackages/Get?organizationId=${organizationId}&packageID=${packageId}&userID=${userId}&asCreateType=false`
  );
}

export async function getExpenseGroups() {
  return await makeApiRequest(`expensegroup/get?onlyActive=true`);
}

export async function getSchoolApplicants(organizationId: string) {
  return await makeApiRequest(
    `User/GetAffiliatedUsers?IncludeTravelTypes=true&IncludeSchoolGroups=true&IncludeDeveloperGroups=true&IncludeEmails=false&IncludeInstitutes=true&OrganizationId=${organizationId}`
  );
}

export async function getToolkitInvitees(organizationId: string) {
  return await makeApiRequest(
    `TreasureChestInvitation/GetTreasureChestInvitations?OrganizationId=${organizationId}`
  );
}

export async function resendToolkitInvitation(invitationId: string) {
  return await new Promise(res => setTimeout(res, 1000));
  // return await makeApiRequest(
  //   `TreasureChestInvitation/ResendTreasureChestInvitation?treasureChestInvitationGuid=${invitationId}`,
  //   'POST'
  // );
}

export async function verifyOrganizationUser(organizationUserId: string) {
  return await makeApiRequest(
    `OrganizationUser/VerifyOrganizationUser?organizationUserId=${organizationUserId}`,
    'POST'
  );
}

export async function unverifyOrganizationUser(organizationUserId: string) {
  return await makeApiRequest(
    `OrganizationUser/UnverifyOrganizationUser?organizationUserId=${organizationUserId}`,
    'POST'
  );
}

export async function deleteOrganizationUser(organizationUserId: string) {
  return await makeApiRequest(
    `OrganizationUser/Delete?id=${organizationUserId}`,
    'POST'
  );
}

export async function manageTreasureChestAccess(
  userId: string,
  allowAccess: boolean
) {
  return await makeApiRequest(
    `User/ManageTreasureChestAccess?&userId=${userId}&allowAccess=${allowAccess}`,
    'POST'
  );
}

export async function createAndAffiliateUser(user: any) {
  return await makeApiRequest(`User/CreateAndAffiliateUser`, 'POST', user);
}

export async function getAffiliatedUsers(organizationId: string) {
  return await makeApiRequest(
    `User/GetAffiliatedUsers?ActiveOnly=true&IncludeOrganizationUsers=true&IncludeUserTypes=true&OrganizationId=${organizationId}`
  );
}

export async function searchAffiliatedUsers(
  email: string,
  organizationId: string
) {
  return await makeApiRequest(
    `User/SearchAffiliatedUsers?email=${email}&organizationID=${organizationId}`
  );
}

export async function updateOrganizationUserPositions(data: any) {
  return await makeApiRequest(
    `OrganizationUser/UpdateOrganizationUserPositions`,
    'POST',
    data
  );
}

export async function getFilteredUsers(organizationId: string) {
  return await makeApiRequest(
    `User/GetFilteredUsers?CurrentPage=1&ItemsPerPage=100000&CheckCtleRequirements=true&OrganizationID=${organizationId}`
  );
}

export async function changeAccount(accountId: string) {
  return await makeApiRequest(
    `User/ChangeAccount?accountId=${accountId}`,
    'POST'
  );
}

export async function savePackage(packageData: any) {
  const finalPackage = {
    ...packageData,
    Package: null
  };
  try {
    return await makeApiRequest(
      `OrganizationPackages/Save`,
      'POST',
      finalPackage
    );
  } catch (err) {
    throw new Error(err);
  }
}

export async function getPaymentLink(eventApplicationId: string) {
  try {
    return await makeApiRequest(
      `Payment/InitiatePayment?eventApplicationId=${eventApplicationId}`
    );
  } catch (err) {
    throw new Error(err);
  }
}

export async function updateProfile(profileData: any) {
  try {
    return await makeApiRequest(`User/UpdateProfile`, 'POST', profileData);
  } catch (err) {
    throw new Error(err);
  }
}

export async function userExists(email: string) {
  try {
    return await makeApiRequest(`User/UserExists?Email=${email}`);
  } catch (err) {
    throw new Error(err);
  }
}

export async function updateEmail(userId: number, email: string) {
  try {
    return await makeApiRequest(
      `User/UpdateEmail?userId=${userId}&emailAddress=${email}`,
      'POST'
    );
  } catch (err) {
    throw new Error(err);
  }
}

export async function submitPackage(
  organizationPackageId: string,
  userId: string
) {
  try {
    return await makeApiRequest(
      `OrganizationPackages/Submit?organizationPackageId=${organizationPackageId}&userID=${userId}`
    );
  } catch (err) {
    throw new Error(err);
  }
}

function getDefaultsForMissingPackageTypes(organizationPackage: any) {
  //Find Lowest Travel Cost
  for (
    var i = 0;
    i < organizationPackage.OrganizationPackageTypes.length;
    i++
  ) {
    var currentOrganizationPackageType =
      organizationPackage.OrganizationPackageTypes[i];
    var minimum = null;
    var lowestTravelCostID = null;

    for (
      var j = 0;
      j < currentOrganizationPackageType.PackageTravelType.TravelCosts.length;
      j++
    ) {
      var currentTravelCost =
        currentOrganizationPackageType.PackageTravelType.TravelCosts[j];

      if (
        currentTravelCost.Active == true &&
        (minimum == undefined || currentTravelCost.PackageDay.Value < minimum)
      ) {
        minimum = currentTravelCost.PackageDay.Value;
        lowestTravelCostID = currentTravelCost.TravelCostId;
      }
    }
    if (currentOrganizationPackageType.TravelCostID == null) {
      currentOrganizationPackageType.TravelCostID = lowestTravelCostID;
    }
  }

  //Set Minimums as defaults
  for (
    var k = 0;
    k < organizationPackage.OrganizationPackageTypes.length;
    k++
  ) {
    var currentOrganizationPackageType =
      organizationPackage.OrganizationPackageTypes[k];

    if (currentOrganizationPackageType.TravelCostID == null) {
      currentOrganizationPackageType.TravelCostID = lowestTravelCostID;
    }

    organizationPackage.OrganizationPackageTypes[
      k
    ] = currentOrganizationPackageType;
  }

  return organizationPackage;
}

export async function getTotalOrganizationPackage(
  organizationId: string,
  packageId: string,
  userId: string,
  withMissing?: boolean
) {
  let data;
  const [
    baseData,
    expenseGroups,
    organization,
    packageData,
    affiliatedUsers
  ] = await Promise.all([
    getOrganizationPackage(organizationId, packageId, userId),
    getExpenseGroups(),
    getOrganization(organizationId),
    getOrganizationPackageDetails(organizationId, packageId),
    getSchoolApplicants(organizationId)
  ]);
  if (withMissing) {
    const missingPackageTypes = await getMissingOrganizationPackageTypes(
      organizationId,
      packageId
    );
    const dataWithoutDefaults = {
      ...baseData,
      OrganizationPackageTypes: [
        ...baseData.OrganizationPackageTypes,
        ...missingPackageTypes
      ]
    };
    data = getDefaultsForMissingPackageTypes(dataWithoutDefaults);
  } else {
    data = baseData;
  }

  data.ExpenseGroups = expenseGroups;
  data.Organization = organization;
  data.Package = packageData;
  data.Users = affiliatedUsers;

  if (data.Organization.ExpenseGroupID != null && data.ExpenseGroups != null) {
    for (var i = 0; i < data.ExpenseGroups.length; i++) {
      var expenseGroup = data.ExpenseGroups[i];

      if (data.Organization.ExpenseGroupID == expenseGroup.ExpenseGroupID) {
        data.ExpenseGroup = expenseGroup;
      }
    }
  }

  if (data != null && data.Package != null) {
    for (var i = 0; i < data.Package.DeveloperGroupsPackages.length; i++) {
      var itrDeveloperGroupPackage = data.Package.DeveloperGroupsPackages[i];

      itrDeveloperGroupPackage.TotalQty = 0;

      for (
        var j = 0;
        j < itrDeveloperGroupPackage.DeveloperGroup.DeveloperSections.length;
        j++
      ) {
        var itrDeveloperSection =
          itrDeveloperGroupPackage.DeveloperGroup.DeveloperSections[j];

        for (
          var k = 0;
          k < data.OrganizationPackageDeveloperGroupSections.length;
          k++
        ) {
          var itrOrganizationPackageDeveloperGroupSection =
            data.OrganizationPackageDeveloperGroupSections[k];

          if (
            itrDeveloperSection.DeveloperSectionID ==
            itrOrganizationPackageDeveloperGroupSection.DeveloperSectionID
          ) {
            itrOrganizationPackageDeveloperGroupSection.Quantity =
              itrOrganizationPackageDeveloperGroupSection.OverrideValue != null
                ? itrOrganizationPackageDeveloperGroupSection.OverrideValue
                : itrOrganizationPackageDeveloperGroupSection.Amount;
            itrDeveloperGroupPackage.TotalQty +=
              itrOrganizationPackageDeveloperGroupSection.Quantity || 0;
            itrDeveloperSection.Amount =
              itrOrganizationPackageDeveloperGroupSection.Amount;
            itrDeveloperSection.OverrideValue =
              itrOrganizationPackageDeveloperGroupSection.OverrideValue;
            itrDeveloperSection.OrganizationPackageDeveloperGroupSectionID =
              itrOrganizationPackageDeveloperGroupSection.OrganizationPackageDeveloperGroupSectionID;
            itrDeveloperSection.OrganizationPackageID =
              itrOrganizationPackageDeveloperGroupSection.OrganizationPackageID;
          }
        }
      }
    }
  }

  const calculatedOrgPackage = calculateOrganizationPackageTotals(data);
  return calculatedOrgPackage;
}

export async function getEvent(eventId: number) {
  return await makeApiRequest(
    `event/GetEvent?EventID=${eventId}&IncludeSections=true&includeLocations=true&ValidateSectionCapacity=true`
  );
}

export async function getEventForAttendance(eventId: number) {
  return await makeApiRequest(
    `event/GetEvent?EventID=${eventId}&IncludeSections=true&IncludeAssignedSections=true`
  );
}

export async function getAttendance(sectionId: number) {
  return await makeApiRequest(
    `Section/GetAttendances?sectionId=${sectionId}&onlyToday=true`
  );
}

export async function getSection(sectionId: number) {
  return await makeApiRequest(
    `Section/GetSection?sectionId=${sectionId}&includeEvent=true`
  );
}

export async function updateAttendance(data: any) {
  return await makeApiRequest(
    `Certificate/GenerateEventCertificates`,
    'POST',
    data
  );
}

export async function assignOrganizationPackage(
  organizationPackageId: number,
  userId: number
) {
  return await makeApiRequest(
    `OrganizationPackages/Assign?organizationPackageID=${organizationPackageId}&userID=${userId}`,
    'POST'
  );
}

export async function getOrganizationPackageTicketEventSectionsInfo(
  organizationPackageTicketId: number
) {
  return await makeApiRequest(
    `organizationpackages/GetOrganizationPackageTicketEventSections?organizationPackageTicketID=${organizationPackageTicketId}`
  );
}

export async function getEventApplicationList(
  eventIdsList: string,
  organizationId: string
) {
  return await makeApiRequest(
    `EventApplication/GetList?ItemsPerPage=1000&EventIDs=${eventIdsList}&OrganizationID=${organizationId}`
  );
}

export async function getEventApplications(
  orgPackage: OrganizationPackage,
  organizationId: string
) {
  const eventIds = orgPackage.OrganizationPackageTickets.filter(
    t => t.ActualAmount > 0 && t.ApplicationBasedEventTickets
  )
    .map(t => t.PackageTicket.Ticket.EventTickets)
    .flat()
    .filter(e => e.Event.ApplicationFlag)
    .map(e => e.Event.EventID);

  const eventList = await getEventApplicationList(
    eventIds.join('&EventIDs='),
    organizationId
  );

  return eventList.EventApplications;
}

function flattenSections(sections: any): any[] {
  var out = [];

  if (sections != null) {
    for (var index = 0; index < sections.length; index++) {
      var itrSection = sections[index];

      if (itrSection) {
        out.push(itrSection);
        out = out.concat(flattenSections(itrSection.Sections));
      }
    }
  }

  return out;
}

async function padTicketAssignments(orgPackage: OrganizationPackage) {
  return {
    ...orgPackage,
    OrganizationPackageTickets: orgPackage.OrganizationPackageTickets.map(
      t => ({
        ...t,
        OrganizationPackageTicketAssignments:
          t.OrganizationPackageTicketAssignments || []
      })
    )
      .map(t => {
        if (t.ActualAmount <= 0) return t;
        return {
          ...t,
          PackageTicket: {
            ...t.PackageTicket,
            Ticket: {
              ...t.PackageTicket.Ticket,
              EventTickets: t.PackageTicket.Ticket.EventTickets.map(ticket => {
                const untilNow = moment(ticket.Event.StartDate);
                var now = moment();
                if (untilNow.diff(now, 'days') < 4) {
                  return {
                    ...ticket,
                    Locked: true
                  };
                }
                return ticket;
              })
            }
          }
        };
      })
      .map(t => {
        if (
          t.OrganizationPackageTicketAssignments &&
          t.OrganizationPackageTicketAssignments.length
        ) {
          return t;
        }

        let missing =
          t.ActualAmount - t.OrganizationPackageTicketAssignments.length;
        let OrganizationPackageTicketAssignments = [];

        for (let eventTicket of t.PackageTicket.Ticket.EventTickets) {
          if (eventTicket.Event) {
            if (
              (eventTicket.Event.EventTypeID != EVENT_TYPE.CONFERENCE_DAY &&
                eventTicket.Event.EventTypeID != EVENT_TYPE.PRINCIPAL_CONF) ||
              eventTicket.Event.ApplicationFlag
            ) {
              for (var j = 0; j < missing; j++) {
                OrganizationPackageTicketAssignments.push({
                  OrganizationPackageTicketID: t.OrganizationPackageTicketID,
                  Active: true,
                  UserID: '',
                  TempID: j + 1
                });
              }
            }
          }
        }

        return {
          ...t,
          OrganizationPackageTicketAssignments
        };
      })
  };
}

async function populateTicketEventSections(orgPackage: OrganizationPackage) {
  const orgPackageCopy = { ...orgPackage };
  for (let [
    i,
    organizationPackageTicket
  ] of orgPackageCopy.OrganizationPackageTickets.entries()) {
    const eventTickets =
      organizationPackageTicket.PackageTicket.Ticket.EventTickets;
    for (let eventTicket of eventTickets) {
      if (eventTicket.Event) {
        if (
          (eventTicket.Event.EventTypeID === EVENT_TYPE.CONFERENCE_DAY ||
            eventTicket.Event.EventTypeID === EVENT_TYPE.PRINCIPAL_CONF) &&
          !eventTicket.Event.ApplicationFlag
        ) {
          const organizationPackageTicketEventSectionsInfo = await getOrganizationPackageTicketEventSectionsInfo(
            organizationPackageTicket.OrganizationPackageTicketID
          );
          orgPackageCopy.OrganizationPackageTickets[
            i
          ].OrganizationPackageTicketEventSections = organizationPackageTicketEventSectionsInfo;
          orgPackageCopy.FilteredSections = [];
          const eventInfo = await getEvent(eventTicket.Event.EventID);
          const flattenedSections = flattenSections(eventInfo.Sections);
          orgPackageCopy.FilteredSections = filterSections(
            organizationPackageTicketEventSectionsInfo,
            eventTicket.Event,
            flattenedSections,
            eventTicket.EventTicketID,
            organizationPackageTicket.PackageTicket.Ticket.TicketBundle
          );
        }
      }
    }
  }
  return orgPackageCopy;
}

function shouldLockSection(dates: any) {
  return dates.some((d: any) => {
    let start = moment(d.Date);
    let now = moment();
    return start.diff(now, 'days') < 4;
  });
}

function filterSections(
  organizationPackageTicketEventSectionsInfo: any[],
  event: any,
  flattenedSections: any,
  eventTicketId: number,
  ticketBundle: any
) {
  return organizationPackageTicketEventSectionsInfo
    .filter(
      s =>
        !!flattenedSections.find(
          (section: any) => section.SectionID == s.SectionID
        )
    )
    .map(s => {
      const sectionMatch = flattenedSections.find(
        (section: any) => section.SectionID == s.SectionID
      );
      if ((sectionMatch && !ticketBundle) || (ticketBundle && s.Amount > 0)) {
        const activeSectionDates = sectionMatch.EventSectionDates.filter(
          (d: any) => !!d.Active
        );
        return {
          ...s,
          CombinedAmount: sectionMatch.CombinedAmount || 0,
          Active: sectionMatch.Active,
          DisplayTitle: sectionMatch.DisplayTitle,
          ClosedFlag: sectionMatch.ClosedFlag,
          FreeDay: sectionMatch.FreeDay,
          FreeTickets: sectionMatch.FreeTickets,
          Location: sectionMatch.Location,
          EventSectionDates: activeSectionDates,
          Description: sectionMatch.Description,
          AudienceType: sectionMatch.AudienceType
            ? sectionMatch.AudienceType.Description
            : sectionMatch.AudienceType,
          EventTicketID: sectionMatch.EventTicketID,
          Keywords: sectionMatch.Keywords.map((k: any) => k.Name),
          MaxAmount:
            sectionMatch.TotalCapacity -
            sectionMatch.TicketsPurchased +
            s.Amount,
          Locked: shouldLockSection(
            activeSectionDates.length ? activeSectionDates : [event.StartDate]
          )
        };
      }
    });
}

function padDeveloperGroupAssignments(orgPackage: OrganizationPackage) {
  return {
    ...orgPackage,
    OrganizationPackageDeveloperGroupSections: orgPackage.OrganizationPackageDeveloperGroupSections?.map(
      s => {
        return {
          ...s,
          OrganizationPackageDeveloperGroupSectionAssignments:
            s.OrganizationPackageDeveloperGroupSectionAssignments || []
        };
      }
    ).map(s => {
      const missing =
        s.Amount - s.OrganizationPackageDeveloperGroupSectionAssignments.length;
      const assignments: any = [];
      Array.from(Array(missing)).forEach(m => {
        assignments.push({
          DeveloperSectionID: s.DeveloperSectionID,
          OrganizationPackageDeveloperGroupSectionID:
            s.OrganizationPackageDeveloperGroupSectionID,
          Active: true
        });
      });

      return {
        ...s,
        OrganizationPackageDeveloperGroupSectionAssignments: assignments
      };
    })
  };
}

export async function getTotalOrganizationPackageForAssignments(
  organizationId: string,
  packageId: string,
  userId: string,
  withMissing?: boolean
) {
  const orgPackage: OrganizationPackage = await getTotalOrganizationPackage(
    organizationId,
    packageId,
    userId,
    withMissing
  );

  orgPackage.FilteredSections = [];

  const withPaddedTicketAssignments = await padTicketAssignments(orgPackage);
  const withPopulatedTickets = await populateTicketEventSections(
    withPaddedTicketAssignments
  );
  return withPopulatedTickets;
}

/// QUERIES FOR NEW API ///

const NEW_API_URL = 'http://localhost:8910/.redwood/functions/graphql';

export async function getPackageById(id: string) {
  const query = gql`
    {
      packageRes(id: "220") {
        id
        name
        description
        schoolBasedPDSessions {
          id
          pdSession {
            id
            name
            description
          }
        }
        events {
          id
          event {
            id
            name
            description
          }
        }
        groups {
          id
          group {
            id
            name
            description
            groupSections {
              id
              title
              groupSectionStaffDevelopers {
                staffDeveloper {
                  firstName
                  lastName
                }
                dates {
                  id
                  date
                }
              }
              description
            }
          }
        }
        institutes {
          id
          event {
            id
            name
            description
          }
        }
      }
    }
  `;

  return await request(NEW_API_URL, query);
}
