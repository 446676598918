import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { getHistoricEvents } from '../../../helpers/previousAttendance.helper';
import { FETCH_STATES, useFetch } from '../../../hooks/useFetch';
import Button from '../../common/Button';
import Card from '../../common/Card';
import { addHistoryEvent, editHistoryEvent, deleteHistoryEvent } from '../api';
import AddEventHistoryForm from './AddEventHistoryForm';
import EventApplicationHeader from './EventApplicationHeader';
import {
  CustomHistoricEvent,
  EventHistory,
  LegacyEventAttendee
} from '../NewApiTypes.generated';
import { subYears } from 'date-fns';
import { formatDateRange } from '../../../util/date';

type EventApplicationPreviousAttendanceEventItemProps = {
  eventForEdit: any;
  setEventForEdit: React.Dispatch<any>;
  event: any;
  handleSubmitDeleteEvent: (id: string) => Promise<void>;
  handleSubmitEditEvent: (values: any) => Promise<void>;
  createHistoryEventState: FETCH_STATES;
  editHistoryEventState: FETCH_STATES;
  deleteHistoryEventState: FETCH_STATES;
  handleEditEventClick: (event: any) => void;
  isReview: boolean | undefined;
};

const EventApplicationPreviousAttendanceEventItem = ({
  event,
  eventForEdit,
  setEventForEdit,
  handleSubmitDeleteEvent,
  handleSubmitEditEvent,
  createHistoryEventState,
  editHistoryEventState,
  deleteHistoryEventState,
  isReview,
  handleEditEventClick
}: EventApplicationPreviousAttendanceEventItemProps) => {
  return (
    <>
      {eventForEdit && eventForEdit.id === event.id ? (
        <AddEventHistoryForm
          key={event.name}
          onCancel={() => setEventForEdit(null)}
          onSubmit={handleSubmitEditEvent}
          onDelete={() => handleSubmitDeleteEvent(eventForEdit.id)}
          loadingCreate={createHistoryEventState === FETCH_STATES.PROCESSING}
          loadingEdit={editHistoryEventState === FETCH_STATES.PROCESSING}
          loadingDelete={deleteHistoryEventState === FETCH_STATES.PROCESSING}
          customEvent={eventForEdit}
        />
      ) : (
        <div className="relative mb-4" key={event.name}>
          <div className="my-2">
            <p className="font-bold text-gray-600 text-xl">
              {event.name}
              {event.status === 'REJECTED' && (
                <span className="text-red-400 ml-2 font-medium">
                  (Not Confirmed)
                </span>
              )}
            </p>
            <p className="text-gray-500">
              {formatDateRange(event.dates as string[], 'MMM d, yyyy')}
            </p>
            {!!event.location && (
              <p className="text-gray-500">{event.location}</p>
            )}
          </div>
          {!isReview &&
            event.type === 'CUSTOM' &&
            (event as CustomHistoricEvent).status === 'PENDING' && (
              <FontAwesomeIcon
                icon={faPencilAlt}
                className="fa-xs text-gray-400 cursor-pointer absolute right-2 top-2"
                onClick={() => handleEditEventClick(event)}
              />
            )}
        </div>
      )}
    </>
  );
};
interface EventApplicationPreviousAttendanceProps {
  eventHistory: EventHistory;
  legacyEventAttendees: LegacyEventAttendee[];
  eventApplicationId?: string;
  refetch?: () => void;
  isReview?: boolean;
}

const EventApplicationPreviousAttendance = (
  props: EventApplicationPreviousAttendanceProps
) => {
  const {
    eventHistory,
    isReview,
    eventApplicationId,
    refetch,
    legacyEventAttendees: _legacyEventAttendees
  } = props;
  const [isEventAdding, setIsEventAdding] = useState(false);
  const [eventForEdit, setEventForEdit] = useState<any>(null);
  const description = `Below is a list of your week-long attendance history with the Teachers College Advancing Literacy. Please review to see that this is correct. If you have not attended an Advancing Literacy institute in the past, this should be blank.
  Please note that only Advancing Literacy week-long Other institutes are considered for your placement (first year or advanced) in this institute. Institutes prior to ${subYears(
    new Date(),
    10
  ).getFullYear()} will not be listed.`;

  const {
    state: createHistoryEventState,
    apply: createHistoryEventRecord
  } = useFetch(addHistoryEvent);

  const {
    state: editHistoryEventState,
    apply: editHistoryEventRecord
  } = useFetch(editHistoryEvent);

  const {
    state: deleteHistoryEventState,
    apply: deleteHistoryEventRecord
  } = useFetch(deleteHistoryEvent);

  const handleSubmitNewEvent = async (values: any) => {
    await createHistoryEventRecord({ ...values, eventApplicationId });
    refetch?.();
    setIsEventAdding(false);
  };

  const handleSubmitEditEvent = async (values: any) => {
    await editHistoryEventRecord({ ...values, eventApplicationId });
    refetch?.();
    setIsEventAdding(false);
    setEventForEdit(null);
  };

  const handleSubmitDeleteEvent = async (id: string) => {
    await deleteHistoryEventRecord({ id });
    refetch?.();
    setIsEventAdding(false);
    setEventForEdit(null);
  };

  const handleEditEventClick = (event: any) => {
    setEventForEdit(event || null);
    setIsEventAdding(false);
  };

  const allHistoricEvents = getHistoricEvents(eventHistory);
  const tentativeEvents = allHistoricEvents.filter(
    event => event.type === 'CUSTOM' && event.status === 'PENDING'
  );
  const historicEvents = allHistoricEvents.filter(
    event =>
      event.type !== 'CUSTOM' ||
      (event.type === 'CUSTOM' &&
        event.status !== 'PENDING' &&
        event.status !== 'REJECTED')
  );

  const legacyEventAttendees = _legacyEventAttendees?.filter(
    attendee => !allHistoricEvents.some(e => e.id === attendee.event.id)
  );

  return (
    <Card className="shadow-none">
      <EventApplicationHeader
        title="Attendance"
        description={description}
        size="xs"
      />
      {!!legacyEventAttendees?.length && (
        <>
          {legacyEventAttendees.map(attendee => (
            <div className="relative mb-1" key={attendee.event.name}>
              <div className="my-2">
                <p className="font-bold text-gray-600 text-xl">
                  {attendee.event.name}
                </p>
                <p className="text-gray-500">
                  {formatDateRange(
                    [
                      attendee.event.startDate,
                      attendee.event.endDate !== attendee.event.startDate
                        ? attendee.event.endDate
                        : ''
                    ].filter(Boolean) as string[],
                    'MMM d, yyyy'
                  )}
                </p>
              </div>
            </div>
          ))}
        </>
      )}
      {!!historicEvents?.length && (
        <>
          {historicEvents.map(event => (
            <EventApplicationPreviousAttendanceEventItem
              key={event.id}
              event={event}
              eventForEdit={eventForEdit}
              setEventForEdit={setEventForEdit}
              handleSubmitEditEvent={handleSubmitEditEvent}
              handleSubmitDeleteEvent={handleSubmitDeleteEvent}
              createHistoryEventState={createHistoryEventState}
              editHistoryEventState={editHistoryEventState}
              deleteHistoryEventState={deleteHistoryEventState}
              handleEditEventClick={handleEditEventClick}
              isReview={isReview}
            />
          ))}
        </>
      )}

      {!!tentativeEvents?.length && (
        <>
          <EventApplicationHeader
            size="xs"
            title="Tentative Attendance"
            color="gray"
          />
          <div className="mt-4">
            {tentativeEvents.map(event => (
              <EventApplicationPreviousAttendanceEventItem
                key={event.id}
                event={event}
                eventForEdit={eventForEdit}
                setEventForEdit={setEventForEdit}
                handleSubmitEditEvent={handleSubmitEditEvent}
                handleSubmitDeleteEvent={handleSubmitDeleteEvent}
                createHistoryEventState={createHistoryEventState}
                editHistoryEventState={editHistoryEventState}
                deleteHistoryEventState={deleteHistoryEventState}
                handleEditEventClick={handleEditEventClick}
                isReview={isReview}
              />
            ))}
          </div>
        </>
      )}
      {!legacyEventAttendees?.length &&
        !historicEvents?.length &&
        !tentativeEvents?.length && (
          <>
            <div className="flex justify-center italic">
              There is no previous attendance
            </div>
          </>
        )}

      {!isReview && (
        <div>
          {!isEventAdding ? (
            <Button
              onClick={() => {
                setIsEventAdding(true);
                setEventForEdit(null);
              }}
              text="+ Add Event"
              size="sm"
              className="mt-3"
            />
          ) : (
            <>
              <hr className="my-5" />
              <AddEventHistoryForm
                onCancel={() => setIsEventAdding(false)}
                onSubmit={handleSubmitNewEvent}
                loadingCreate={
                  createHistoryEventState === FETCH_STATES.PROCESSING
                }
              />
            </>
          )}
        </div>
      )}
    </Card>
  );
};

export default EventApplicationPreviousAttendance;
