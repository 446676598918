import React, { useEffect, useRef, useState } from 'react';
import Card from '../common/Card';
import { useLocalStorage } from 'usehooks-ts';
import Button from '../common/Button';
import queryString from 'query-string';
import { navigate } from 'gatsby';
import { failedCashnetPayment } from '../members/api';
import MembersPageTitle from '../members/MembersPageTitle';

interface CashnetCheckoutFailureProps {
  path: string;
}
const CashnetCheckoutFailure = (props: CashnetCheckoutFailureProps) => {
  const allUrlArgs = queryString.parse(location.search);
  const [processingFailure, setProcessingFailure] = useState(true);
  const intervalRef: { current: NodeJS.Timeout | null } = useRef(null);

  const processFailure = async (intervalRef: any) => {
    await failedCashnetPayment(allUrlArgs);
    clearInterval(intervalRef.current as NodeJS.Timeout);
    setProcessingFailure(false);
    navigate('/members/incomplete-transaction');
  };

  useEffect(() => {
    if (processingFailure) {
      intervalRef.current = setInterval(async () => {
        processFailure(intervalRef);
      }, 2000);
      processFailure(intervalRef);
      return () => {
        clearInterval(intervalRef.current as NodeJS.Timeout);
      };
    }
  }, [processingFailure]);

  return (
    <>
      <MembersPageTitle title="Event Cart" />
      <Card className="mt-4 flex flex-col items-center">
        <div className="p-4 text-primary flex justify-center italic">
          Loading...
        </div>
      </Card>
    </>
  );
};

export default CashnetCheckoutFailure;
