import React from 'react';
import MembersPageTitle from './MembersPageTitle';
import Card from '../common/Card';

interface IncompleteTransactionProps {
  path: string;
}

const IncompleteTransaction = (props: IncompleteTransactionProps) => {
  return (
    <>
      <MembersPageTitle title="Incomplete Transaction" />
      <Card className="mt-4 flex flex-col items-center">
        <>
          <div className="p-4 text-primary flex justify-center">
            Something went wrong processing your payment. Please try again later
            or contact technical support
          </div>
        </>
      </Card>
    </>
  );
};

export default IncompleteTransaction;
