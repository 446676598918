import React, { useEffect, useState } from 'react';
import Card from '../common/Card';
import { useLocalStorage } from 'usehooks-ts';
import { eventSectionsPurchase } from '../members/api';
import { toast } from 'react-toastify';
import MembersPageTitle from '../members/MembersPageTitle';
import Button from '../common/Button';
import EventSectionsForm, { EventSectionsCartModel } from './EventSectionsForm';
import { EVENT_SECTION_CART_DEFAULT } from './EventSectionsCart';
import { navigate } from 'gatsby';

interface EventSectionPurchaseModel {
  id: string;
  ticketTypeId: string;
  numberOfTickets: number;
  name: string;
}

const EventSectionsCheckout = ({
  goToCheckout,
  path
}: {
  goToCheckout?: () => void;
  path: string;
}) => {
  useEffect(() => {
    navigate('/members/profile');
  }, []);

  const [cart, setCart] = useLocalStorage<EventSectionsCartModel>(
    'eventSectionsCart',
    EVENT_SECTION_CART_DEFAULT
  );

  const [loading, setLoading] = useState(false);
  const [loadingPO, setLoadingPO] = useState(false);

  const proceedWithPayment = async () => {
    setLoading(true);
    try {
      const data: EventSectionPurchaseModel[] = cart?.items?.map(item => ({
        id: item.id,
        ticketTypeId: item.ticketType.id,
        numberOfTickets: item.numberOfTickets,
        name: item.title,
        type: item.type
      }));

      const result = await eventSectionsPurchase({
        type: 'CREDIT_CARD',
        items: data
      });
      setLoading(false);
      window.location.href = result.url;
    } catch (err) {
      toast.error('Something went wrong');
      setLoading(false);
    }
  };

  const proccedWithPO = async () => {
    setLoadingPO(true);
    try {
      const data: EventSectionPurchaseModel[] = cart?.items?.map(item => ({
        id: item.id,
        ticketTypeId: item.ticketType.id,
        numberOfTickets: item.numberOfTickets,
        name: item.title,
        type: item.type
      }));

      const result = await eventSectionsPurchase({ type: 'PO', items: data });
      setLoadingPO(false);
      window.location.href = result.url;
    } catch (err) {
      toast.error('Something went wrong');
      setLoadingPO(false);
    }
  };

  return (
    <>
      <MembersPageTitle title="Event Cart" />
      <Card className="mt-4">
        {cart?.items?.length ? (
          <div className="max-w-lg bg-white">
            <EventSectionsForm
              cart={cart}
              setCart={setCart}
              onSubmit={proceedWithPayment}
              submitText="Pay by Credit Card"
              loading={loading}
              disabled={loading || loadingPO}
              additionalButtons={
                <>
                  <Button
                    isLoading={loadingPO}
                    type="button"
                    disabled={loading || loadingPO}
                    text={'Pay with Purchase Order'}
                    onClick={proccedWithPO}
                  />
                </>
              }
            />
          </div>
        ) : (
          <div className="p-4 text-primary flex justify-center italic">
            Your cart is empty
          </div>
        )}
      </Card>
    </>
  );
};

export default EventSectionsCheckout;
