import { useEffect } from 'react';

const HistoryNavigationGuard = () => {
  useEffect(() => {
    // Function to add a history entry
    const addHistoryEntry = () => {
      const currentEntry = window.history.state;
      window.history.pushState(
        { ...currentEntry, __guard: true },
        '',
        window.location.pathname
      );
    };

    // Function to handle popstate event
    const handlePopState = (event: any) => {
      if (event.state && !event.state.__guard) {
        // This is a back navigation to an unguarded state
        event.preventDefault();
        const confirmNavigation = window.confirm(
          'Are you sure you want to leave this page? Your form entries will not be saved until submitted.'
        );
        if (confirmNavigation) {
          // If user confirms, we need to manually navigate
          window.history.back();
        } else {
          // If user cancels, we push a new guarded state
          addHistoryEntry();
        }
      }
    };

    // Check if this is the first load of the page in this session
    const isFirstLoad = !sessionStorage.getItem('__historyGuardInitialized');
    if (isFirstLoad) {
      // If it's the first load, add two history entries
      addHistoryEntry(); // This will be the "back" state
      addHistoryEntry(); // This will be the current state
      sessionStorage.setItem('__historyGuardInitialized', 'true');
    } else {
      // If it's not the first load, just ensure we have a guarded state
      addHistoryEntry();
    }

    // Add the popstate event listener
    window.addEventListener('popstate', handlePopState);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return null; // This component doesn't render anything
};

export default HistoryNavigationGuard;
