import React, { useEffect, useState } from 'react';
import Card from '../../common/Card';
import * as _ from 'lodash';
import { SectionTypeEnum, getPathType } from './EventApplicationSections';

interface EventApplicationSectionsProps {
  application: any;
}

const EventApplicationSectionsPreview = (
  props: EventApplicationSectionsProps
) => {
  const [type, setType] = useState<SectionTypeEnum | null>(null);
  const [firstYearPath, setFirstYearPath] = useState<any>(null);
  const [advancedPath, setAdvancedPath] = useState<any>(null);

  const [
    advancedPathRequireRankedSelection,
    setAdvancedPathRequireRankedSelection
  ] = useState<any>(false);
  useEffect(() => {
    if (props.application) {
      setType(getPathType(props.application));
      setFirstYearPath(
        props.application.event.paths.find(
          (p: any) => p.name === SectionTypeEnum.FIRST_YEAR
        )
      );
      setAdvancedPath(
        props.application.event.paths.find(
          (p: any) => p.name === SectionTypeEnum.ADVANCED
        )
      );
    }
  }, [props.application]);

  useEffect(() => {
    if (advancedPath) {
      const { requireRankedSelection } = advancedPath;
      setAdvancedPathRequireRankedSelection(requireRankedSelection);
    }
  }, [advancedPath]);

  return (
    <Card className="shadow-none">
      {firstYearPath || advancedPath ? (
        <>
          <h2 className="text-primary mb-2 font-bold text-lg">
            {type === SectionTypeEnum.FIRST_YEAR
              ? firstYearPath?.name
              : advancedPath?.name}
          </h2>
          {(type === SectionTypeEnum.FIRST_YEAR ||
            !advancedPathRequireRankedSelection) && (
            <div className="font-bold">
              Selected section:{' '}
              {type === SectionTypeEnum.FIRST_YEAR
                ? props.application.requestedGroupings?.[0]?.eventPathGrouping
                    ?.title
                : props.application.requestedSections[0]?.eventSections
                    ?.displayTitle ||
                  props.application.requestedSections[0]?.eventSections?.title}
            </div>
          )}
          {type === SectionTypeEnum.ADVANCED &&
            advancedPathRequireRankedSelection && (
              <div>
                {_.sortBy(props.application.requestedSections, 'ranking').map(
                  (section: any) => (
                    <div key={section.id} className="font-bold">{`${
                      section.ranking
                    }: ${section.eventSections.displayTitle ||
                      section.eventSections.title}`}</div>
                  )
                )}
              </div>
            )}
        </>
      ) : null}
    </Card>
  );
};

export default EventApplicationSectionsPreview;
