import React, { useEffect, useState } from 'react';
import {
  faExternalLinkAlt,
  faLongArrowAltLeft,
  faPencilAlt
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';
import { useQuery } from 'react-query';
import Button from '../../common/Button';
import Card from '../../common/Card';
import LoadingDialog from '../../common/LoadingDialog';
import SEO from '../../Seo';
import InfoBlock from './../InfoBlock';
import ManageRosterLink from './../ManageRosterLink';
import { QueryReturnState } from './../Enum';
import {
  IBillingContact,
  IOrganization,
  OrganizationMembershipRole
} from './../NewApiTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import OrganizationEditAddressForm, {
  OrganizationEditAddressType
} from './OrganizationEditAddressForm';
import OrganizationEditBillingContactForm, {
  OrganizationEditBillingContactType
} from './OrganizationEditBillingContactForm';
import OrganizationEditNameForm, {
  OrganizationEditNameType
} from './OrganizationEditNameForm';
import { FETCH_STATES, useFetch } from '../../../hooks/useFetch';
import OrganizationEditTicketRecipientForm from './OrganizationEditTicketRecipientForm';
import {
  getOrganizationTCAL,
  updateOrganizationInfoTCAL
} from '../../../tcal-api';

const EditPencilBtn = ({ onClick }: { onClick?: () => void }) => (
  <button className="border-none bg-transparent w-4 h-4" onClick={onClick}>
    <FontAwesomeIcon icon={faPencilAlt} className="text-gray-400 fa-xs mt-1" />
  </button>
);

type EditType =
  | null
  | 'name'
  | 'mailing-address'
  | 'billing-address'
  | 'new-billing-contact'
  | 'ticket-recipient'
  | 'billing-contact';

const ManageOrganizationEditForm = ({
  edit,
  organization,
  editId,
  refetch,
  cancelEdit
}: {
  edit: EditType;
  organization: IOrganization;
  editId?: string;
  cancelEdit: () => void;
  refetch: () => Promise<void>;
}) => {
  const { apply, state } = useFetch(updateOrganizationInfoTCAL);

  const handleSubmit = async (v: any) => {
    if (edit === 'name') {
      // edit name
      const values: OrganizationEditNameType = v;
      await apply(organization.id, {
        type: 'name',
        values
      });
    } else if (edit === 'billing-contact') {
      // edit billing contact
      const values: OrganizationEditBillingContactType = v;
      await apply(organization.id, {
        type: 'billing-contact',
        values: { ...values, id: editId }
      });
    } else if (edit === 'new-billing-contact') {
      // create billing contact
      const values: OrganizationEditBillingContactType = v;
      await apply(organization.id, {
        type: 'new-billing-contact',
        values
      });
    } else if (edit === 'mailing-address') {
      // edit mailing address
      const values: OrganizationEditAddressType = v;
      await apply(organization.id, {
        type: 'mailing-address',
        values: {
          ...values,
          phone: values.phone.replace(/[^\d]/g, '')
        }
      });
    } else if (edit === 'billing-address') {
      // edit billing address
      const values: OrganizationEditAddressType = v;
      await apply(organization.id, {
        type: 'billing-address',
        values: {
          ...values,
          phone: values.phone.replace(/[^\d]/g, '')
        }
      });
    } else if (edit === 'ticket-recipient') {
      // edit ticket recipient
      const values: OrganizationEditAddressType = v;
      await apply(organization.id, {
        type: 'ticket-recipient',
        values
      });
    }

    refetch();

    cancelEdit();
  };

  return (
    <>
      {edit === 'name' && (
        <OrganizationEditNameForm
          cancelEdit={cancelEdit}
          name={organization.name}
          onSubmit={handleSubmit}
          loading={state === FETCH_STATES.PROCESSING}
        />
      )}
      {edit === 'mailing-address' && (
        <OrganizationEditAddressForm
          cancelEdit={cancelEdit}
          address={organization.mailingAddress || ({} as any)}
          onSubmit={handleSubmit}
          loading={state === FETCH_STATES.PROCESSING}
        />
      )}
      {edit === 'billing-address' && (
        <OrganizationEditAddressForm
          cancelEdit={cancelEdit}
          address={organization.billingAddress || ({} as any)}
          onSubmit={handleSubmit}
          loading={state === FETCH_STATES.PROCESSING}
        />
      )}
      {edit === 'billing-contact' && (
        <OrganizationEditBillingContactForm
          billingContact={
            organization.billingContacts?.find(bc => bc.id === editId) ||
            undefined
          }
          cancelEdit={cancelEdit}
          onSubmit={handleSubmit}
          loading={state === FETCH_STATES.PROCESSING}
        />
      )}
      {edit === 'new-billing-contact' && (
        <OrganizationEditBillingContactForm
          cancelEdit={cancelEdit}
          onSubmit={handleSubmit}
          loading={state === FETCH_STATES.PROCESSING}
        />
      )}
      {edit === 'ticket-recipient' && (
        <OrganizationEditTicketRecipientForm
          cancelEdit={cancelEdit}
          onSubmit={handleSubmit}
          organization={organization}
          loading={state === FETCH_STATES.PROCESSING}
        />
      )}
    </>
  );
};

interface ManageOrganizationProps {
  path: string;
  organizationId: string;
}
const ManageOrganization = (props: ManageOrganizationProps) => {
  const [isEdit, setIsEdit] = useState<EditType>(null);
  const [editId, setEditId] = useState<null | string>(null);

  const { data, isFetching, refetch } = useQuery<{
    state: QueryReturnState;
    organization: IOrganization;
    organizationMemberRole?: OrganizationMembershipRole;
  }>({
    queryKey: ['organization', 1],
    queryFn: () => getOrganizationTCAL(props.organizationId)
  });

  const isAdmin =
    data?.organizationMemberRole === OrganizationMembershipRole.ADMIN;

  const isOwner =
    data?.organizationMemberRole === OrganizationMembershipRole.OWNER;

  return (
    <>
      <SEO title="Manage Organization" />
      {isFetching && <LoadingDialog />}
      {!isFetching && !!data?.organization && (
        <>
          <Card>
            <h3 className="text-primary font-bold text-2xl mb-6">
              Organization Information
            </h3>
            <div className="mt-2 mb-4">
              <Link to={`/members/organization/select`}>
                <Button
                  text="Back to Organization Select"
                  icon={faLongArrowAltLeft}
                  size="sm"
                />
              </Link>
            </div>
            {isEdit ? (
              <ManageOrganizationEditForm
                edit={isEdit}
                organization={data.organization}
                cancelEdit={() => {
                  setIsEdit(null);
                  setEditId(null);
                }}
                editId={editId || ''}
                refetch={refetch as any}
              />
            ) : (
              <div className="mt-8 flex flex-col gap-y-3">
                <div className="mr-8 flex w-2/3 ">
                  <div className="mr-8 w-1/3 flex gap-x-2">
                    <InfoBlock title="Name" info={[data.organization.name]} />
                    {isOwner && (
                      <EditPencilBtn onClick={() => setIsEdit('name')} />
                    )}
                  </div>
                  <div className="mr-8 w-1/3 flex gap-x-2">
                    <InfoBlock
                      title="Mailing Address"
                      info={[
                        data?.organization.mailingAddress?.addressLine1 ?? '',
                        data?.organization.mailingAddress?.addressLine2 ?? '',
                        [
                          data?.organization.mailingAddress?.city ?? '',
                          data?.organization.mailingAddress?.stateProvince ?? ''
                        ]
                          .filter(Boolean)
                          .join(', '),
                        data?.organization.mailingAddress?.zipPostal ?? ''
                      ]}
                    />
                    {(isOwner || isAdmin) && (
                      <EditPencilBtn
                        onClick={() => setIsEdit('mailing-address')}
                      />
                    )}
                  </div>
                  <div className="mr-8 w-1/3 flex gap-x-2">
                    <InfoBlock
                      title="Billing Address"
                      info={[
                        data?.organization.billingAddress?.addressLine1 ?? '',
                        data?.organization.billingAddress?.addressLine2 ?? '',
                        [
                          data?.organization.billingAddress?.city ?? '',
                          data?.organization.billingAddress?.stateProvince ?? ''
                        ]
                          .filter(Boolean)
                          .join(', '),
                        data?.organization.billingAddress?.zipPostal ?? ''
                      ]}
                    />
                    {(isOwner || isAdmin) && (
                      <EditPencilBtn
                        onClick={() => setIsEdit('billing-address')}
                      />
                    )}
                  </div>
                </div>

                <div className="mr-8 flex w-2/3 justify-between">
                  <div className="mr-8 w-1/3 flex flex-col">
                    <label className="uppercase text-gray-500 text-sm sans-serif">
                      {'Billing Contacts'}
                    </label>
                    <div className="mt-1">
                      {!data.organization.billingContacts?.length ? (
                        <p className="text-gray-400 sans-serif italic">
                          Not set
                        </p>
                      ) : (
                        <>
                          <div className="divide-y">
                            {data.organization.billingContacts.map(
                              (item: IBillingContact, i: number) => (
                                <div key={i} className="py-0.5">
                                  <div className="text-gray-700 sans-serif flex justify-between">
                                    <span>
                                      {item.firstName} {item.lastName}
                                    </span>
                                    {(isOwner || isAdmin) && (
                                      <EditPencilBtn
                                        onClick={() => {
                                          setIsEdit('billing-contact');
                                          setEditId(item.id);
                                        }}
                                      />
                                    )}
                                  </div>
                                  <div className="text-gray-500 sans-serif text-sm">
                                    {item.email}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                          {(isOwner || isAdmin) && (
                            <button
                              className="border-none bg-transparent text-primary sans-serif text-sm mt-1 w-40"
                              onClick={() => setIsEdit('new-billing-contact')}
                            >
                              + Add New Billing Contact
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="w-1/3 flex gap-x-2 mr-8">
                    <InfoBlock
                      title="Ticket Recipient"
                      infoClassNames={['text-sm', 'text-sm -mt-0.5']}
                      info={[
                        [
                          data.organization?.ticketRecipient?.user?.firstName ??
                            '',
                          data.organization?.ticketRecipient?.user?.lastName ??
                            ''
                        ]
                          .filter(Boolean)
                          .join(' '),
                        data.organization?.ticketRecipient?.user?.email || ''
                      ]}
                    />
                    {(isOwner || isAdmin) && (
                      <EditPencilBtn
                        onClick={() => setIsEdit('ticket-recipient')}
                      />
                    )}
                  </div>
                  <div className="w-1/3 mr-8" />
                </div>
              </div>
            )}
          </Card>
          {(isOwner || isAdmin) && (
            <div className="mt-4">
              <Card>
                {data.organization?.packageRequests && (
                  <>
                    <h3 className="text-primary font-bold text-2xl mb-6">
                      Manage Affiliated Staff and Toolkit Access
                    </h3>
                    {(isOwner || isAdmin) && (
                      <div className="mb-2">
                        <ManageRosterLink
                          organizationId={props.organizationId}
                          withArrow={false}
                        />
                      </div>
                    )}
                    {/* <p className="sans-serif uppercase text-gray-600 text mb-2">
                    Packages
                  </p>
                  {!!data.organization?.packageRequests?.length ? (
                    <>
                      {data.organization.packageRequests.map(
                        (orgPackage, i: number) => (
                          <div
                            className="flex justify-between border-b border-gray-200 py-4"
                            key={i}
                          >
                            <p className="font-bold text-gray-600 text-lg sans-serif my-auto">
                              {orgPackage.package?.name || ''}
                            </p>

                            <div className="my-auto">
                              <Link
                                to={`/members/manage-services/package-request/${orgPackage.id}/review`}
                              >
                                <Button
                                  text="Review Package"
                                  size="sm"
                                  icon={faExternalLinkAlt}
                                />
                              </Link>
                            </div>
                          </div>
                        )
                      )}
                    </>
                  ) : (
                    <p className="text-gray-400 italic sans-serif">
                      No packages found
                    </p>
                  )} */}
                  </>
                )}
              </Card>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ManageOrganization;
