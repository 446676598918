import React from 'react';
import Select from 'react-select';
import CheckedSelect from '../common/CheckedSelect';

type FormatOptionLabel = {
  value: string;
  label: string;
  email: string;
};
const formatOptionLabel = ({ value, label, email }: FormatOptionLabel) => (
  <div>
    <p className="sans-serif">{label}</p>
    <p className="text-gray-400 text-xs sans-serif">{email}</p>
  </div>
);

const customStyles = {
  control: (base: any) => ({
    ...base,
    height: 50,
    minHeight: 50
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999, width: '300px' })
};

interface UserSelectProps {
  options: { value: string; label: string; email: string }[];
  placeholder?: string;
  noOptionsMessage?: () => string;
  isDisabled?: boolean;
  useCheckedSelect?: boolean;
  onChange: any;
  defaultValue?: any;
}
const UserSelect = (props: UserSelectProps) => {
  if (props.useCheckedSelect) {
    return <CheckedSelect {...props} />;
  } else {
    return (
      <Select
        {...props}
        formatOptionLabel={formatOptionLabel}
        styles={customStyles}
        menuPortalTarget={document.body}
      />
    );
  }
};

export default UserSelect;
