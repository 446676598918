import { faCheckCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Select from 'react-select';
import Button from '../common/Button';
import Card from '../common/Card';
import SEO from '../Seo';
import {
  getAvailablePackages,
  getPackageOfferingApplications,
  getSchoolYears,
  initiatePackageRequest
} from './api';
import ApplicationStatus from './ApplicationStatus';

type PackageStatus =
  | 'NOT_STARTED'
  | 'STARTED'
  | 'SUBMITTED'
  | 'ACCEPTED'
  | 'REJECTED'
  | 'CHANGES_REQUESTED'
  | 'WITHDRAWN';

const getPackageManagementLink = (
  packageRequestId: string,
  segment?: string
) => {
  return `/members/manage-services/package-request/${packageRequestId}/${segment ||
    'staff-options'}`;
};

interface PackageOfferingListingProps {
  packageId: string;
  packageName: string;
  packageDescription: string;
  brochureLink: string;
  packageStatus: PackageStatus;
  packageStatusId: number;
  organizationId: string;
  onInitiatePackage?: () => void;
  packageRequestId?: string;
  initiatePackageLoading?: boolean;
}

const PackageOfferingListing = (props: PackageOfferingListingProps) => {
  return (
    <div className="mt-2 p-4 border border-gray-300 rounded-lg flex justify-between">
      <div className="w-2/3">
        <p className="sans-serif font-bold text-gray-800 text-lg">
          {props.packageName}
        </p>
        <div
          className="mt-2 text-gray-600 sans-serif"
          dangerouslySetInnerHTML={{
            __html: props.packageDescription
          }}
        />
      </div>
      <div className="flex justify-between">
        <div className="my-auto flex flex-col space-y-2">
          <Button
            text="Choose Package"
            onClick={props.onInitiatePackage}
            isLoading={props.initiatePackageLoading}
          />
          <a href={props.brochureLink} target="_blank">
            <Button text="Download Brochure" />
          </a>
        </div>
      </div>
    </div>
  );
};

interface PackageOfferingApplicationListingProps {
  packageOfferingApplication: any;
  organizationId: string;
}

const PackageOfferingApplicationListing = (
  props: PackageOfferingApplicationListingProps
) => {
  return (
    <div className="mt-2 p-4 border border-gray-300 rounded-lg flex justify-between">
      <div className="w-2/3">
        <p className="sans-serif font-bold text-gray-800 text-lg">
          {props.packageOfferingApplication.packageOffering.name}
        </p>
        <div
          className="mt-2 text-gray-600 sans-serif"
          dangerouslySetInnerHTML={{
            __html: props.packageOfferingApplication.packageOffering.description
          }}
        />
      </div>
      <div className="flex justify-between">
        <div className="my-auto flex flex-col space-y-2">
          {props.packageOfferingApplication.status === 'SUBMITTED' ? (
            <div className="flex">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-green-600 hover:text-white cursor-pointer my-auto mr-2"
              />
              <p className="text-green-600 sans-serif">
                Package Application Submitted
              </p>
            </div>
          ) : null}

          <>
            {props.packageOfferingApplication.status === 'STARTED' ? (
              <div className="flex">
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="text-yellow-600 hover:text-white cursor-pointer my-auto mr-2"
                />
                <p className="text-yellow-600 sans-serif">
                  Package Application Started
                </p>
              </div>
            ) : null}
          </>

          <>
            {props.packageOfferingApplication.status === 'ACCEPTED' ? (
              <div className="flex">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-green-600 hover:text-white cursor-pointer my-auto mr-2"
                />
                <p className="text-green-600 sans-serif">
                  Package Application Accepted
                </p>
              </div>
            ) : null}
          </>

          <>
            {props.packageOfferingApplication.status === 'ACCEPTED' &&
            props.packageOfferingApplication.clientPortalLink ? (
              <a
                href={props.packageOfferingApplication.clientPortalLink}
                target="_blank"
              >
                <Button text="Manage Application" />
              </a>
            ) : null}
          </>

          <>
            {props.packageOfferingApplication.status === 'REJECTED' ? (
              <div className="flex">
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="text-yellow-600 hover:text-white cursor-pointer my-auto mr-2"
                />
                <p className="text-yellow-600 sans-serif">
                  Package Application Not Accepted
                </p>
              </div>
            ) : null}
          </>

          <a
            href={props.packageOfferingApplication.brochureLink}
            target="_blank"
          >
            <Button text="Download Brochure" />
          </a>
        </div>
      </div>
    </div>
  );
};

function getSchoolYearsForSelect(schoolYears: any) {
  const schoolYearsOptions: Array<{
    value: string;
    label: string;
  }> = schoolYears.map((year: any) => ({
    value: year.id,
    label: year.name
  }));

  return schoolYearsOptions.sort((a, b) => {
    return a.label.localeCompare(b.label);
  });
}

function getMostRecentSchoolYear(schoolYears: any) {
  const formattedSchoolYears = getSchoolYearsForSelect(schoolYears);

  return formattedSchoolYears[formattedSchoolYears.length - 1];
}

interface SelectServiceProps {
  organizationId: string;
}
const SelectService = (props: SelectServiceProps) => {
  const [selectedSchoolYearId, setSelectedSchoolYearId] = useState('');
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(
    props.organizationId
  );

  useEffect(() => {
    setSelectedOrganizationId(props.organizationId);
  }, [props.organizationId]);

  const {
    isLoading: schoolYearsLoading,
    error: schoolYearsError,
    data: schoolYearsResult
  } = useQuery('schoolYears', getSchoolYears);

  const {
    isLoading: availablePackagesLoading,
    error: availablePackagesError,
    data: availablePackagesResult
  } = useQuery(
    ['availablePackages', selectedSchoolYearId, selectedOrganizationId],
    async () => {
      if (selectedSchoolYearId) {
        const packages = await getAvailablePackages(
          selectedSchoolYearId,
          selectedOrganizationId
        );

        const applications = await getPackageOfferingApplications(
          selectedSchoolYearId,
          selectedOrganizationId
        );

        return {
          packageOfferings: packages.packageOfferings.filter(
            (offering: any) => {
              return !applications.packageOfferingApplications.find(
                (application: any) =>
                  application.packageOffering.id === offering.id
              );
            }
          ),
          packageOfferingApplications: applications.packageOfferingApplications
        };
      }
    }
  );

  useEffect(() => {
    if (schoolYearsResult && schoolYearsResult.schoolYears) {
      setSelectedSchoolYearId(
        getMostRecentSchoolYear(schoolYearsResult.schoolYears).value.toString()
      );
    }
  }, [schoolYearsResult]);

  const initiatePackageMutation = useMutation(
    ['iniiatePackage'],
    initiatePackageRequest,
    {
      onSuccess: ({ packageOfferingApplication }) =>
        navigate(
          `/members/package-applications/${packageOfferingApplication.packageOffering.id}/form`
        )
    }
  );

  return (
    <>
      <SEO title="Select a Service" />
      <Card>
        <h2 className="sans-serif text-primary font-bold text-2xl">
          Select a Service
        </h2>
        <p className="sans-serif mt-4">
          Schools wishing to secure site-based staff development with Advancing
          Literacy agree to the basic package of professional development
          services, itemized below. This year, we have created additional
          opportunities to customize your package. Part of the Staff Development
          Package is flexible, allowing you to best meet the needs of your
          particular school(s).
        </p>
        {schoolYearsLoading && <p>Loading...</p>}
        {schoolYearsResult && schoolYearsResult.schoolYears && (
          <>
            <div className="mt-6">
              <h3 className="text-gray-600 font-semibold text-lg">
                School Year
              </h3>
              <div className="w-1/3 mt-2">
                <Select
                  defaultValue={getMostRecentSchoolYear(
                    schoolYearsResult.schoolYears
                  )}
                  options={getSchoolYearsForSelect(
                    schoolYearsResult.schoolYears
                  )}
                  onChange={option => {
                    if (option?.value) {
                      setSelectedSchoolYearId(option.value.toString());
                    }
                  }}
                />
              </div>
            </div>

            {availablePackagesResult &&
            !availablePackagesResult.packageOfferings?.length &&
            !availablePackagesResult.packageOfferingApplications?.length ? (
              <div className="mt-6">
                <p className="sans-serif text-yellow-600 font-semibold text-lg">
                  No services available for this school year
                </p>
              </div>
            ) : null}

            <div className="mt-6">
              {availablePackagesResult &&
              availablePackagesResult.packageOfferings &&
              availablePackagesResult.packageOfferings.length ? (
                <>
                  <h3 className="text-gray-600 font-semibold text-lg">
                    Available Services
                  </h3>
                  <>
                    {availablePackagesResult.packageOfferings.map((p: any) => (
                      <PackageOfferingListing
                        packageId={p.id}
                        organizationId={selectedOrganizationId}
                        packageName={p.name}
                        packageDescription={p.description}
                        brochureLink={p.brochureLink}
                        packageStatus={p.status}
                        packageStatusId={123}
                        onInitiatePackage={() =>
                          initiatePackageMutation.mutate({
                            packageOfferingId: p.id,
                            organizationId: selectedOrganizationId
                          })
                        }
                        initiatePackageLoading={
                          initiatePackageMutation.isLoading
                        }
                        key={p.id}
                      />
                    ))}
                  </>
                </>
              ) : null}
            </div>
            <div className="mt-6">
              {availablePackagesResult &&
              availablePackagesResult.packageOfferingApplications &&
              !!availablePackagesResult.packageOfferingApplications.length ? (
                <>
                  <h3 className="text-gray-600 font-semibold text-lg">
                    Your Service Applications
                  </h3>
                  <>
                    {availablePackagesResult.packageOfferingApplications.map(
                      (p: any) => (
                        <PackageOfferingApplicationListing
                          packageOfferingApplication={p}
                          organizationId={selectedOrganizationId}
                          key={p.id}
                        />
                      )
                    )}
                  </>
                </>
              ) : null}
            </div>
          </>
        )}
      </Card>
    </>
  );
};

export default SelectService;
