import React from 'react';
import { OptionType } from '../../../constants/select';
import Card from '../../common/Card';
import { FormField } from '../../common/Form';
import EventApplicationField from './EventApplicationField';
import { formatAsCurrency } from '../../../util';

interface EventApplicationQuestionnaireProps {
  application: any;
  selectedPaymentOptionId: any;
}

const EventApplicationPayment = (props: EventApplicationQuestionnaireProps) => {
  const { application, selectedPaymentOptionId } = props;
  const due = application?.event?.finance?.due || 0;
  const paymentOptions = application?.event?.finance?.paymentOptions || [];
  const options: OptionType[] = paymentOptions.map((o: any) => ({
    value: o.id,
    label: o.label
  }));
  const costPerCredit = application?.event.finance?.costPerCredit / 100 || 0;
  const specialCourseFee =
    application?.event.finance?.specialCourseFee / 100 || 0;
  const selectedPaymentOption = paymentOptions.filter(
    (po: any) => po.id === selectedPaymentOptionId
  )[0];
  return (
    <Card className="shadow-none">
      <h4 className="text-gray-600 mt-1 text-lg mb-2">
        If your application is accepted payment will be due within{' '}
        <b>{`${due} ${due > 1 ? 'days' : 'day'}`}.</b> If we do not receive
        payment or request for a time extension within this time period your
        application will be automatically <b>put on our waitlist.</b>
      </h4>
      {application.status === 'STARTED' ? (
        <>
          <FormField
            name="paymentOption"
            type="select"
            className="w-1/4"
            options={options}
            isLoading={false}
          />
          {selectedPaymentOption?.name === 'graduate_credit' && (
            <>
              <FormField
                name="numCreditsSelected"
                label="Number of Credits"
                placeholder="Credits"
                type="select"
                className="w-1/4"
                options={application?.event?.finance?.numGraduateCredits?.map(
                  (gc: any) => ({ label: gc, value: gc })
                )}
                isLoading={false}
              />
              <p className="text-gray-600 text-lg my-2 mx-3">
                By selecting this option you are acknowledging that you would
                like to apply for graduate credit for this institute. Graduate
                credit is offered through Teachers College at a cost of{' '}
                {formatAsCurrency(costPerCredit)} per credit for the 2023-24
                academic year. All graduate credit participants must also pay a
                special course fee of {formatAsCurrency(specialCourseFee)}.
                Graduate credit is available for beginning or advanced sections.
                In order to receive graduate credit you must either already be a
                TC student or you must register as a non-degree student at TC.
                This section has limited space, and priority is given to TC
                Literacy Specialist students.
              </p>
            </>
          )}
        </>
      ) : (
        <>
          <EventApplicationField label="Payment Option">
            {options.find(
              (o: OptionType) => o.value === application?.paymentOptionId
            )?.label || 'N/A'}
          </EventApplicationField>
          {application?.numCreditsSelected && (
            <EventApplicationField label="Number of Credits">
              {application?.numCreditsSelected || 'N/A'}
            </EventApplicationField>
          )}
        </>
      )}
    </Card>
  );
};

export default EventApplicationPayment;
