import React, { useContext, useEffect, useState } from 'react';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import classnames from 'classnames';
import {
  flatPolyfill,
  formatAsCurrency,
  formatDateWithoutTimezone
} from '../../util';
import ManageRosterLink from './ManageRosterLink';
import NoSelectionsWarning from './NoSelectionsWarning';
import { OrganizationPackage } from './OrganizationPackageTypes';
import { ProductPanelTitle } from './ProductPanel';
import SelectionInstructions from './SelectionInstructions';
import UserSelect from './UserSelect';
import { getOrgDevSectionByDevSection, shouldShowEvent } from './Util';
import {
  EventTicketAssignment,
  GroupTicketAssignment,
  TicketAssignments,
  UserOptionType
} from './AssignTickets';
import { PackageContext } from '../../context/PackageContext';
import {
  PackageGroup,
  PackageRequestGroupSection,
  User
} from './NewApiTypes.generated';
import {
  getTicketAllocationNotAvailableMessage,
  isNotAvailableForPurchase
} from '../../helpers/eventSections.helper';
import UnableToEditNotice from './UnableToEditNotice';
import { getUserInfo } from '../../util/auth';

function allAssignmentsComplete(assignments: UserOptionType[], count: number) {
  return assignments?.filter(Boolean).length === count;
}
interface GroupPackagePanelProps {
  title: string;
  dates: string[];
  days: number;
  audience: string;
  users: { value: string; label: string; email: string }[];
  open: boolean;
  assignments: GroupTicketAssignment;
  assignmentsCount: number;
  onAssignmentSelect: (a: { userId: string; position: number }) => void;
  onClearAssignment: (a: { userId: string; position: number }) => void;
  maxAmount: number;
}

const GroupPackagePanel = (props: GroupPackagePanelProps) => {
  const buttonClasses = classnames({
    'flex justify-between w-full px-4 py-2 font-medium text-left text-blue-100 bg-primary rounded-t-lg hover:bg-primary-dark focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75 sans-serif': true,
    'rounded-b-lg': !props.open
  });
  const chevronClasses = classnames({
    'w-5 h-5 text-blue-100': true,
    'transform rotate-180': props.open
  });

  return (
    <>
      <Disclosure.Button className={buttonClasses}>
        <span className="sans-serif text-lg">
          <FontAwesomeIcon
            icon={
              allAssignmentsComplete(
                props.assignments.assignedUsers,
                props.assignmentsCount
              )
                ? faCheckCircle
                : faCircle
            }
            className="text-blue-100 mr-2"
          />
          {props.title}
        </span>
        <ChevronUpIcon className={chevronClasses} />
      </Disclosure.Button>
      <Disclosure.Panel className="p-6 text-gray-700 sans-serif border-gray-300 border-b border-l border-r rounded-b-lg">
        <div className="flex justify-between">
          <div className="w-3/4 flex">
            <div className="mr-10">
              <ProductPanelTitle title="Date(s)" />
              {props.dates && !!props.dates.length && (
                <>
                  {props.dates.map((d, i: number) => (
                    <p key={i}>
                      {formatDateWithoutTimezone(new Date(d), true)}
                    </p>
                  ))}
                </>
              )}
              {props.days > 0 && (
                <p>
                  {props.days && props.days > 1 ? (
                    <span>{props.days} Dates TBD</span>
                  ) : props.days && props.days === 1 ? (
                    <span>{props.days} Date TBD</span>
                  ) : null}
                </p>
              )}
            </div>
            <div className="mr-10">
              <ProductPanelTitle title="Audience" />
              <p>{props.audience}</p>
            </div>
          </div>

          <div className="w-1/4 ml-4">
            {Array(props.assignmentsCount)
              .fill(0)
              .map((_n, i: number) => {
                const userMatch = props.assignments.assignedUsers[i] || null;
                return (
                  <div className="mb-3" key={i}>
                    <ProductPanelTitle
                      title={
                        props.assignmentsCount < 2
                          ? `Assignment`
                          : `Assignment ${i + 1}`
                      }
                    />
                    <UserSelect
                      options={
                        props.users.filter(
                          u =>
                            !props.assignments.assignedUsers.some(
                              au => au && au.value === u.value
                            )
                        ) || []
                      }
                      onChange={(value: any) =>
                        props.onAssignmentSelect({
                          userId: value.value,
                          position: i
                        })
                      }
                      // @ts-ignore
                      value={userMatch || null}
                    />
                    {userMatch && (
                      <div className="flex justify-end">
                        <button
                          className="mt-1 text-sm sans-serif text-red-600 text-right"
                          onClick={() =>
                            props.onClearAssignment({
                              userId: userMatch.value,
                              position: i
                            })
                          }
                        >
                          Clear selection
                        </button>
                      </div>
                    )}
                  </div>
                );
              })}

            <p
              className={`sans-serif ${
                props.maxAmount <
                props.assignments.assignedUsers?.filter(Boolean).length
                  ? 'text-red-600'
                  : 'text-gray-600'
              }`}
            >
              Maximum Tickets Per Session: {props.maxAmount}
            </p>
          </div>
        </div>
      </Disclosure.Panel>
    </>
  );
};

interface EventTicketPanelProps {
  open: boolean;
  title: string;
  ticketTitle: string;
  ticketPrice: number;
  actualAmount: number;
  users: UserOptionType[];
  blockApplicantAllocationMessage?: string;
  assignments: EventTicketAssignment;
  onAssignmentSelect: (a: { userId: string; position: number }) => void;
  onClearAssignment: (a: { userId: string; position: number }) => void;
}

const EventTicketPanel = (props: EventTicketPanelProps) => {
  const buttonClasses = classnames({
    'flex justify-between w-full px-4 py-2 font-medium text-left text-blue-100 bg-primary rounded-t-lg hover:bg-primary-dark focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75 sans-serif': true,
    'rounded-b-lg': !props.open
  });
  const chevronClasses = classnames({
    'w-5 h-5 text-blue-100': true,
    'transform rotate-180': props.open
  });

  return (
    <>
      <Disclosure.Button className={buttonClasses}>
        <span className="sans-serif text-lg">
          <FontAwesomeIcon
            icon={
              allAssignmentsComplete(
                props.assignments.assignedUsers,
                props.actualAmount
              )
                ? faCheckCircle
                : faCircle
            }
            className="text-blue-100 mr-2"
          />
          {props.title}
        </span>
        <ChevronUpIcon className={chevronClasses} />
      </Disclosure.Button>
      <Disclosure.Panel className="p-6 text-gray-700 sans-serif border-gray-300 border-b border-l border-r rounded-b-lg">
        {props.blockApplicantAllocationMessage && (
          <div className="w-1/2 m-auto mt-2 mb-6">
            <UnableToEditNotice text={props.blockApplicantAllocationMessage} />
          </div>
        )}
        <div>
          <div className="flex justify-between">
            <div className="w-3/4 flex">
              {/* <div className="mr-10">
                <ProductPanelTitle title="Ticket" />
                <p>{props.ticketTitle}</p>
              </div> */}
              <div className="mr-10">
                <ProductPanelTitle title="Price" />
                <p>{formatAsCurrency((props.ticketPrice || 0) / 100)}</p>
              </div>
              <div className="mr-10">
                <ProductPanelTitle title="Number of Tickets" />
                <p>{props.actualAmount}</p>
              </div>
              <div className="mr-10">
                <ProductPanelTitle title="Total Cost" />
                <p>
                  {formatAsCurrency(
                    ((props.ticketPrice || 0) / 100) * props.actualAmount
                  )}
                </p>
              </div>
            </div>

            <div className="w-1/4 ml-4">
              {Array(props.actualAmount)
                .fill(0)
                .map((_n, i: number, arr) => {
                  const userMatch = props.assignments.assignedUsers[i] || null;
                  return (
                    <div className="mb-3" key={i}>
                      <ProductPanelTitle
                        title={
                          arr.length < 2 ? `Assignment` : `Assignment ${i + 1}`
                        }
                      />
                      <UserSelect
                        isDisabled={!!props.blockApplicantAllocationMessage}
                        // @ts-ignore
                        value={userMatch || null}
                        options={
                          props.users.filter(
                            u =>
                              !props.assignments.assignedUsers.some(
                                au => au && au.value === u.value
                              )
                          ) || []
                        }
                        onChange={(value: any) =>
                          props.onAssignmentSelect({
                            userId: value.value,
                            position: i
                          })
                        }
                      />
                      {userMatch && (
                        <div className="flex justify-end">
                          <button
                            disabled={!!props.blockApplicantAllocationMessage}
                            className="mt-1 text-sm sans-serif text-red-600 text-right"
                            onClick={() =>
                              props.onClearAssignment({
                                userId: userMatch.value,
                                position: i
                              })
                            }
                          >
                            Clear selection
                          </button>
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Disclosure.Panel>
    </>
  );
};

interface AssignStaffProps {
  ticketAssignments: TicketAssignments;
  setHasUnsavedChanges: any;
  packageRequestOrganizationUsers: (User & { isPending?: boolean })[];
  eventTickets: EventTicketAssignment[];
  onUpdateOrganizationPackageEvents: (a: EventTicketAssignment) => void;
  groupTickets: GroupTicketAssignment[];
  onUpdateOrganizationPackageGroups: (a: GroupTicketAssignment) => void;
  amounts: {
    groups: {
      total: number;
      allocated: number;
    };
    events: {
      total: number;
      allocated: number;
    };
  };
}
const AssignStaff = (props: AssignStaffProps) => {
  const { ticketAssignments, amounts } = props;

  const packageContext = useContext(PackageContext);
  const [user, setUser] = useState();
  useEffect(() => {
    const fetchUser = async () => {
      setUser(await getUserInfo());
    };
    fetchUser();
  }, []);

  return (
    <>
      <h3 className="text-2xl text-gray-600 font-bold my-4">
        Assign Staff to Events
      </h3>
      <>
        <div className="my-2">
          <ManageRosterLink
            organizationId={String(packageContext.organizationId)}
            withArrow
          />
        </div>

        {!ticketAssignments?.events?.length ? (
          <div className="w-1/2 m-auto my-6">
            <NoSelectionsWarning />
          </div>
        ) : (
          <>
            <div className="flex mx-auto justify-center w-1/5 border-primary p-4 rounded-lg border">
              {`${amounts.events.allocated} of ${amounts.events.total} Tickets Assigned`}
            </div>
            <div className="w-1/2 m-auto my-6">
              <SelectionInstructions />
            </div>
          </>
        )}
        {/* // TODO: ask Ryan which events should be displayed heres */}
        {ticketAssignments.events.map((ev, i: number) => {
          return (
            <div className="mb-4 flex flex-col gap-2" key={i}>
              <Disclosure key={i}>
                {({ open }) => {
                  const packageRequestEvent =
                    ev.packageEvents?.[0]?.packageRequestEvent?.[0];
                  const assignmentsCount =
                    (packageRequestEvent?.overrideDate
                      ? packageRequestEvent.overrideAmount
                      : packageRequestEvent?.requestedParticipants) || 0;
                  const existingTickets = props.eventTickets.find(
                    e => e.eventId === ev.id
                  ) || {
                    eventId: ev.id,
                    assignedUsers: Array(assignmentsCount).fill(null)
                  };
                  const usersOptions =
                    props.packageRequestOrganizationUsers?.map(sd => ({
                      value: sd?.id || '',
                      label:
                        [
                          sd?.firstName,
                          sd?.lastName,
                          sd?.isPending && '(Pending)'
                        ]
                          .filter(Boolean)
                          .join(' ') || '',
                      email: sd?.email || ''
                    })) || [];

                  const spotsLack =
                    assignmentsCount - existingTickets.assignedUsers.length;

                  if (spotsLack > 0) {
                    existingTickets.assignedUsers.concat(
                      Array(spotsLack).fill(null)
                    );
                  }

                  return (
                    <EventTicketPanel
                      open={open}
                      title={ev.name || ''}
                      ticketTitle={ev.name || ''}
                      ticketPrice={
                        ev.packageEvents?.[0]?.isFree
                          ? 0
                          : ev.packageEvents?.[0]?.ticketType?.price || 0
                      }
                      actualAmount={assignmentsCount}
                      users={usersOptions}
                      blockApplicantAllocationMessage={
                        isNotAvailableForPurchase({
                          event: ev,
                          type: 'EVENT',
                          isImpersonated: (user as any)?.impersonator
                        })
                          ? getTicketAllocationNotAvailableMessage(
                              ev.daysBeforePurchaseClose
                            )
                          : undefined
                      }
                      assignments={existingTickets}
                      onAssignmentSelect={userSelect => {
                        const assignedUsers = [
                          ...existingTickets.assignedUsers
                        ];

                        assignedUsers[userSelect.position] =
                          usersOptions.find(
                            u => u.value === userSelect.userId
                          ) || null;

                        const updatedOrgPackageEvent: EventTicketAssignment = {
                          ...existingTickets,
                          assignedUsers
                        };

                        props.onUpdateOrganizationPackageEvents(
                          updatedOrgPackageEvent
                        );
                        props.setHasUnsavedChanges(true);
                      }}
                      onClearAssignment={e => {
                        const assignedUsers: (UserOptionType | null)[] = [
                          ...existingTickets.assignedUsers
                        ];

                        if (assignedUsers[e.position]?.value === e.userId) {
                          assignedUsers[e.position] = null;
                        }

                        const updatedOrgPackageEvent: EventTicketAssignment = {
                          ...existingTickets,
                          assignedUsers: assignedUsers as any[]
                        };

                        props.onUpdateOrganizationPackageEvents(
                          updatedOrgPackageEvent
                        );
                        props.setHasUnsavedChanges(true);
                      }}
                    />
                  );
                }}
              </Disclosure>
            </div>
          );
        })}
        <hr className="my-10 border-gray-300" />
        <h3 className="text-2xl text-gray-600 font-bold my-4">
          Assign Staff to Groups
        </h3>
        {!ticketAssignments?.groups?.length ? (
          <div className="w-1/2 m-auto my-6">
            <NoSelectionsWarning />
          </div>
        ) : (
          <>
            <div className="flex mx-auto justify-center w-1/5 border-primary p-4 rounded-lg border mb-4">
              {`${amounts.groups.allocated} of ${amounts.groups.total} Tickets Assigned`}
            </div>
            {ticketAssignments.groups.map((g, i: number) => {
              const seatsCount = g.groupSections.reduce(
                (sum, s) =>
                  (sum +=
                    s?.packageGroupSections?.reduce(
                      (sum1, ps) =>
                        (sum1 +=
                          (ps?.packageRequestGroupSectionsOverride?.length
                            ? (ps.packageRequestGroupSectionsOverride as PackageRequestGroupSection[])
                            : ps.packageRequestGroupSections
                          )?.reduce(
                            (sum2, prgs) =>
                              (sum2 += prgs?.requestedParticipants || 0),
                            0
                          ) || 0),
                      0
                    ) || 0),
                0
              );

              const price = (g.packageGroup?.[0]?.attendeeCost || 0) / 100;

              let total = seatsCount * price;
              const packageGroup = (g.packageGroup as unknown) as PackageGroup[];

              if (
                packageGroup?.[0]?.enableFreeSeatOffer &&
                seatsCount > packageGroup?.[0]?.paidSeatsRequiredForOffer
              ) {
                const diff =
                  seatsCount - packageGroup?.[0]?.paidSeatsRequiredForOffer <
                  packageGroup?.[0]?.freeSeatsInOffer
                    ? seatsCount - packageGroup?.[0]?.paidSeatsRequiredForOffer
                    : packageGroup?.[0]?.freeSeatsInOffer;
                total -= diff * price;
              }

              return (
                <div key={i} className="mb-4">
                  <table className="min-w-full divide-y divide-gray-200 p-4 mb-10">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="text-left py-3 px-6">
                          Group Package
                        </th>
                        <th scope="col" className="text-left py-3 px-6">
                          Price
                        </th>
                        <th scope="col" className="text-left py-3 px-6">
                          Seats
                        </th>
                        <th scope="col" className="text-left py-3 px-6">
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      <tr>
                        <td className="py-3 px-6">{g.name}</td>
                        <td className="py-3 px-6">{formatAsCurrency(price)}</td>
                        <td className="py-3 px-6">{seatsCount}</td>
                        <td className="py-3 px-6">{formatAsCurrency(total)}</td>
                      </tr>
                    </tbody>
                  </table>
                  <>
                    {g.groupSections.map((s, i: number) => (
                      <div className="mb-2" key={i}>
                        <Disclosure>
                          {({ open }) => {
                            const usersOptions =
                              props.packageRequestOrganizationUsers?.map(
                                sd => ({
                                  value: sd?.id || '',
                                  label:
                                    [sd?.firstName, sd?.lastName]
                                      .filter(Boolean)
                                      .join(' ') || '',
                                  email: sd?.email || ''
                                })
                              ) || [];
                            const assignmentsCount =
                              s?.packageGroupSections?.reduce(
                                (sum, s) =>
                                  (sum +=
                                    (s.packageRequestGroupSectionsOverride
                                      ?.length
                                      ? (s.packageRequestGroupSectionsOverride as PackageRequestGroupSection[])
                                      : s?.packageRequestGroupSections
                                    ).reduce(
                                      (sum1, rqgs) =>
                                        (sum1 +=
                                          rqgs?.requestedParticipants || 0),
                                      0
                                    ) || 0),
                                0
                              ) || 0;

                            const maxTickets = s?.maxTickets || 0;

                            if (assignmentsCount === 0) {
                              return <></>;
                            }
                            const dates =
                              flatPolyfill(
                                s?.groupSectionStaffDevelopers?.map(
                                  g =>
                                    g?.dates?.map(d => d?.date as string) || []
                                ) || [],
                                2
                              ) || [];

                            const existingTickets = props.groupTickets.find(
                              gr =>
                                gr.groupId === g.id && gr.sectionId === s?.id
                            ) || {
                              groupId: g.id,
                              sectionId: s?.id || '',
                              assignedUsers: Array(assignmentsCount).fill(null)
                            };

                            const spotsLack =
                              assignmentsCount -
                              existingTickets.assignedUsers.length;

                            if (spotsLack > 0) {
                              existingTickets.assignedUsers.concat(
                                Array(spotsLack).fill(null)
                              );
                            }

                            return (
                              <GroupPackagePanel
                                open={open}
                                title={s?.displayTitle || ''}
                                days={dates.length}
                                dates={dates}
                                audience={s?.audienceType?.join(', ') || ''}
                                users={usersOptions}
                                assignmentsCount={assignmentsCount}
                                assignments={existingTickets}
                                maxAmount={maxTickets}
                                onAssignmentSelect={userSelect => {
                                  const assignedUsers = [
                                    ...existingTickets.assignedUsers
                                  ];

                                  assignedUsers[userSelect.position] =
                                    usersOptions.find(
                                      u => u.value === userSelect.userId
                                    ) || null;

                                  const updatedOrgPackageGroup: GroupTicketAssignment = {
                                    ...existingTickets,
                                    assignedUsers
                                  };

                                  props.onUpdateOrganizationPackageGroups(
                                    updatedOrgPackageGroup
                                  );
                                  props.setHasUnsavedChanges(true);
                                }}
                                onClearAssignment={e => {
                                  const assignedUsers: (UserOptionType | null)[] = [
                                    ...existingTickets.assignedUsers
                                  ];

                                  if (
                                    assignedUsers[e.position]?.value ===
                                    e.userId
                                  ) {
                                    assignedUsers[e.position] = null;
                                  }

                                  const updatedOrgPackageGroup: GroupTicketAssignment = {
                                    ...existingTickets,
                                    assignedUsers: assignedUsers as any[]
                                  };

                                  props.onUpdateOrganizationPackageGroups(
                                    updatedOrgPackageGroup
                                  );
                                  props.setHasUnsavedChanges(true);
                                }}
                              />
                            );
                          }}
                        </Disclosure>
                      </div>
                    ))}
                  </>
                </div>
              );
            })}
          </>
        )}
      </>
    </>
  );
};

export default AssignStaff;
