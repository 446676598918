import React from 'react';
import { useQuery } from 'react-query';
import Card from '../common/Card';
import SEO from '../Seo';
import { getPackageOfferingById } from './api';
import MembersPageTitle from './MembersPageTitle';
import NavigationGuard from './NavigationGuard';
import HistoryNavigationGuard from './HistoryNavigationGuard';

type PackageApplicationFormProps = {
  packageOfferingId: string;
};

const PackageApplicationForm = (props: PackageApplicationFormProps) => {
  const { isLoading, data } = useQuery<any>(['packageOffering'], () =>
    getPackageOfferingById(props.packageOfferingId)
  );

  return (
    <div>
      <SEO title="Package Application Form" />
      <NavigationGuard />
      <HistoryNavigationGuard />
      {isLoading ? <p>Loading...</p> : null}
      {!isLoading && data && data.packageOffering ? (
        <div>
          <div className="mb-4">
            <MembersPageTitle
              title={`${data.packageOffering.name} Application`}
            />
          </div>
          <Card>
            <iframe
              src={data.packageOffering.formLink}
              frameBorder="0"
              width="100%"
              height="3200px"
            ></iframe>
          </Card>
        </div>
      ) : (
        <p>There was a problem getting the package application</p>
      )}
    </div>
  );
};

export default PackageApplicationForm;
