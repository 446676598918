import { Router } from '@reach/router';
import React, { useContext } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FourOhFour from '../components/common/FourOhFour';
import LoadingDialog from '../components/common/LoadingDialog';
import AssignTickets from '../components/members/AssignTickets';
import Attendance from '../components/members/Attendance';
import AttendanceSection from '../components/members/AttendanceSection';
import EventApplicationAffiliationsAttendance from '../components/members/EventApplication/EventApplicationAffiliationsAttendance';
import EventApplicationGeneralInformation from '../components/members/EventApplication/EventApplicationGeneralInformation';
import EventApplicationQuestionnaire from '../components/members/EventApplication/EventApplicationQuestionnaire';
import EventApplicationReviewSubmit from '../components/members/EventApplication/EventApplicationReviewSubmit';
import EventApplications from '../components/members/EventApplication/EventApplications';
import EventApplicationSections from '../components/members/EventApplication/EventApplicationSections';
import InstituteSelection from '../components/members/InstituteSelection';
import ManageOrganization from '../components/members/ManageOrganization/index';
import ManageServices from '../components/members/ManageServices';
import MySavedResources from '../components/members/MySavedResources';
import MyToolkit from '../components/members/MyToolkit';
import Organization from '../components/members/Organization';
import OrganizationSelect from '../components/members/OrganizationSelect';
import Packages from '../components/members/Packages';
import Profile from '../components/members/Profile';
import PurchaseOrders from '../components/members/PurchaseOrders';
import PurchaseOrdersInstitute from '../components/members/PurchaseOrdersInstitute';
import PurchaseOrdersPackage from '../components/members/PurchaseOrdersPackage';
import Review from '../components/members/Review';
import SchoolRoster from '../components/members/SchoolRoster';
import FormExample from '../components/members/FormExample';
import VideoCollection from '../components/members/VideoCollection';
import VideoCollectionContent from '../components/members/VideoCollectionContent';
import MembersLayout from '../components/MembersLayout';
import { AuthContext } from '../context/AuthContext';
import EventManagement from './../components/members/EventManagement';
import Events from './../components/members/Events';
import EventSelection from './../components/members/EventSelection';
import GroupSelection from './../components/members/GroupSelection';
import StaffOptions from './../components/members/StaffOptions';
import AcceptOrganizationInvitationWithoutCode from '../components/members/AcceptOrganizationInvitation/AcceptOrganizationInvitationWithoutCode';
import AcceptOrganizationInvitationWithCode from '../components/members/AcceptOrganizationInvitation/AcceptOrganizationInvitationWithCode';
import { getUserInfo } from '../util/auth';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import AcceptDistrictInvitationWithoutCode from '../components/members/AcceptDistrictInvitation/AcceptDistrictInvitationWithoutCode';
import AcceptDistrictInvitationWithCode from '../components/members/AcceptDistrictInvitation/AcceptDistrictInvitationWithCode';
import ConferenceDaysCheckoutSuccess from '../components/conference-days/ConferenceDaysCheckoutSuccess';
import ConferenceDaysCheckoutPurchaseOrder from '../components/conference-days/ConferenceDaysCheckoutPurchaseOrder';
import EventApplicationCheckoutPurchaseOrder from '../components/members/EventApplication/EventApplicationCheckoutPurchaseOrder';
import EventApplicationCheckoutSuccess from '../components/members/EventApplication/EventApplicationCheckoutSuccess';
import EventSections from '../components/event-sections/EventSections';
import EventSectionsCheckout from '../components/event-sections/EventSectionsCheckout';
import EventAssignTickets from '../components/event-sections/EventAssignTickets';
import EventAssignTicketsWrapper from '../components/event-sections/EventAssignTicketsWrapper';
import CashnetCheckoutFailure from '../components/members/CashnetCheckoutFailure';
import IncompleteTransaction from '../components/members/IncompleteTransaction';
import PackageApplications from '../components/members/PackageApplications';
import PackageApplicationForm from '../components/members/PackageApplicationForm';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const Members = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <MembersRoutes />
    </QueryClientProvider>
  );
};

const MembersRoutes = () => {
  const auth = useContext(AuthContext);
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  if (params.has('token')) {
    localStorage.setItem('access_token', params.get('token') as string);
    const refreshUser = async () => {
      const user = await getUserInfo();
    };
    refreshUser().then(() => {
      navigate('/members/profile');
    });
  }

  if (auth.authState === undefined) {
    return (
      <div className="flex justify-center items-center h-screen">
        <LoadingDialog />
      </div>
    );
  }

  return (
    <>
      <MembersLayout user={auth.authState}>
        <ToastContainer />
        <Router basepath="members">
          <FourOhFour default path="not-found" />
          <Profile path="profile" user={auth.authState} />
          <MyToolkit path="my-toolkit" />
          <FormExample path="form-example" />
          <MySavedResources path="my-saved-resources" />
          <AcceptOrganizationInvitationWithoutCode path="accept-organization-invitation" />
          <AcceptOrganizationInvitationWithCode path="accept-organization-invitation/:code" />
          <AcceptDistrictInvitationWithoutCode path="accept-district-invitation" />
          <AcceptDistrictInvitationWithCode path="accept-district-invitation/:code" />
          <PurchaseOrders path="purchase-orders">
            <PurchaseOrdersInstitute path="institute" />
            <PurchaseOrdersPackage path="package" />
          </PurchaseOrders>
          <EventSections path="event-cart">
            <EventSectionsCheckout path="checkout" />
            <ConferenceDaysCheckoutPurchaseOrder path="checkout/purchase-order" />
            <ConferenceDaysCheckoutSuccess path="checkout/success" />
          </EventSections>
          <CashnetCheckoutFailure path="checkout/failure" />
          <IncompleteTransaction path="incomplete-transaction" />
          <EventAssignTicketsWrapper path="event/assign-tickets" />
          <OrganizationSelect path="organization/select" />
          <Organization path="organization/:organizationId">
            <ManageOrganization path="/" />
            <SchoolRoster path="school-roster" />
          </Organization>
          <ManageServices path="manage-services">
            <OrganizationSelect path="select-organization" />
            <Packages path="package-request/:packageRequestId">
              <StaffOptions path="staff-options" />
              <EventSelection path="event-selection" />
              <GroupSelection path="course-selection" />
              <InstituteSelection path="institute-selection" />
              <Review path="review" />
              <AssignTickets path="assign-tickets" />
            </Packages>
          </ManageServices>
          <PackageApplications path="package-applications/:packageOfferingId">
            <PackageApplicationForm path="form" />
          </PackageApplications>
          <Events path="events">
            <EventApplications path="applications" />
            <EventApplicationCheckoutPurchaseOrder path="applications/checkout/purchase-order" />
            <EventApplicationCheckoutSuccess path="applications/checkout/success" />
            <EventManagement path="applications/:applicationId/manage">
              <EventApplicationGeneralInformation path="general-information" />
              <EventApplicationAffiliationsAttendance path="affiliations-and-attendance" />
              <EventApplicationSections path="sections" />
              <EventApplicationQuestionnaire path="questionnaire" />
              <EventApplicationReviewSubmit path="review-and-submit" />
            </EventManagement>
          </Events>

          <VideoCollection path="video-collection">
            <VideoCollectionContent path=":collectionName" />
          </VideoCollection>
          <Attendance path="events/:eventId/attendance" />
          <AttendanceSection path="events/:eventId/attendance/:sectionId" />
        </Router>
      </MembersLayout>
    </>
  );
};

export default Members;
