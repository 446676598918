import {
  EventHistory,
  Event,
  Maybe,
  LegacyEvent,
  CustomHistoricEvent,
  CustomHistoricEventStatus
} from '../components/members/NewApiTypes.generated';

type EventHistoryType = 'CUSTOM' | 'LEGACY' | 'REGULAR';

export interface HistoricEventType {
  id: string | undefined;
  name: string | undefined;
  location: string | undefined | null;
  editable: boolean;
  eventType: string | undefined;
  eventSubType: string | undefined | null;
  type: EventHistoryType | undefined;
  dates: any[] | undefined;
  status?: CustomHistoricEventStatus;
}

export const getHistoricEvents = (
  eventHistory: EventHistory
): HistoricEventType[] => {
  return [
    ...(eventHistory?.events || []).map((ev: Maybe<Event>) => ({
      id: ev?.id,
      name: ev?.name,
      location: '',
      editable: false,
      eventType: ev?.eventType?.name,
      eventSubType: ev?.eventSubType?.name,
      type: 'REGULAR' as EventHistoryType,
      dates: ev?.dates
    })),
    ...(eventHistory?.legacyEvents || [])
      .filter(ev => ev?.eventAttendees?.[0]?.portalMetadata?.eventApplicationId)
      .map((ev: Maybe<LegacyEvent>) => ({
        id: ev?.id,
        name: ev?.name,
        location: '',
        editable: false,
        eventType: ev?.eventType,
        eventSubType: ev?.eventSubType,
        type: 'LEGACY' as EventHistoryType,
        dates: [ev?.startDate]
      })),
    ...(eventHistory?.customEvents || []).map(
      (ev: Maybe<CustomHistoricEvent>) => ({
        id: ev?.id,
        name: ev?.legacyEvent?.name || ev?.event?.name,
        location: ev?.location,
        editable: true,
        eventType: ev?.event?.eventType?.name || ev?.legacyEvent?.eventType,
        eventSubType:
          ev?.event?.eventSubType?.name || ev?.legacyEvent?.eventSubType,
        type: 'CUSTOM' as EventHistoryType,
        status: ev?.status,
        dates: ev?.legacyEventId
          ? [ev?.legacyEvent?.startDate]
          : ev?.event?.isRangeDate
          ? [ev?.event?.startDate]
          : ev?.event?.dates
      })
    )
  ];
};
