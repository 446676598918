import React, { useCallback, useEffect, useState } from 'react';
import { getEvents } from '../api';
import { useFormik } from 'formik';
import { FormField, FormWrapper } from '../../common/Form';
import * as Yup from 'yup';
import { OptionType } from '../../../constants/select';
import { SEARCH_DEBOUNCE_TIME } from '../../../constants/input';
import { debounce } from 'lodash';
import Button from '../../common/Button';
import { CustomDropdownOption } from '../../common/CustomDropdownOption';

const hasLocation = (name = '') => name?.toLowerCase().includes('homegrown');

export type AddEventHistoryForm = {
  name: any;
  year?: any;
  location?: string;
};

type IForm = AddEventHistoryForm;

const currentYear = Number(new Date().getFullYear().toString());

const yearOptions = [
  { value: '', label: 'All years' },
  ...Array.from({ length: 10 }, (_, i) => ({
    value: (currentYear - i).toString(),
    label: (currentYear - i).toString()
  }))
];

const AddEventHistoryForm = ({
  onCancel,
  onSubmit,
  onDelete,
  loadingCreate,
  loadingEdit,
  loadingDelete,
  customEvent
}: {
  onCancel: () => void;
  onDelete?: () => Promise<void>;
  onSubmit: (values: any) => Promise<void>;
  loadingCreate?: boolean;
  loadingEdit?: boolean;
  loadingDelete?: boolean;
  customEvent?: any;
}) => {
  const [events, setEvents] = useState<any[]>([]);
  const [validationSchema, setValidationSchema] = useState<any>(Yup.object());

  const [showLocation, setShowLocation] = useState(
    hasLocation(customEvent?.name)
  );
  const [eventsLoading, setEventsLoading] = useState(false);

  const formik = useFormik<AddEventHistoryForm>({
    validationSchema,
    initialValues: {
      name: customEvent?.name ? {} : '',
      location: customEvent?.location || '',
      year: ''
    },
    onSubmit: values => {
      if (customEvent) {
        const payload = {
          id: customEvent.id,
          location: values.location
        };
        onSubmit(payload);
      } else {
        const [id, type] = values.name.value.split(':');
        const payload = {
          eventId: id,
          type,
          location: values.location
        };
        onSubmit(payload);
      }
    }
  });

  useEffect(() => {
    setValidationSchema(
      Yup.object().shape({
        name: Yup.object().required('Name is required'),
        year: Yup.string(),
        ...(showLocation && {
          location: Yup.string().required('Location is required')
        })
      })
    );

    formik.validateForm();
  }, [showLocation]);

  const loadEventOptions = useCallback(
    async (
      inputValue: string,
      callback: (options: Array<{ value: string; label: string }>) => void
    ) => {
      setEventsLoading(true);
      const result = await getEvents(inputValue, formik.values.year?.value);
      setEvents(result.events);
      setEventsLoading(false);
      callback(
        result.events.map((event: any) => ({
          value: `${event.id}:${event.type}`,
          label: event.name,
          description: {
            id: event.dates,
            name: event.dates
          }
        }))
      );
    },
    [formik.values.year?.value]
  );

  const handleEventChange = (option: OptionType) => {
    formik.setFieldValue('name', option, true);
    const [id, type] = String(option.value).split(':');
    const event = events?.find(e => e.id === id && e.type === type);
    setShowLocation(hasLocation(event?.name));
  };

  return (
    <>
      <FormWrapper
        onCancel={onCancel}
        formik={formik}
        isLoading={loadingCreate || loadingEdit}
        className="px-3 py-4 border border-gray-300 bg-gray-100 rounded-xl"
        customBtns={
          <>
            {customEvent && onDelete && (
              <Button
                isDanger
                text="Remove event"
                size="sm"
                type="button"
                onClick={() => onDelete()}
                isLoading={loadingDelete}
              />
            )}
          </>
        }
      >
        <span className="mb-1.5 font-semibold sans-serif">
          {customEvent ? 'Edit history' : 'Add an event to history'}
        </span>
        <div className="flex gap-x-2 w-full ">
          {customEvent ? (
            <div className="flex flex-col mt-0.5 gap-y-1 w-1/2">
              <div className="sans-serif text-gray-500 uppercase mb-1 text-sm">
                Event
              </div>
              <div className="text-xl font-semibold">{customEvent?.name}</div>
            </div>
          ) : (
            <>
              <FormField<IForm>
                key={formik.values.year?.value}
                name="name"
                type="async-select"
                isLoading={eventsLoading}
                loadOptions={debounce(loadEventOptions, SEARCH_DEBOUNCE_TIME)}
                onChange={handleEventChange}
                className="w-1/2"
                components={{ Option: CustomDropdownOption as any }}
              />
              <FormField<IForm>
                name="year"
                type="select"
                className="w-1/2"
                options={yearOptions}
                onChange={option => {
                  formik.setFieldValue('year', option, true);
                  formik.setFieldValue('name', '', true);
                }}
              />
            </>
          )}
          {showLocation && (
            <FormField<IForm> type="text" name="location" className="w-1/2" />
          )}
        </div>
      </FormWrapper>
    </>
  );
};

export default AddEventHistoryForm;
