import {
  faPlus,
  faTimes,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { countries } from '../../data/countries';
import { states } from '../../data/states';
import { FormField, FormWrapper } from '../common/Form';
import { QueryReturnState } from './Enum';
import { useQuery } from 'react-query';
import { getOrganizationTypesTCAL, getDistrictsTCAL } from '../../tcal-api';
import { IDistrict, IOrganizationType } from './NewApiTypes';
import { SEARCH_DEBOUNCE_TIME } from '../../constants/input';
import { debounce } from 'lodash';
import { District } from './NewApiTypes.generated';

export type CreateOrganizationType = {
  organizationType: string;
  organizationName: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  stateProvince: string;
  country: string;
  zipPostal: string;
  phone: string;
  position: string;
  grades: string[];
  district?:
    | { value: string | undefined; label: string | undefined }
    | undefined;
  isUnaffiliatedToDistrict?: boolean;
  districtName?: string;
};

type IForm = CreateOrganizationType;

const CreateOrganizationSchema: Yup.SchemaOf<CreateOrganizationType> = Yup.object().shape(
  {
    organizationType: Yup.string().required('Selection required'),
    organizationName: Yup.string().required('Required'),
    addressLine1: Yup.string().required('Address 1 is required'),
    addressLine2: Yup.string(),
    city: Yup.string().required('City is required'),
    stateProvince: Yup.string().required('State / Province is required'),
    country: Yup.string().required('Country is required'),
    zipPostal: Yup.string().required('Zip / Postal code is required'),
    phone: Yup.string().required('Phone is required'),
    position: Yup.string().required('Selection required'),
    grades: Yup.array()
      .min(1, 'Selection required')
      .required('Required'),
    district: Yup.object().shape({
      value: Yup.string(),
      label: Yup.string()
    }),
    isUnaffiliatedToDistrict: Yup.boolean(),
    districtName: Yup.string()
  }
);

interface CreateOrganizationModalProps {
  onSubmit: (values: CreateOrganizationType) => void;
  isLoading: boolean;
  onCancel: () => void;
  positionOptions: { value: string; label: string }[];
  gradeOptions: { value: string; label: string }[];
  isLoadingPositions?: boolean;
  isLoadingGrades?: boolean;
}

const CreateOrganizationModal = (props: CreateOrganizationModalProps) => {
  const [isDistrictSelectMode, setIsDistrictSelectMode] = useState(true);

  const {
    data: organizationTypesData,
    isLoading: isLoadingOrganizationTypes
  } = useQuery<{
    state: QueryReturnState;
    organizationTypes?: IOrganizationType[];
  }>('organizationTypes', getOrganizationTypesTCAL);

  const [isLoadingDistricts, setDistrictLoading] = useState(false);

  const formik = useFormik<CreateOrganizationType>({
    validationSchema: CreateOrganizationSchema,
    initialValues: {
      organizationType: '',
      organizationName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      stateProvince: '',
      country: 'United States',
      zipPostal: '',
      phone: '',
      position: '',
      grades: [],
      district: undefined,
      isUnaffiliatedToDistrict: false,
      districtName: ''
    },
    onSubmit: values => {
      const { district, districtName, ...data } = values;

      const dt = isDistrictSelectMode ? { district } : { districtName };
      props.onSubmit({
        ...data,
        ...dt,
        phone: data.phone.replace(/[^\d]/g, '')
      });
    }
  });

  const handleChangeMode = () => {
    setIsDistrictSelectMode(!isDistrictSelectMode);
  };

  const loadDistrictOptions = async (
    inputValue: string,
    callback: (options: Array<{ value: string; label: string }>) => void
  ) => {
    setDistrictLoading(true);
    const result = await getDistrictsTCAL({ search: inputValue });
    const districts = result.districts as District[];
    setDistrictLoading(false);
    callback(
      districts.map(o => ({
        value: o.id,
        label: o.name
      }))
    );
  };

  return (
    <>
      <div
        className="h-screen w-0  sm:w-1/4 md:w-1/2 lg:w-2/3 fixed bg-black opacity-30 z-10 top-0 bottom-0 left-0 overflow-hidden"
        onClick={props.onCancel}
      />
      <aside className="w-full sm:w-3/4 md:w-1/2 lg:w-1/3 shadow-2xl fixed top-0 bottom-0 right-0 bg-white pt-4 overflow-scroll z-10">
        <FormWrapper
          className="px-8 py-4"
          formik={formik}
          isLoading={props.isLoading}
          onCancel={props.onCancel}
        >
          <div className="mb-6">
            <div className="flex justify-between mb-4">
              <h3 className="text-primary font-bold text-2xl">
                Create a New Organization
              </h3>
              <button
                type="button"
                className="text-3xl"
                onClick={props.onCancel}
              >
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  className="text-primary hover:text-primary-dark cursor-pointer"
                />
              </button>
            </div>
            <p className="text-gray-700">
              Use this form to request the addition of a new organization
            </p>
          </div>
          <FormField<IForm>
            name="organizationType"
            type="select"
            options={
              organizationTypesData?.organizationTypes?.map(t => ({
                label: t.label,
                value: t.id
              })) || []
            }
            isLoading={isLoadingOrganizationTypes}
            required
          />
          <FormField<IForm> name="organizationName" type="text" required />

          <FormField<IForm>
            label="This organization is not part of a district"
            type="checkbox"
            name="isUnaffiliatedToDistrict"
          />
          {formik.values.isUnaffiliatedToDistrict ? (
            <></>
          ) : (
            <>
              {isDistrictSelectMode ? (
                <FormField<IForm>
                  name="district"
                  type="async-select"
                  required
                  loadOptions={debounce(
                    loadDistrictOptions,
                    SEARCH_DEBOUNCE_TIME
                  )}
                  onChange={data =>
                    formik.setFieldValue('district', data, true)
                  }
                  isLoading={isLoadingDistricts}
                />
              ) : (
                <FormField<IForm>
                  label="New District"
                  name="districtName"
                  required
                  type="text"
                />
              )}

              <div>
                <FontAwesomeIcon
                  icon={isDistrictSelectMode ? faPlus : faTimes}
                  onClick={handleChangeMode}
                  className="cursor-pointer fa-xs text-secondary"
                />
                <span
                  className="ml-2 cursor-pointer text-xs text-primary"
                  onClick={handleChangeMode}
                >
                  {isDistrictSelectMode
                    ? 'New District'
                    : 'Look for existing district'}
                </span>
              </div>
            </>
          )}

          <FormField<IForm>
            name="addressLine1"
            type="text"
            label="Address 1"
            placeholder="Address 1"
            required
          />
          <FormField<IForm>
            name="addressLine2"
            type="text"
            label="Address 2"
            placeholder="Address 2"
          />
          <FormField<IForm> name="city" type="text" required />

          {formik.values.country !== 'United States' ? (
            <FormField<IForm> name="stateProvince" type="text" required />
          ) : (
            <FormField<IForm>
              name="stateProvince"
              label="State"
              placeholder="State"
              type="select"
              options={states.map(s => ({
                label: s.name,
                value: s.value
              }))}
              required
            />
          )}
          <FormField<IForm>
            name="country"
            type="select"
            options={countries.map(c => ({
              label: c.name,
              value: c.name
            }))}
            onChange={v => {
              if (v.value !== 'United States') {
                formik.setFieldValue('stateProvince', '');
              }
            }}
            required
          />
          <FormField<IForm>
            name="zipPostal"
            label="Zip / Postal Code"
            type="text"
            required
          />
          <FormField<IForm>
            name="phone"
            type="mask"
            mask="phoneNumber"
            required
          />
          <FormField<IForm>
            name="position"
            type="select"
            isLoading={props.isLoadingPositions}
            options={props.positionOptions}
            required
          />
          <FormField<IForm>
            name="grades"
            type="select"
            isLoading={props.isLoadingGrades}
            isMulti
            options={props.gradeOptions}
            required
          />
        </FormWrapper>
      </aside>
    </>
  );
};

export default CreateOrganizationModal;
